import styled from 'styled-components';

export const TituloDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const TituloLabel = styled.label`
  font-weight: 900;
  font-size: 18px;
  color: #6A3BAF;
  text-align: center;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
`;
