import { Form } from '@unform/web';
import Input from 'components/Input';
import AgendaModalPaciente from 'components/Modais/Pacientes/AgendaModal';
import AgendaModalTerapeuta from 'components/Modais/Terapeuta/AgendaModal';
import MiniCurriculoModal from 'components/Modais/Terapeuta/MiniCurriculoModal';
import ModalTitulo from 'components/ModalTitulo';
import TextArea from 'components/TextArea';
import { useAuth } from 'context/auth';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { BsArrowLeft } from 'react-icons/bs';
import { CgClose } from 'react-icons/cg';
import { GoCalendar } from 'react-icons/go';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import api from 'services/api';
import { ContainerBase, ModalBase } from 'styles/js-bases/modal';
moment.locale();
export default function DetalhesDoAgendamento({ agendaButtons, id = 'modalDetalhesDoAtendimento', agendamentoId, temDetalhes = true, onClose = () => { } }) {
    //#region VARIÁVEIS
    const titulo = 'MODAL DETALHES AGENDAMENTO';
    const rota = ['dashboard/terapeuta/agenda', ''];
    const descricao = 'Tela que permite o usuário observar todos os dados do Agendamento.';
    const [agendamento, setAgendamento] = useState();
    const dayName = ['Domingo', 'Segunda - Feira', 'Terça - Feira', 'Quarta - Feira', 'Quinta - Feira', 'Sexta - Feira', 'Sábado'];
    const [selectedPacienteId, setSelectedPacienteId] = useState();
    const [selectedTerapeutaId, setSelectedTerapeutaId] = useState();
    const [modal2Aberta, setModal2Aberta] = useState(false);
    const agendaModalPacienteRef = useRef();
    const agendaModalTerapeutaRef = useRef();
    const { user } = useAuth();
    const navigate = useNavigate();
    //#endregion
    //#region GETTERS
    const getAgendamento = () => {
        api.get(`api/agendamento/${agendamentoId}?id=${agendamentoId}&with=especialidade,terapium,modalidade,tipo_atendimento,` +
            'terapeutum.terapeuta_terapia.terapium,terapeutum.terapeuta_especialidades.especialidade,terapia_paciente,terapia_paciente.plano_saude,estacao.sala,estacao').then(response => {
                setAgendamento(response.data);
            });
    };
    //#endregion
    //#region HANDLES
    const handleOutsideClick = (e) => {
        if (e.target.id === id) onClose();
    };
    const handleOpenModalAgendaPaciente = function (id) {
        setSelectedPacienteId(id);
        if (agendaModalPacienteRef.current) {
            agendaModalPacienteRef.current.openModal(id);
        }
    };
    const handleOpenModalAgendaTerapeuta = function (id) {
        setSelectedTerapeutaId(id);
        if (agendaModalTerapeutaRef.current) {
            agendaModalTerapeutaRef.current.openModal(id);
        }
    };
    //#endregion
    //#region FUNÇÕES
    const showFormAgendamento = () => {
        if (user.tipo_user === 'responsavel') {
            return (
                <>
                    <div className="row">
                        <div className="col-lg-6 col-sm-12">
                            <Input disabled readOnly title="Nome:" name="paciente" defaultValue={agendamento?.paciente?.nome} />
                        </div>
                        <div className="col-lg-3 col-sm-12">
                            <Input disabled readOnly title="Dia:" name="data" defaultValue={dayName[moment(agendamento?.data_atendimento_inicial).weekday()]} />
                        </div>
                        <div className="col-lg-3 col-sm-12">
                            <Input disabled readOnly title="Horario:" name="horario" defaultValue={moment.utc(agendamento?.data_atendimento_inicial).format('HH:mm') + ' - ' + moment.utc(agendamento.data_atendimento_final).format('HH:mm')} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Input disabled readOnly title="Terapeuta:" name="Terapeuta" defaultValue={agendamento?.terapeutum?.nome} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-sm-12">
                            <Input disabled readOnly title="Plano de saúde:" name="plano_saude" defaultValue={agendamento?.terapia_paciente?.plano_saude?.plano} />
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <Input disabled readOnly title="Terapia:" name="Terapia" defaultValue={agendamento?.terapium?.terapia} />
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <Input disabled readOnly title="Especialidade:" name="Especialidade" defaultValue={agendamento?.especialidade?.especialidade} />
                        </div>
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <div className="row">
                        <div className="col-lg-6 col-sm-12">
                            <Input disabled readOnly title="Nome:" name="paciente" defaultValue={agendamento?.paciente?.nome} />
                        </div>
                        <div className="col-lg-3 col-sm-12">
                            <Input disabled readOnly title="Data:" name="data" defaultValue={dayName[moment(agendamento?.data_atendimento_inicial).weekday()]} />
                        </div>
                        <div className="col-lg-3 col-sm-12">
                            <Input disabled readOnly title="Horario:" name="horario" defaultValue={moment.utc(agendamento?.data_atendimento_inicial).format('HH:mm') + ' - ' + moment.utc(agendamento.data_atendimento_final).format('HH:mm')} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Input disabled readOnly title="Terapeuta:" name="Terapeuta" defaultValue={agendamento?.terapeutum?.nome} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-sm-12">
                            <Input disabled readOnly title="Plano de saúde:" name="plano_saude" defaultValue={agendamento?.terapia_paciente?.plano_saude?.plano} />
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <Input disabled readOnly title="Terapia:" name="Terapia" defaultValue={agendamento?.terapium?.terapia} />
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <Input disabled readOnly title="Especialidade:" name="Especialidade" defaultValue={agendamento?.especialidade?.especialidade} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-sm-12">
                            <Input disabled readOnly title="Modalidade:" name="Modalidade" defaultValue={agendamento?.modalidade?.modalidade} />
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <Input disabled readOnly title="Tipo de Atendimento:" name="tipoAtendimento" defaultValue={agendamento?.tipo_atendimento?.tipo_atendimento} />
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <Input disabled readOnly title="Reserva:" name="reserva" defaultValue={agendamento?.reserva ? 'Sim' : 'Não'} />
                        </div>
                    </div>
                    {user && user?.tipo_user?.toLowerCase() !== 'terapeuta' && user?.tipo_user?.toLowerCase() !== 'responsavel' &&
                        <TextArea disabled readOnly title="Comentário:" name="comentario" defaultValue={agendamento?.comentario} />
                    }
                </>
            );
        }
    };
    //#endregion
    //#region USE EFFECT
    useEffect(() => {
        getAgendamento();
    }, []);
    //#endregion
    //#region HTML
    return (
        <>
            <ModalBase className={modal2Aberta ? 'some' : ''} id={id} onClick={handleOutsideClick}>
                <ContainerBase className="mt-sm-5 pt-0">
                    <nav className='navbar sticky-top bg-cinza-claro shadow' style={{ margin: '0px -30px 0px -30px' }}>
                        <div className="container-fluid d-flex align-items-center justify-content-between">
                            <div className='w-50px'></div>
                            <ModalTitulo>Detalhes do Atendimento</ModalTitulo>
                            <label className='btn-outline w-50px text-right' onClick={() => onClose()}>
                                <CgClose size={22} color='red' />
                            </label>
                        </div>
                    </nav>
                    <br></br>
                    {agendamento
                        ?
                        <Form>
                            {showFormAgendamento()}
                            <br></br>
                            {agendaButtons &&
                                <div className="d-flex justify-between align-center">
                                    <button className="btn-padrao" onClick={() => handleOpenModalAgendaPaciente(agendamento.paciente.id)}>
                                        <GoCalendar size={22} color={'white'} style={{ marginRight: 10 }} />
                                        Agenda do Paciente
                                    </button>
                                    <button className="btn-padrao" onClick={() => handleOpenModalAgendaTerapeuta(agendamento.terapeuta_id)}>
                                        <GoCalendar size={22} color={'white'} style={{ marginRight: 10 }} />
                                        Agenda do Terapeuta
                                    </button>
                                </div>
                            }
                            <div className="row">
                                {user && user?.tipo_user?.toLowerCase() === 'terapeuta' ?
                                    <>
                                        <button className="btn-padrao mb-4" onClick={() => handleOpenModalAgendaPaciente(agendamento.paciente.id)}>
                                            <GoCalendar size={22} color={'white'} style={{ marginRight: 10 }} />
                                            Agenda do Paciente
                                        </button>
                                        <button className="btn-padrao disabled" onClick={() => navigate('/dashboard/terapeuta/pacientes/prontuarios/' + agendamento.paciente_id, '_blank')} disabled>
                                            Prontuário do Paciente
                                        </button>
                                    </>
                                    : user?.tipo_user?.toLowerCase() === 'responsavel' ?
                                        <>
                                            <div className="col-12">
                                                <div className="w-100per bg-roxo text-center p-1 border-lg-top-round-15">
                                                    <label className="font-branca font-16px">Mapa da Sala</label>
                                                    <img className="border-none w-100per" style={{ minHeight: '200px' }} src={agendamento?.estacao?.sala?.mapa_sala_base} alt=""></img>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <MiniCurriculoModal terapeuta_id={agendamento.terapeuta_id} modo='incorporado'></MiniCurriculoModal>
                                            </div>
                                        </>
                                        : ''
                                }
                                <div className="col-12 d-flex justify-content-start mt-4">
                                    <button onClick={() => onClose()} className="btn-padrao">
                                        <BsArrowLeft size={22} style={{ marginRight: '10px' }}></BsArrowLeft>
                                        Voltar
                                    </button>
                                </div>
                            </div>
                        </Form>
                        : <ClipLoader color={'purple'} size={60} />
                    }
                </ContainerBase>
            </ModalBase>
            <AgendaModalPaciente ref={agendaModalPacienteRef} paciente_id={selectedPacienteId} outSideSet={setModal2Aberta} temDetalhes={temDetalhes} />
            <AgendaModalTerapeuta ref={agendaModalTerapeutaRef} terapeuta_id={selectedTerapeutaId} outSideSet={setModal2Aberta} />
        </>
    );
    //#endregion
}
