import React, { useCallback, useEffect, useState } from 'react';
import { AiFillFileAdd } from 'react-icons/ai';

import { Container } from './styles';
import TablePlanoSaude from './TablePlanoSaude';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/react';
import PlanoSaudeService from '../../../services/PlanoSaudeService';
import PlanoSaudeModal from '../../../components/Modais/PlanoSaudeModal';
import api from '../../../services/api';
import Button from 'components/Button';
import { usePageBase } from 'context/page-base';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

/**
 * Componente PlanoDeSaudeAdmin
 * 
 * Este componente é responsável por renderizar a tela de cadastro dos Planos de Saúde, suas terapias e parâmetros.
 * 
 * @component
 * @returns {JSX.Element} Retorna o componente PlanoDeSaudeAdmin.
 * 
 * @example
 * <PlanoDeSaudeAdmin />
 * 
 * @description
 * O componente utiliza o hook `usePageBase` para configurar a página com título, descrição e outras propriedades.
 * Ele também gerencia o estado de carregamento, abertura de modal, e listas de planos, terapias e especialidades.
 * 
 * @function loadPlanos
 * @description Função assíncrona que carrega os planos de saúde, terapias e especialidades a partir da API.
 * 
 * @function handleNewPlano
 * @description Função que abre o modal para criar um novo plano de saúde.
 * 
 * @hook useEffect
 * @description Hook que chama a função `loadPlanos` ao montar o componente.
 * 
 * @returns {JSX.Element} Retorna o JSX do componente, incluindo a tabela de planos de saúde e o modal de criação/edição.
 */
export default function PlanoDeSaudeAdmin() {
    usePageBase({
        title: 'Plano de Saúde',
        description: 'Tela de cadastro dos Planos de Saúde, suas terapias e parâmetros.',
        hasTitle: true,
        hasSubMenu: false
    });
    //#region VARIAVEIS
    const [loading, setLoading] = useState(true);
    const color = '#8ed4ae';
    const [modalIsOpen, setIsOpen] = useState(false);
    const [planos, setPlanos] = useState([]);
    const [terapias, setTerapias] = useState([]);
    const [especialidades, setEspecialidades] = useState([]);
    const [obj, setObj] = useState({});
    //#endregion

    //#region FUNCOES
    const loadPlanos = useCallback(async (reloadAll = false) => {
        let terapias = await api.get('api/terapia');
        setTerapias(terapias.data);
        let especialidades = await api.get('api/especialidade');
        setEspecialidades(especialidades.data);
        PlanoSaudeService.index('with=plano_saude_terapia,porcentagem_reajuste_planos')
            .then(async ({ data }) => {
                data.sort((a, b) => {
                    return new Date(b.created_at) - new Date(a.created_at);
                });
                setPlanos(data);
            })
            .finally(() => setLoading(false));
    }, []);
    //#endregion

    //#region HANDLES
    const handleNewPlano = () => {
        setObj(undefined);
        setIsOpen(true);
    };
    //#endregion
    //#region USE EFFECTS
    useEffect(() => {
        loadPlanos();
    }, []);
    //#endregion

    //#region HTML
    return (
        <Container>
            <TablePlanoSaude setObj={setObj} setIsOpen={setIsOpen} callback={loadPlanos} source={planos} loading={loading} createButton={
                <Button onClick={() => handleNewPlano()} className="btn-padrao">
                    <AiFillFileAdd style={{ marginRight: 5 }} />
                    Novo Plano
                </Button>
            } />
            {modalIsOpen
                && <PlanoSaudeModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} callback={loadPlanos} data={obj} terapias={terapias} especialidades={especialidades} />
            }
        </Container>
    );
    //#endregion
}
