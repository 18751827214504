import React, { useMemo } from 'react';
import { useAuth } from 'context/auth';
import Header from 'components/Header';
import Container from 'components/Container';
import { useLocation } from 'react-router-dom';
import Button from 'components/Button';
import { BsArrowLeft } from 'react-icons/bs';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const HeaderPadrao = ({ children }) => {
    const { isAuthenticated } = useAuth();
    const [param, setParam] = React.useState('');
    const query = useQuery();
    const [noHeader, setNoHeader] = React.useState(query.get('no_header'));
    const isLoginScreen = useMemo(() => window.location.pathname === '/', [window.location.pathname]);
    const location = useLocation();

    const handleRetornar = () => {
        if (window.history.length > 2) {
            window.history.back();
            window.history.back();
        } else {
            window.close();
        }
    };
    React.useEffect(() => {
        if (param === 'true') {
            const newUrl = new URL(window.location);
            newUrl.searchParams.set('no_header', 'true');
            setNoHeader(true);
            window.history.pushState({}, '', newUrl);
        }
    }, [location]);
    React.useEffect(() => {
        if (query.get('no_header') === 'true') {
            setParam('true');
        } else {
            setParam('false');
        }
    }, []);

    return (
        <>
            {!isLoginScreen
                ? isAuthenticated
                    ? noHeader
                        ? <div className='w-100 p-2 mb-2'>
                            <Button className='w-20per-i' onClick={handleRetornar}>
                                <BsArrowLeft size={30} />
                                Retornar
                            </Button>
                        </div>
                        : <Header />
                    : null
                : null
            }
            <Container>
                {React.cloneElement(children, {})}
            </Container>
        </>
    );
};

export default HeaderPadrao;