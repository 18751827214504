//#region IMPORTAÇÕES
import NotFound from 'components/ErrosTemplate/NotFound';
import TituloPagina from 'components/TituloPagina';
import { useAuth } from 'context/auth';
import { usePageBase } from 'context/page-base';
import HeaderPadrao from 'layouts/HeaderPadrao';
import { AuditorRoutes } from 'module-routes/auditor-route';
import FuncionariosRoutes from 'module-routes/funcionario-route';
import { ResponsavelRoutes } from 'module-routes/responsavel-route';
import { TerapeutaRoutes } from 'module-routes/terapeuta-route';
import OfflineIndexDev from 'pages/Desenvolvedor/offline-index-dev';
import Login from 'pages/Login';
import PerfilUsuario from 'pages/Login/Perfil';
import RecuperarSenha from 'pages/Login/RecuperarSenha/link';
import RedirecionaParaDashboard from 'pages/RedirecionaParaDashboard';
import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
//#endregion

/**
 * Componente de rotas da aplicação.
 *
 * @component
 *
 * @returns {JSX.Element} O componente de rotas da aplicação.
 *
 * @example
 * <RoutesApp />
 *
 * @description
 * Este componente é responsável por definir as rotas da aplicação, verificando o acesso do usuário
 * e redirecionando conforme necessário. Utiliza hooks como `useLocation`, `useNavigate`, `useAuth` e `usePageBase`.
 *
 * @function
 * @name RoutesApp
 *
 * @hook
 * @name useLocation
 * @description Obtém a localização atual da rota.
 *
 * @hook
 * @name useNavigate
 * @description Navega para uma nova rota.
 *
 * @hook
 * @name useAuth
 * @description Obtém informações de autenticação do usuário.
 *
 * @hook
 * @name usePageBase
 * @description Obtém o título da página atual.
 *
 * @function
 * @name verificaAcesso
 * @description Verifica se o usuário tem acesso à rota atual e redireciona se necessário.
 *
 * @hook
 * @name useEffect
 * @description Executa a função `verificaAcesso` sempre que a rota atual muda.
 */
function RoutesApp() {
	//#region VARIAVEIS
	const location = useLocation();
	const navigate = useNavigate();
	const { user } = useAuth();
	const { title, description, hasTitle } = usePageBase();
	//#endregion

	//#region FUNCOES
	const verificaAcesso = () => {
		const localArray = location.pathname.split('/');
		if (user && user.route_tipo_user) {
			if ((localArray[2] !== '' || localArray[2] !== null) && (localArray[2]?.toLowerCase() !== user.route_tipo_user)) {
				navigate('/');
			}
		} else {
			navigate('/');
		}
	};
	//#endregion

	//#region CONEXÃO COM WEBSOCKET
	useEffect(() => {
	}, []);
	//#endregion

	//#region USE EFFECTS
	useEffect(() => {
		if (location.pathname !== '/' && !location.pathname.includes('recuperar-senha')) {
			verificaAcesso();
		}
	}, [location.pathname]);
	//#endregion

	//#region HTML
	return (
		<HeaderPadrao>
			<React.Fragment>
				{hasTitle && <TituloPagina description={description}>{title}</TituloPagina>}
				<Routes>
					<Route path='*' element={<NotFound />} />
					<Route path='/' element={<Login />} />
					<Route path='/recuperar-senha/' element={<RecuperarSenha />} />
					<Route path='/offline-testes' element={<OfflineIndexDev />} />
					{user && (
						<React.Fragment>
							<Route path='/dashboard/perfil' element={<PerfilUsuario />} />
							<Route path='/dashboard/' element={<RedirecionaParaDashboard user={user} />} />
							{FuncionariosRoutes(user?.route_tipo_user).map((route, index) => React.cloneElement(route, { key: 'funcionario-route' + index }))}
							{TerapeutaRoutes().map((route, index) => React.cloneElement(route, { key: 'terapeuta-route' + index }))}
							{AuditorRoutes().map((route, index) => React.cloneElement(route, { key: 'auditor-route' + index }))}
							{ResponsavelRoutes().map((route, index) => React.cloneElement(route, { key: 'responsavel-route' + index }))}
						</React.Fragment>
					)}
				</Routes>
			</React.Fragment>
		</HeaderPadrao>
	);
	//#endregion
}

export default RoutesApp;