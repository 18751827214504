import { usePageBase } from 'context/page-base';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { useAuth } from '../../../context/auth';
import api from '../../../services/api';
import { TableUltimosAtendimentos } from './TableRelatorios';

function TerapeutaRelatorios() {
    //#region VARIAVEIS
    usePageBase({
        title: 'Ultimos Atendimentos',
        description: 'Relatório dos ultimos atendimentos do terapeuta.',
        routes: ['terapeuta/ultimos-atendimentos'],
        showHeader: true,
        showBreadcrumb: false,
    });
    const [dropDown, setDropDown] = useState(false);
    const [relatorios, setRelatorios] = useState([]);
    const [dias, setDias] = useState(15);
    const [filterDate, setFilterDate] = useState(moment().subtract(15, 'days').format('YYYY-MM-DD'));
    const [loading, setLoading] = useState(false);
    // Hooks
    const { user } = useAuth();
    //#endregion

    //#region FUNCOES
    /**
     * Função assíncrona para obter relatórios filtrados de atendimentos.
     * 
     * Esta função faz uma requisição POST para a API com filtros específicos,
     * ordena os dados recebidos pela data de criação e mapeia os dados para um formato específico.
     * 
     * @async
     * @function getRelatorios
     * @returns {Promise<void>} - Retorna uma Promise que resolve quando os relatórios são obtidos e processados.
     * @throws {Error} - Lança um erro caso ocorra algum problema na requisição ou processamento dos dados.
     */
    const getRelatorios = async () => {
        setLoading(true);
        try {
            const select = [
                'paciente_id',
                'paciente_nome',
                'paciente_identificador',
                'nome_reduzido',
                'terapia',
                'especialidade',
                'data_do_atendimento',
                'hora_atendimento',
                'estacao',
                'sala',
                'sugestao_recepcao_name',
                'status_evolucao',
            ];
            const filters = {
                data_inicio: filterDate,
                data_fim: moment().format('YYYY-MM-DD'),
                terapeuta_id: user.terapeuta[0].id,
                duplica_alteracoes: false,
                sugestao_recepcao: 1,
                select: select,
            };
            const { data } = await api.post('/api/atendimento/relatorio/filtrado/puro', filters);
            data.sort((a, b) =>
                new Date(`${moment(a.data_do_atendimento, 'DD/MM/YYYY').format('YYYY-MM-DD')} ${a.hora_atendimento}`) -
                new Date(`${moment(b.data_do_atendimento, 'DD/MM/YYYY').format('YYYY-MM-DD')} ${b.hora_atendimento}`)
            );
            setRelatorios(data.map((atendimento) => ({
                id_paciente: atendimento.paciente_id,
                data_atendimento: atendimento.data_do_atendimento,
                hora_atendimento: atendimento.hora_atendimento.slice(0, -3),
                estacao: atendimento.estacao,
                sala: atendimento.sala,
                sala_estacao: `${atendimento.sala} - ${atendimento.estacao}`,
                nome: atendimento.paciente_nome,
                identificador: atendimento.paciente_identificador,
                plano_saude: atendimento.nome_reduzido,
                terapia: atendimento.terapia,
                especialidade: atendimento.especialidade,
                status: atendimento.sugestao_recepcao_name,
                status_evolucao: atendimento.status_evolucao,
            })));
            setLoading(false);
        } catch (error) {
            console.error('Erro ao obter lista de pacientes - ', error);
        }
    };
    //#endregion

    //#region HANDLES
    function handleFilterChange(days) {
        setDias(days);
        setFilterDate(moment().subtract(days, 'days').format('YYYY-MM-DD'));
    }
    //#endregion

    //#region USE EFFECTS
    useEffect(() => {
        getRelatorios();
    }, [filterDate]);
    //#endregion

    //#region HTML
    return (
        <div className='d-flex col-12 px-1'>
            <div className='col-12 d-flex justify-content-center flex-col h-auto p-0'>
                <div className='d-flex flex-column align-items-center justify-content-center h-auto p-0'>
                    <span style={{ color: '#4906AB', fontWeight: '600' }}>Filtro</span>
                    <div onClick={() => dropDown ? setDropDown(false) : setDropDown(true)} className='font-15px font-branca border-none border-radius-10px py-3 bg-roxo px-4'>
                        <span className='ms-1' style={{ cursor: 'pointer' }}>Selecionar <span className='ms-1'>({dias} dias)</span> {dropDown ? <IoIosArrowDown className='ms-2' /> : <IoIosArrowUp className='ms-2' />}</span>
                        {dropDown ?
                            <div className='d-flex flex-column align-items-center justify-content-center'>
                                <div onClick={() => handleFilterChange(15)} style={{ cursor: 'pointer', color: dias === 15 ? '#fff000' : '#fff' }} className='text-decoration-underline mb-1 py-2'><span>Últimos 15 dias</span></div>
                                <div onClick={() => handleFilterChange(30)} style={{ cursor: 'pointer', color: dias === 30 ? '#fff000' : '#fff' }} className='text-decoration-underline mb-1 py-2'><span>Últimos 30 dias</span></div>
                                <div onClick={() => handleFilterChange(60)} style={{ cursor: 'pointer', color: dias === 60 ? '#fff000' : '#fff' }} className='text-decoration-underline mb-1 py-2'><span>Últimos 60 dias</span></div>
                                <div onClick={() => handleFilterChange(90)} style={{ cursor: 'pointer', color: dias === 90 ? '#fff000' : '#fff' }} className='text-decoration-underline mb-1 py-2'><span>Últimos 90 dias</span></div>
                            </div>
                            : ''
                        }
                    </div>
                </div>
                <TableUltimosAtendimentos source={relatorios} loading={loading} />
            </div>
        </div>
    );
    //#endregion
}

export default TerapeutaRelatorios;