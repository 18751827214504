import { useSnackbar } from 'context/snackbar';
import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FaEye } from 'react-icons/fa';
import api from 'services/api';
import ReactModal from 'react-modal';

const KanbanBoard = ({ data, columns, onDrop = () => { } }) => {
    const snackbar = useSnackbar();
    const [localData, setLocalData] = useState(data);
    const [modalContent, setModalContent] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const onDragEnd = ({ draggableId, destination }) => {
        if (!destination) return;

        const updatedData = localData.map(item => {
            if (item.id === parseInt(draggableId)) {
                return { ...item, status: destination.droppableId };
            }
            return item;
        });

        setLocalData(updatedData);

        api.patch(`/api/linhas/${draggableId}`, { status: destination.droppableId })
            .then(() => {
                snackbar.displayMessage('Status atualizado com sucesso!', 'success');
            })
            .catch(error => {
                console.error('Erro ao atualizar status:', error);
                snackbar.displayMessage('Erro ao atualizar status!', 'error');
            })
            .finally(() => {
                onDrop();
            });
    };

    const handleViewDetails = (item) => {
        setModalContent(item.conteudo_linhas);
        setModalIsOpen(true);
    };

    // Agrupar itens por status
    const groupedData = localData.reduce((acc, item) => {
        const status = item.status || 'Sem Status';
        if (!acc[status]) acc[status] = [];
        acc[status].push(item);
        return acc;
    }, {});

    // Adicionar colunas vazias para os status especificados
    const statuses = ['PENDENTE', 'FAZENDO', 'FEITO LOCAL', 'FEITO', 'CANCELADO', 'RESPONDIDO'];
    statuses.forEach(status => {
        if (!groupedData[status]) groupedData[status] = [];
    });

    return (
        <>
            <DragDropContext onDragEnd={onDragEnd}>
                <div className="kanban-board">
                    {statuses.map((status, index) => (
                        <Droppable key={index} droppableId={status}>
                            {(provided) => (
                                <div className="kanban-column" ref={provided.innerRef} {...provided.droppableProps} >
                                    <h3 className='kanban-column-title'>{status}</h3>
                                    <div className='kanban-column-content'>
                                        {groupedData[status].map((item, itemIndex) => {
                                            const conteudo = item.conteudo_linhas;

                                            return (
                                                <Draggable key={item.id} draggableId={String(item.id)} index={itemIndex} >
                                                    {(provided) => {
                                                        return (
                                                            <div ref={provided.innerRef} className="kanban-item"
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <button className="kanban-item-view-button" onClick={() => handleViewDetails(item)} >
                                                                    <FaEye />
                                                                </button>
                                                                <div className="kanban-item-content">
                                                                    {columns.map(column => {
                                                                        const columnId = column.columnId;
                                                                        const conteudoColuna = conteudo.filter(conteudo => {
                                                                            return Number(conteudo.coluna_id) === Number(columnId);
                                                                        })[0];
                                                                        if (column.nome?.toLowerCase() === 'descrição') {
                                                                            return '';
                                                                        } else {
                                                                            if (column.nome?.toLowerCase() === 'titulo') {
                                                                                return (
                                                                                    <div className='title' key={column.accessor}>
                                                                                        {conteudoColuna?.conteudo}
                                                                                    </div>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <div key={column.accessor}>
                                                                                        {conteudoColuna?.conteudo}
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        }
                                                                    })}
                                                                </div>
                                                            </div>
                                                        )
                                                    }}
                                                </Draggable>
                                            )
                                        })}
                                    </div>
                                    {/* <div className='kanban-column-footer'>
                                        {provided.placeholder}
                                    </div> */}
                                </div>
                            )}
                        </Droppable>
                    ))}
                </div>
            </DragDropContext>
            <VisualizaConteudo conteudo={modalContent} isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)} />
        </>
    );
};

const VisualizaConteudo = ({ conteudo, isOpen, onRequestClose }) => {
    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Conteúdo Detalhado"
            style={{
                content: {
                    top: '10%',
                    right: '10%',
                    bottom: 'auto',
                    left: 'auto',
                    transform: 'translate(-10%, 0)',
                    width: '300px',
                    height: '400px',
                },
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                },
            }}
        >
            <button onClick={onRequestClose}>Fechar</button>
            <div className='conteudo'>
                {conteudo.map((item, index) => (
                    <div key={index}>
                        <strong>{item.nome}:</strong> {item.conteudo}
                    </div>
                ))}
            </div>
        </ReactModal>
    );
};

export default KanbanBoard;
