import Button from 'components/Button';
import Input, { RichTextEditor } from 'components/Input';
import Select from 'components/Select';
import PickList from 'components/PickList';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BsEye } from 'react-icons/bs';
import { CgClose } from 'react-icons/cg';
import ReactModal from 'react-modal';
import { ClipLoader } from 'react-spinners';
import api from 'services/api';
import Swal from 'sweetalert2';
import { Form } from '@unform/web';
import { Container, Modal } from './styles';

const ModalNotificacao = ({ modalData, setModalNotificacaoOpen }) => {
    //#region VARIAVEIS
    const pickListRef = useRef();
    const formRef = useRef(null);
    const [loading, setloading] = useState(false);
    const [notificacao, setNotificacao] = useState({
        user_id: '',
        titulo: '',
        conteudo: '',
        nivel_urgencia: '',
        tipo: '',
        modo_envio: ''
    });
    const [listaUsuarios, setListaUsuarios] = useState([]);
    const [listaUsuarioAlterada, setListaUsuarioAlterada] = useState({ added: [], removed: [] });
    const urgenciaOptions = useMemo(() => [
        { value: 'baixa', label: 'Baixa', name: 'nivel_urgencia' },
        { value: 'média', label: 'Média', name: 'nivel_urgencia' },
        { value: 'alta', label: 'Alta', name: 'nivel_urgencia' }
    ], []);
    const tipoOptions = useMemo(() => [
        { value: 'warning', label: 'Aviso', name: 'tipo' },
        { value: 'alert', label: 'Alerta', name: 'tipo' },
        { value: 'question', label: 'Questionamento', name: 'tipo' },
        { value: 'error', label: 'Erro', name: 'tipo' }
    ], []);
    const modoEnvioOptions = useMemo(() => [
        { value: 'sistema', label: 'Sistema', name: 'modo_envio' },
        { value: 'email', label: 'E-mail', name: 'modo_envio' },
    ], []);
    //#endregion
    //#region FUNÇÕES
    /**
     * Função de callback para pré-visualizar uma notificação.
     * 
     * Esta função utiliza a biblioteca SweetAlert2 para exibir uma notificação
     * com base no conteúdo e tipo fornecidos pelo objeto `notificacao`.
     * 
     * @function
     * @name previewNotificacao
     * @memberof Notificacoes/ModalNotificacao
     * @param {Object} notificacao - Objeto contendo os dados da notificação.
     * @param {string} notificacao.conteudo - Conteúdo HTML da notificação.
     * @param {string} notificacao.tipo - Tipo de ícone da notificação.
     * @returns {void}
     */
    const previewNotificacao = useCallback(() => {
        const { conteudo, tipo } = notificacao;
        const titulo = document.getElementById('titulo').value;
        let option = {
            title: titulo,
            html: conteudo,
            icon: tipo,
            confirmButtonText: 'Visualizar',
            denyButtonText: 'Excluir',
            showConfirmButton: true,
            showDenyButton: true,
        };
        Swal.fire(option);
    }, [notificacao]);
    //#endregion
    //#region HANDLES
    /**
     * Função de callback para lidar com mudanças em campos de entrada.
     * Atualiza o estado da notificação com o novo valor do campo alterado.
     *
     * @param {Object} e - O evento de mudança ou um objeto contendo o nome e valor do campo.
     * @param {Object} e.target - O alvo do evento de mudança.
     * @param {string} e.target.name - O nome do campo alterado.
     * @param {string} e.target.value - O novo valor do campo alterado.
     * @param {string} e.name - O nome do campo alterado (caso não seja um evento).
     * @param {string} e.value - O novo valor do campo alterado (caso não seja um evento).
     */
    const handleChange = useCallback((e) => {
        let name;
        let value;
        if (e.target) {
            name = e.target.name;
            value = e.target.value;
        } else {
            if (e.name !== undefined) {
                name = e.name;
            }
            if (e.value !== undefined) {
                value = e.value;
            }

            if (e.added !== undefined) {
                setListaUsuarioAlterada(e.added);
            }
        }
        setNotificacao((prevNotificacao) => ({
            ...prevNotificacao,
            [name]: value
        }));
    }, []);
    /**
     * Função de callback para lidar com o envio de notificações.
     *
     * @param {Object} data - Dados do formulário contendo o título da notificação.
     * @throws {Error} - Lança um erro se houver um problema ao enviar as notificações.
     */
    const handleSubmit = useCallback((data) => {
        try {
            const dados = {
                ...notificacao,
                titulo: data.titulo
            };
            const added = listaUsuarioAlterada;
            if (added.length === 0) {
                Swal.fire('Selecione ao menos um usuário para notificar...', '', 'warning');
                return;
            }
            const ids = added.map(user => user.value);

            const obj = {
                dados: dados,
                ids: ids
            };
            api.post('api/notificacao/notifica/user/ids', obj)
                .then((response) => {
                    if (response.status === 200) {
                        Swal.fire('Notificações enviadas com sucesso!', '', 'success', 2000);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    Swal.fire('Houve um problema a enviar as notificações...', '', 'error');
                });
        } catch (error) {
            console.error(error);
        } finally {
            setModalNotificacaoOpen(false);
        }
    }, [notificacao, listaUsuarios]);
    //#endregion
    //#region USE EFFECTS
    useEffect(() => {
        setloading(true);
        if (listaUsuarios.length === 0) {
            api.get('api/user')
                .then(({ data }) => {
                    let pickList = [];
                    data.forEach((user) => {
                        pickList.push({
                            value: user.id,
                            label: user.name,
                            secondLabel: user.tipo_user,
                            email: user.email,
                            tipo: user.tipo_user
                        });
                    });
                    setListaUsuarios(pickList.sort((a, b) => {
                        if (a.tipo === b.tipo) {
                            return a.label.localeCompare(b.label);
                        } else {
                            return a.tipo.localeCompare(b.tipo);
                        }
                    }));
                    setloading(false);
                })
                .catch(error => console.error(error));
        }
    }, [listaUsuarios]);
    useEffect(() => {
        if (modalData) {
            setNotificacao(modalData);
        }
    }, [modalData]);
    //#endregion
    //#region HTML
    return (
        <Modal id={'modal-notificacao'} onMouseDown={(e) => { if (e.target.id === 'modal-notificacao') setModalNotificacaoOpen(false) }}>
            <Container>
                <div className='div-titulo-fixo d-flex w-100' style={{ justifyContent: 'space-between', position: 'sticky' }}>
                    <label>
                        <BsEye color='purple' className='pointer' size={25} onClick={() => previewNotificacao()}></BsEye>
                    </label>
                    <label>Adicionar/Editar Notificação</label>
                    <label>
                        <CgClose color='red' className='pointer' size={25} onClick={() => setModalNotificacaoOpen(false)} />
                    </label>
                </div>
                <ClipLoader size={30} className='m-auto' loading={loading} />
                <Form ref={formRef} onSubmit={handleSubmit} className={`row pe-4 ps-4 pb-4 m-0${loading ? 'some' : ''}`} autoComplete="off">
                    <label className='col-12 mt-4'>
                        Título:
                        <Input type='text' id='titulo' name='titulo' defaultValue={notificacao.titulo} onChange={handleChange} />
                    </label>
                    <label className='col-12 mt-4'>
                        Conteúdo:
                        <RichTextEditor id='conteudo' name='conteudo' defaultValue={notificacao.conteudo} onChange={(e) => handleChange({ name: 'conteudo', value: e })} />
                    </label>
                    <label className='col-sm-12 col-lg-4 mt-4'>
                        Nível de Urgência:
                        <Select type='text' id='nivel_urgencia' name='nivel_urgencia' options={urgenciaOptions} defaultValue={notificacao.nivel_urgencia} onChange={handleChange} />
                    </label>
                    <label className='col-sm-12 col-lg-4 mt-4'>
                        Tipo:
                        <Select type='text' id='tipo' name='tipo' options={tipoOptions} defaultValue={notificacao.tipo} onChange={handleChange} />
                    </label>
                    <label className='col-sm-12 col-lg-4 mt-4'>
                        Modo de Envio:
                        <Select type='text' id='modo_envio' name='modo_envio' options={modoEnvioOptions} defaultValue={notificacao.modo_envio} onChange={handleChange} />
                    </label>
                    <div className={`col-12 flex-col mt-4${modalData ? ' some' : ''}`}>
                        <label>Usuário para notificar</label>
                        <PickList
                            ref={pickListRef}
                            className={'w-100per'}
                            todosDefault={listaUsuarios}
                            selecionadosDefault={[]}
                            onSelectedChange={handleChange}
                        />
                    </div>
                    <div className='d-flex justify-between'>
                        <Button className='w-300px' type='button' onClick={() => setModalNotificacaoOpen(false)}>Cancelar</Button>
                        <Button className='w-300px' type='submit'>Salvar</Button>
                    </div>
                </Form>
            </Container>
        </Modal>
    );
    //#endregion
};

export { ModalNotificacao };