import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
background: #F5F7FA;
max-width: 1280px;
margin: 0 auto;
`;

export const Calendar = styled.aside`
  width: 380px;
  .DayPicker {
    background: #28262e;
    border-radius: 10px;
  }
  .DayPicker-wrapper {
    padding-bottom: 0;
  }
  .DayPicker,
  .DayPicker-Month {
    width: 100%;
  }
  .DayPicker-Month {
    border-collapse: separate;
    border-spacing: 8px;
    margin: 16px;
  }
  .DayPicker-Day {
    width: 40px;
    height: 40px;
  }
  .DayPicker-Day--available:not(.DayPicker-Day--outside) {
    background: #3e3b47;
    border-radius: 10px;
    color: #fff;
  }
  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background: ${shade(0.2, '#3e3b47')};
  }
  .DayPicker-Day--today {
    font-weight: normal;
  }
  .DayPicker-Day--disabled {
    color: #666360 !important;
    background: transparent !important;
  }
  .DayPicker-Day--selected {
    background: #ff9000 !important;
    border-radius: 10px;
    color: #232129 !important;
  }
`;

export const ButtonStyle = {
  backgroundColor: '#7441BF',
  color: '#fff',
  padding: '2px 22px',
  width: 120,
  borderRadius: 10,
  borderColor: '#F5F7FA',
  borderWidth: 2,
  borderStyle: 'solid',
  cursor: 'pointer',
};

export const ButtonStyle2 = {
  backgroundColor: '#7441BF',
  color: '#fff',
  marginTop: 10,
  width: 380,
  height: 30,
  borderRadius: 10,
  borderColor: '#F5F7FA',
  borderWidth: 2,
  borderStyle: 'solid',
  cursor: 'pointer',
};
const alturaTela = window.innerHeight;
const larguraTela = window.innerWidth;
const altura = 60;
const alturaMenor = 60;
const largura = '15%';

export const tr0 = {
  backgroundColor: 'transparent',
  width: '10%',
  height: alturaMenor,
};

export const tr1 = {
  backgroundColor: 'rgb(0, 177, 157)',
  color: '#fff',
  borderRadius: 6,
  borderColor: '#F5F7FA',
  borderWidth: 0.1,
  borderStyle: 'solid',
  minWidth: largura,
  maxWidth: largura,
  width: largura,
  height: alturaMenor,
  minHeight: alturaMenor,
  maxHeight: alturaMenor,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

export const tr2 = {
  backgroundColor: '#7441BF',
  color: '#fff',
  padding: 5,
  borderRadius: 6,
  borderColor: '#F5F7FA',
  borderWidth: 0.1,
  borderStyle: 'solid',
  minWidth: largura,
  maxWidth: largura,
  width: largura,
  height: alturaMenor,
  minHeight: alturaMenor,
  maxHeight: alturaMenor,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

export const trHora = {
  backgroundColor: '#DFCDF8',
  color: '#9586AC',
  padding: 5,
  borderRadius: 6,
  borderColor: '#F5F7FA',
  borderWidth: 0.1,
  borderStyle: 'solid',
  fontSize: 11,
  minWidth: '10%',
  maxWidth: '10%',
  width: '10%',
  height: alturaMenor,
  minHeight: alturaMenor,
  maxHeight: alturaMenor,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

export const trVazio = {
  backgroundColor: '#fff',
  padding: 5,
  borderRadius: 6,
  borderColor: '#F5F7FA',
  borderWidth: 0.1,
  borderStyle: 'solid',
  minWidth: largura,
  maxWidth: largura,
  width: largura,
  height: alturaMenor,
  minHeight: alturaMenor,
  maxHeight: alturaMenor,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

export const trOcupado = {
  backgroundColor: '#35D058',
  fontSize: 9,
  color: '#fff',
  padding: 5,
  borderRadius: 6,
  borderColor: '#F5F7FA',
  borderWidth: 0.1,
  borderStyle: 'solid',
  cursor: 'zoom-in',
  minWidth: largura,
  maxWidth: largura,
  width: largura,
  height: alturaMenor,
  minHeight: alturaMenor,
  maxHeight: alturaMenor,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

export const linha = {
  borderColor: '#fff',
  borderWidth: 1,
  borderStyle: 'solid',
  color: '#fff'
};