//#region IMPORTAÇÕES
import { Form } from '@unform/web';
import Button from 'components/Button';
import Select from 'components/Select';
import SelectPaciente from 'components/Select/SelectPaciente';
import { useAuth } from 'context/auth';
import useIcons from 'context/icons';
import usePacienteData from 'context/paciente';
import { usePageBase } from 'context/page-base';
import useTerapeutasData from 'context/terapeuta';
import moment from 'moment';
import ControleDiario from 'pages/RecepcaoDashboard/Agenda/ControleDiario';
import { cloneElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
//#endregion

const ConsultasAlteracoes = () => {
    usePageBase({
        title: 'Consultar e Alterar Atendimentos',
        description: 'Visualize os atendimentos pendentes para o dia selecionado.',
        hasTitle: true,
        hasSubmenu: true,
        routes: [
            'dashboard/{route_tipo_user}/controlediario/atendimentos-pendentes',
        ]
    });
    //#region VARIAVEIS
    // Hooks
    const { iconArray } = useIcons();
    const { user } = useAuth();

    // Parâmetros
    const { data } = useParams();
    const [dataMoment, setDataMoment] = useState(moment(data));

    // Estado de Carregamento
    const [loading, setloading] = useState(false);

    // Listas
    const { terapeutasOptions, fetchTerapeutasData } = useTerapeutasData();
    const { pacienteOptions, fetchPacienteData } = usePacienteData(['nome', 'identificador', 'aviso', 'terapia_paciente']);

    // Selecionados
    const [pacienteSelecionado, setPacienteSelecionado] = useState(null);
    const [terapeutaSelecionado, setTerapeutaSelecionado] = useState(null);
    const [modoDeConsulta, setModoDeConsulta] = useState(null);

    //#endregion
    //#region HANDLES
    /**
     * Função para pesquisar e definir o modo de consulta baseado nos IDs do paciente e terapeuta selecionados.
     * 
     * Esta função cria um array de condições de consulta (`novoModoDeConsulta`) com base nos valores selecionados
     * de paciente e terapeuta. Se um paciente ou terapeuta estiver selecionado, suas respectivas condições são
     * adicionadas ao array. Em seguida, o array é definido como o novo modo de consulta.
     * 
     * @function handlePesquisar
     */
    const handlePesquisar = () => {
        setModoDeConsulta(null);
        const novoModoDeConsulta = [];
        const paciente_id = pacienteSelecionado?.value;
        const terapeuta_id = terapeutaSelecionado?.value;
        if (paciente_id) novoModoDeConsulta.push(`atendimento.paciente_id=${paciente_id}`);
        if (terapeuta_id) novoModoDeConsulta.push(`atendimento.terapeuta_id=${terapeuta_id}`);
        setModoDeConsulta(novoModoDeConsulta);
    };
    const handleLimpaFiltros = () => {
        setPacienteSelecionado(null);
        setTerapeutaSelecionado(null);
    };
    //#endregion
    //#region USE EFFECTS
    useEffect(() => {// Verifica Listas
        if (terapeutasOptions && pacienteOptions) {
            setloading(false);
        }
    }, [terapeutasOptions, pacienteOptions]);
    useEffect(() => {
        setModoDeConsulta(null);
    }, [terapeutaSelecionado, pacienteSelecionado]);
    useEffect(() => {// ON LOAD
        setloading(true);
        fetchTerapeutasData();
        //fetchPacienteData(['nome', 'identificador', 'aviso', 'terapia_paciente']);
    }, []);
    //#endregion
    //#region HTML
    return (
        <div className='m-0 p-0' style={{ padding: '0 0 0 50' }}>
            <div className='mx-auto col-8'>
                <div className='w-100per d-flex flex-col'>
                    <div className='col-12 d-flex text-right justify-content-center align-center mt-2'>
                        <span className='texto-data-controle-diario me-2 ml-2'>Data: <b>{dataMoment.format('dddd - DD/MM/YYYY')}</b></span>
                    </div>
                </div>
                <Form className='row'>
                    <div className='col-4'>
                        <SelectPaciente
                            id={'paciente_selecionado'}
                            name={'paciente_selecionado'}
                            label='Paciente'
                            placeholder='Selecione o paciente, ou deixe em branco para todos.'
                            setPaciente={setPacienteSelecionado}
                            isDisabled={loading}
                        />
                    </div>
                    <div className='col-5'>
                        <Select
                            id={'terapeuta_selecionado'}
                            name={'terapeuta_selecionado'}
                            label='Terapeuta'
                            placeholder='Selecione o terapeuta, ou deixe em branco para todos.'
                            onChange={setTerapeutaSelecionado}
                            options={terapeutasOptions}
                            isDisabled={loading}
                        />
                    </div>
                    <div className='col-1 d-flex align-items-end'>
                        <Button type={'button'} className="h-38px" onClick={() => handleLimpaFiltros()}>
                            {cloneElement(iconArray['limpar-geral'], { size: 24 })}
                        </Button>
                    </div>
                    <div className='col-2 d-flex align-items-end'>
                        <Button type={'button'} className="h-38px" onClick={() => handlePesquisar()}>
                            {loading
                                ? <ClipLoader size={20} />
                                : <> {cloneElement(iconArray['pesquisar'], { size: 24 })} Pesquisar </>
                            }
                        </Button>
                    </div>
                </Form>
            </div>
            {(modoDeConsulta) &&
                <ControleDiario
                    modoIncorporado={true}
                    modoDeConsulta={modoDeConsulta}
                    funcoesHabilitadas={{
                        filtro: true,
                        navegacao: false,
                        salvar: false,
                        carregar: false,
                        novo_atendimento: false,
                        solicitacoes_alteracao: false,
                        filtro_solicitacoes_alteracao: false,
                        atualizacao: true,
                        baixar_excel: false,
                        legenda: true,
                        botao_alteracao: true,
                        controle_de_execucao: false,
                        altera_sugestao_recepcao:
                            (user?.tipo_user?.toLowerCase() === 'guias' || user?.tipo_user?.toLowerCase() === 'administrador' || user?.tipo_user?.toLowerCase() === 'desenvolvedor' || user?.tipo_user?.toLowerCase() === 'agendamento')
                                ? true
                                : false,
                        altera_liberado: (user?.tipo_user?.toLowerCase() === 'guias' || user?.tipo_user?.toLowerCase() === 'administrador' || user?.tipo_user?.toLowerCase() === 'desenvolvedor' || user?.tipo_user?.toLowerCase() === 'agendamento')
                            ? true
                            : false,
                        coluna_p: true,
                        paciente_filtro_inline: true,
                        modal_alteracao_opcoes: {
                            aprova: false,
                            reprova: true,
                            cria: true,
                        }
                    }}
                    filtrosHabilitados={{
                        paciente: pacienteSelecionado ? false : true,
                        terapeuta: terapeutaSelecionado ? false : true,
                        status_atendimento: true,
                        plano_saude: true,
                    }}
                />
            }
        </div>
    );
    //#endregion
};

export default ConsultasAlteracoes;