import React from 'react';
import { BotaoAcaoStyle, InputStyle, MainDivStyle, OptionsStyle, TextFormat } from './styles';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleLeft, FaAngleRight } from 'react-icons/fa';

/**
 * TablePagination component for handling pagination controls and display.
 *
 * @param {Object} props - The properties object.
 * @param {string} [props.prefix_id='default'] - The prefix ID for the component.
 * @param {Function} props.gotoPage - Function to navigate to a specific page.
 * @param {Function} props.previousPage - Function to navigate to the previous page.
 * @param {Function} props.nextPage - Function to navigate to the next page.
 * @param {Function} props.setPageSize - Function to set the number of items per page.
 * @param {number} props.pageIndex - The current page index.
 * @param {number} props.pageCount - The total number of pages.
 * @param {boolean} props.canNextPage - Flag indicating if the next page is available.
 * @param {boolean} props.canPreviousPage - Flag indicating if the previous page is available.
 * @param {Array<number>} props.pageOptions - Array of available page options.
 * @param {number} props.pageSize - The current number of items per page.
 * @param {number} props.linhas - The total number of lines/items.
 * @param {Function} props.exportToExcel - Function to export data to Excel.
 * @param {boolean} [props.enableExport=true] - Flag to enable or disable the export button.
 * @param {boolean} [props.enablePageCount=true] - Flag to enable or disable the page count display.
 * @param {boolean} [props.enablePageSize=true] - Flag to enable or disable the page size selector.
 * @param {boolean} [props.enableLinesCount=true] - Flag to enable or disable the lines count display.
 * @param {boolean} [props.enableGoToPage=true] - Flag to enable or disable the go-to-page input.
 * @returns {JSX.Element} The TablePagination component.
 */
const TablePagination = ({
    prefix_id = 'default',
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    pageIndex,
    pageCount,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageSize,
    linhas,
    exportToExcel,
    enableExport = true,
    enablePageCount = true,
    enablePageSize = true,
    enableLinesCount = true,
    enableGoToPage = true,
}) => {
    return (
        <div id={`${prefix_id}-tfoot-inside`} style={MainDivStyle}>
            <div id={`${prefix_id}-tfoot-page-details`} className="w-400px-i d-flex justify-content-start align-items-center">
                {enableExport &&
                    <button id={`${prefix_id}-tfoot-export`} type={'button'} className='btn-outline' onClick={exportToExcel} style={{ marginBottom: '10px' }}>
                        <RiFileExcel2Fill className='font-roxo' size={26} />
                    </button>
                }
                {enablePageCount &&
                    <span style={TextFormat}>
                        Página:{' '}
                        <strong style={TextFormat}>
                            {pageIndex + 1} de {pageOptions.length}
                        </strong>{' '}
                    </span>
                }
                {enableGoToPage &&
                    <span className="ms-2" style={TextFormat}>
                        Ir para página:{' '}
                        <input
                            type="number"
                            defaultValue={pageIndex + 1}
                            onChange={(e) => {
                                const pageNumber = e.target.value
                                    ? Number(e.target.value) - 1
                                    : 0;
                                gotoPage(pageNumber);
                            }}
                            style={InputStyle} />
                    </span>
                }
            </div>
            <div id={`${prefix_id}-tfoot-toolbox`} className="w-250px-i d-flex justify-content-start align-items-center">
                <button id={`${prefix_id}-tfoot-fast-back`} type={'button'} style={BotaoAcaoStyle} onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    <FaAngleDoubleLeft />
                </button>{' '}
                <button id={`${prefix_id}-tfoot-back`} type={'button'} style={BotaoAcaoStyle} onClick={() => previousPage()} disabled={!canPreviousPage}>
                    <FaAngleLeft />
                </button>{' '}
                <button id={`${prefix_id}-tfoot-forward`} type={'button'} style={BotaoAcaoStyle} onClick={() => nextPage()} disabled={!canNextPage}>
                    <FaAngleRight />
                </button>{' '}
                <button id={`${prefix_id}-tfoot-fast-forward`} type={'button'} style={BotaoAcaoStyle} onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    <FaAngleDoubleRight />
                </button>{' '}
            </div>
            <div id={`${prefix_id}-tfoot-line-details`} className="w-400px-i d-flex justify-content-end align-items-center">
                {enableLinesCount && <span style={TextFormat}> Linhas: {linhas}</span>}
                {enablePageSize &&
                    <select style={OptionsStyle}
                        value={pageSize}
                        onChange={(e) => setPageSize(Number(e.target.value))}>
                        {[4, 8, 15, 20, 50, 70, 100].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                Mostrar {pageSize}
                            </option>
                        ))}
                    </select>
                }
            </div>
        </div>
    );
};
export default TablePagination;