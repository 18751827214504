import { useState } from 'react';
import api from 'services/api';

const usePlanoSaudeData = () => {
    const [planoSaudeOptions, setPlanoSaudeOptions] = useState([]);

    const mapPlanoSaudeData = (data, includeTerapia = false) => {
        return data.map((planoSaude) => ({
            value: planoSaude.id,
            label: `${planoSaude.id} - ${planoSaude.nome_reduzido}`,
            ...(includeTerapia && { plano_saude_terapia: planoSaude.plano_saude_terapia })
        }));
    };

    const fetchPlanoSaude = async () => {
        try {
            const { data } = await api.get('api/plano-saude');
            const options = mapPlanoSaudeData(data);
            setPlanoSaudeOptions(options);
        } catch (error) {
            console.error('Erro ao carregar planos de saúde:', error);
        }
    };

    const fetchPlanoSaudeTerapiaData = async () => {
        try {
            const { data } = await api.get('api/plano-saude?with=plano_saude_terapia');
            const options = mapPlanoSaudeData(data, true);
            setPlanoSaudeOptions(options);
        } catch (error) {
            console.error('Erro ao carregar planos de saúde com terapias:', error);
        }
    };

    return { planoSaudeOptions, fetchPlanoSaude, fetchPlanoSaudeTerapiaData, mapPlanoSaudeData };
};

export default usePlanoSaudeData;