import { Form } from '@unform/web';
import { useSnackbar } from 'context/snackbar';
import React, { useRef, useState } from 'react';
import { MdEmail } from 'react-icons/md';
import { ClipLoader } from 'react-spinners';
import api from 'services/api';

const EnviarEmail = () => {

    //#region VARIAVEIS
    // Hooks
    const snackbar = useSnackbar();
    // Refs
    const formRef = useRef(null);
    // Conteudos
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    // Estados
    const [loading, setloading] = useState(false);
    //#endregion

    //#region HANDLES
    const handleSubmit = (e) => {
        setloading(true);
        try {
            const data = {
                email,
                subject,
                message
            };

            // Enviar e-mail
            api.post('api/enviar/email', data)
                .then((response) => {
                    snackbar.displayMessage('E-mail enviado com sucesso!', 'success');
                    // Limpar campos
                    formRef.current.reset();
                    setEmail('');
                    setSubject('');
                    setMessage('');
                    setloading(false);
                })
                .catch((error) => {
                    console.error(error);
                    snackbar.displayMessage('Erro ao enviar e-mail!', 'error');
                    setloading(false);
                });
        } catch (error) {
            console.error(error);
            snackbar.displayMessage('Erro ao enviar e-mail!', 'error');
        }
    };
    //#endregion

    return (
        <div className="container mt-5">
            <h1>Enviar E-mail</h1>
            <Form ref={formRef} className='row' onSubmit={handleSubmit}>
                <div className="mb-3 col-6">
                    <label className="form-label">
                        E-mail:
                    </label>
                    <input
                        type="email"
                        className="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-3 col-6">
                    <label className="form-label">
                        Assunto:
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-3 col-12">
                    <label className="form-label">
                        Mensagem:
                    </label>
                    <textarea
                        className="form-control"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                        rows="4"
                    />
                </div>
                <div className="mb-3 col-12 d-flex justify-content-end">
                    <button type="submit" className="btn btn-primary w-200px" isDisabled={loading}>
                        {loading ? <ClipLoader /> : 'Enviar'} <MdEmail size={22} className='ms-2' />
                    </button>
                </div>
            </Form>
        </div>
    );
};

export default EnviarEmail;