import { Form } from '@unform/web';
import { useEffect, useState } from 'react';
import { GoCalendar } from 'react-icons/go';
import Input from '../../../../../components/Input';
import { Container, Modal } from './styles';

import { useAuth } from 'context/auth';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import api from '../../../../../services/api';

moment.locale();

/**
 * Component that displays the details of an appointment in a modal.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.agendaButtons - Flag to show or hide agenda buttons.
 * @param {string} [props.id='modalDetalhesDoAtendimento'] - The ID of the modal.
 * @param {number} props.agendamentoId - The ID of the appointment.
 * @param {Function} [props.onClose=() => {}] - Function to handle modal close event.
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * <DetalhesDoAgendamento
 *   agendaButtons={true}
 *   agendamentoId={123}
 *   onClose={() => console.log('Modal closed')}
 * />
 */
export default function DetalhesDoAgendamento({
    agendaButtons,
    id = 'modalDetalhesDoAtendimento',
    agendamentoId,
    onClose = () => { },
}) {
    const user = useAuth();
    const [agendamento, setAgendamento] = useState();
    const dayName = ['Domingo', 'Segunda - Feira', 'Terça - Feira', 'Quarta - Feira', 'Quinta - Feira', 'Sexta - Feira', 'Sábado'];

    const navigate = useNavigate();

    const handleOutsideClick = (e) => {
        if (e.target.id === id) onClose();
    };

    const getAgendamento = () => {
        api.get(`api/agendamento/${agendamentoId}?with=terapium,especialidade,modalidade,tipo_atendimento,` +
            'terapeutum.terapeuta_terapia.terapium,terapeutum.terapeuta_especialidades.especialidade').then(response => {
                setAgendamento(response.data);
            });
    };

    const showFormAgendamento = () => {
        return (
            <>
                <div className='row'>
                    <div className='col-6'>
                        <Input disabled title="Nome:" name="paciente" value={agendamento.paciente.nome} />
                    </div>
                    <div className='col-3'>
                        <Input disabled title="Data:" name="data" value={dayName[moment(agendamento.data_atendimento_inicial).weekday()]} />
                    </div>
                    <div className='col-3'>
                        <Input disabled title="Horario:" name="horario" value={moment.utc(agendamento.data_atendimento_inicial).format('HH:mm') + ' - ' + moment.utc(agendamento.data_atendimento_final).format('HH:mm')} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6'>
                        <Input disabled title="Plano de saúde:" name="plano_saude" value={agendamento.paciente.plano_saude.plano} />
                    </div>
                    <div className='col-6'>
                        <Input disabled title="Terapeuta:" name="Terapeuta" value={agendamento.terapeutum.nome} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6'>
                        <Input disabled title="Terapia:" name="Terapia" value={agendamento.terapium.terapia} />
                    </div>

                    <div className='col-6'>
                        <Input disabled title="Especialidade:" name="Especialidade" value={agendamento.especialidade.especialidade} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6'>
                        <Input disabled title="Modalidade:" name="Modalidade" value={agendamento.modalidade.modalidade} />
                    </div>
                    <div className='col-6'>
                        <Input disabled title="Tipo de Atendimento:" name="tipoAtendimento" value={agendamento.tipo_atendimento.tipo_atendimento} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6'>
                        <Input disabled title="Reserva:" name="reserva" value={agendamento.reserva ? 'Sim' : 'Não'} />
                    </div>
                </div>
                {agendaButtons ?
                    <div className='mt-2 d-flex align-items-center justify-content-between'>
                        <button className='btn-padrao'
                            onClick={() => { navigate('/dashboard/recepcao/agenda/paciente/' + agendamento.paciente.id); }}>
                            <GoCalendar size={22} color={'white'} style={{ marginRight: 10 }} />
                            <label>Agenda do Paciente</label>
                        </button>

                        <button className='btn-padrao'
                            onClick={() => { navigate('/dashboard/recepcao/agenda/terapeuta/' + agendamento.terapeuta_id); }}>
                            <GoCalendar size={22} color={'white'} style={{ marginRight: 10 }} />
                            <label>Agenda do Terapeuta</label>
                        </button>
                    </div>
                    : ''}
                {user?.user?.tipo_user?.toLowerCase() !== 'terapeuta' && user?.user?.tipo_user?.toLowerCase() !== 'responsavel' &&
                    <Input disabled title="Comentário:" name="comentario" value={agendamento.comentario} />
                }
            </>
        );
    };

    useEffect(() => {
        getAgendamento();
    }, []);

    return (
        <Modal id={id} onClick={handleOutsideClick}>
            {agendamento &&
                <Container>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <label style={{ fontWeight: '900', fontSize: '18', color: '#6A3BAF' }}>Detalhes do Atendimento</label>
                    </div>
                    <Form>
                        {showFormAgendamento()}

                        <div className='mt-2 d-flex align-items-center justify-content-center'>
                            <button className='btn-padrao' onClick={() => onClose()}>
                                Voltar
                            </button>
                        </div>
                    </Form>
                </Container>
            }
        </Modal>
    );
}