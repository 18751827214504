//#region IMPORTAÇÕES
import { useEffect, useRef, useState } from 'react';
import { TutorialBadge } from 'components/AjudanteInicial';
import { Dashboard } from 'helpers/dashboard.helper';
import moment from 'moment';
import { ScaleLoader } from 'react-spinners';
import AcessoRapido from '../../components/AcessoRapido';
import { MostradoresColoridos } from '../../components/MostradoresColoridos';
import { useAuth } from '../../context/auth';
import api from '../../services/api';
import RecadosCard from '../DashboardPadrao/RecadosCard';
import { Container, ContainerSub, GridHeader, GridRecados, Header } from './styles';
import { usePageBase } from 'context/page-base';
import BalaoDica from 'components/Baloes/BalaoDica';
import ModalBase from 'components/ModalBase';
import { Form } from '@unform/web';
import useListas from 'context/listas-de-selecao';
import InstallApp from 'components/InstallApp';
//#endregion

/**
 * Componente de Dashboard Padrão.
 *
 * Este componente é responsável por renderizar o dashboard padrão do sistema,
 * exibindo informações como mostradores, recados e acessos rápidos.
 *
 * @component
 * @example
 * return (
 *   <DashboardPadrao />
 * )
 *
 * @returns {JSX.Element} O componente de Dashboard Padrão.
 *
 * @function
 * @name DashboardPadrao
 *
 * @description
 * Utiliza os hooks `usePageBase`, `useAuth`, `useListas`, `useRef`, `useState` e `useEffect`.
 * Carrega dados do usuário, listas e configurações do dashboard.
 * Renderiza componentes de mostradores, recados, acessos rápidos e modal de teste.
 *
 * @hook
 * @name usePageBase
 * @param {Object} options - Opções para configuração da página.
 * @param {string} options.title - Título da página.
 * @param {string} options.description - Descrição da página.
 * @param {boolean} options.hasTitle - Indica se a página tem título.
 * @param {boolean} options.hasSubmenu - Indica se a página tem submenu.
 *
 * @hook
 * @name useAuth
 * @returns {Object} auth - Objeto de autenticação.
 * @returns {Object} auth.user - Dados do usuário autenticado.
 *
 * @hook
 * @name useListas
 * @param {Array<string>} listas - Listas a serem buscadas.
 * @returns {Object} listas - Objeto com funções para buscar listas.
 * @returns {Function} listas.fetchListas - Função para buscar listas.
 *
 * @hook
 * @name useRef
 * @returns {Object} ref - Referência para o elemento DOM.
 *
 * @hook
 * @name useState
 * @param {any} initialState - Estado inicial.
 * @returns {Array} state - Array com o estado atual e a função para atualizá-lo.
 *
 * @hook
 * @name useEffect
 * @param {Function} effect - Função de efeito colateral.
 * @param {Array<any>} dependencies - Array de dependências.
 *
 * @function
 * @name onLoad
 * @description
 * Função assíncrona para carregar dados do dashboard.
 * Configura mostradores, recados e acessos rápidos.
 *
 * @returns {Promise<void>}
 *
 * @typedef {Object} Dashboard
 * @property {Function} setMostradoresInformacao - Configura os mostradores de informação.
 * @property {Function} getEnableRecados - Verifica se os recados estão habilitados.
 * @property {Function} getEnableMostradores - Verifica se os mostradores estão habilitados.
 * @property {Function} getEnableAcessoRapido - Verifica se o acesso rápido está habilitado.
 * @property {Function} getMostradores - Retorna os mostradores configurados.
 * @property {Function} getAcessoRapido - Retorna os acessos rápidos configurados.
 * @property {Function} getEnableHeader - Verifica se o cabeçalho está habilitado.
 */
export default function DashboardPadrao() {
    usePageBase({
        title: 'Dashboard Padrão',
        description: 'Dashboard padrão do sistema',
        hasTitle: false,
        hasSubmenu: false,
    });
    //#region VARIAVEIS
    const { user } = useAuth();
    const { fetchListas } = useListas([
        'diagnostico',
        'especialidade',
        'terapeuta',
        'status_atendimento',
        'sala',
        'plano_saude',
        'paciente:ativo|select=id.nome.identificador',
        'modalidade',
        'horarios_atendimento',
        'tipo_atendimento',
        'tipo_demanda',
        'terapia',
        'terapia_paciente',
    ]);
    const tipo_user = user.tipo_user;
    const modalBaseTestRef = useRef();

    const [dashboardStructure, setDashboardStructure] = useState(null);
    const [recado, setRecado] = useState([]);
    const [mostradoresColoridos, setMostradoresColoridos] = useState({
        titulo: 'Estatísticas',
        mostradores: [],
    });
    const [acessoRapido, setAcessoRapido] = useState([]);
    const [loadingMostradores, setLoadingMostradores] = useState(false);
    const [loadingAcessos, setLoadingAcessos] = useState(false);
    const mostradorStyle = {
        borderTopLeftRadius: '5%',
        borderTopRightRadius: '5%',
        gridArea: 'mostradores',
        margin: '0',
        padding: '0'
    };
    //#endregion

    //#region FUNCOES

    const onLoad = async () => {
        const tipoUserUpper = tipo_user.toUpperCase();
        const dashboard = new Dashboard(tipoUserUpper, user.id);
        await dashboard.setMostradoresInformacao();

        try {
            if (dashboard.getEnableRecados()) {
                const recadoResponse = await api.get('api/recado/get/by/group/' + user.tipo_user);
                setRecado(recadoResponse.data);
            }
            if (dashboard.getEnableMostradores()) {
                const mostradores = dashboard.getMostradores();
                setMostradoresColoridos({
                    titulo: 'Estatísticas',
                    mostradores: mostradores
                });
            }
            if (dashboard.getEnableAcessoRapido) {
                const acessoRapido = dashboard.getAcessoRapido();
                setAcessoRapido(acessoRapido);
            }
            setDashboardStructure(dashboard);
            setLoadingMostradores(false);
            setLoadingAcessos(false);
        } catch (error) {
            console.error(error);
        }
    };
    //#endregion

    //#region USE EFFECTS
    useEffect(() => {
        if (tipo_user) {
            setLoadingMostradores(true);
            setLoadingAcessos(true);
            onLoad();
            if (tipo_user === 'DESENVOLVEDOR') {
                //fetchListas();
            }
        }
    }, [tipo_user]);
    useEffect(() => {
    }, []);
    //#endregion
    //#region HTML
    return (
        <>
            <Container className='row'>
                {!dashboardStructure &&
                    <div className='col-12 d-flex'>
                        <label className="text-center all-center w-content h-content mx-auto">
                            Carregando dashboard...<br></br><br></br>
                            <ScaleLoader color='#000' loading={true} />
                        </label>
                    </div>
                }
                <ContainerSub id='containerSub'>
                    {dashboardStructure && dashboardStructure.getEnableHeader() &&
                        <GridHeader id="header">
                            <Header>
                                <label>Bem Vindo {user?.name && user?.name}!</label>
                                <label>Hoje é dia {moment.utc().format('DD/MM/YYYY')}</label>
                            </Header>
                        </GridHeader>
                    }
                    {(dashboardStructure && dashboardStructure && dashboardStructure?.getEnableMostradores()) &&
                        <MostradoresColoridos mostradores={mostradoresColoridos.mostradores} style={mostradorStyle} titulo={mostradoresColoridos.titulo} id="mostradores" className='mostradores' name="mostradores" loading={loadingMostradores || mostradoresColoridos.mostradores.length === 0} />
                    }
                    {dashboardStructure?.getEnableRecados() &&
                        <>
                            <GridRecados id="recados">
                                <RecadosCard title={'Recado'} recado={recado} />
                            </GridRecados>
                            <BalaoDica elementoAncora='recados'>
                                Aqui você poderia visualizar os recados importantes do sistema e da clínica, sempre fique de olho 👀.
                            </BalaoDica>
                        </>
                    }
                    {acessoRapido && dashboardStructure && dashboardStructure?.getEnableAcessoRapido() &&
                        <AcessoRapido id="acesso" name="acesso" acessos={acessoRapido} loading={loadingAcessos} />
                    }
                </ContainerSub>
                {tipo_user.toLowerCase() === 'desenvolvedor'  && <>
                    <button className='btn-padrao' onClick={() => modalBaseTestRef.current.open()}>Abrir modal teste</button>
                    <ModalBase ref={modalBaseTestRef} title='Teste de modal' hasCloseButton={true} onOpen={() => alert('Abriu')} onClose={() => alert('Fechou')}>
                        <Form>
                            <div>
                                <div className="form-group">
                                    <label htmlFor="name">Nome:</label>
                                    <input type="text" id="name" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email">Email:</label>
                                    <input type="email" id="email" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="message">Mensagem:</label>
                                    <textarea id="message" className="form-control"></textarea>
                                </div>
                                <button type="submit" className="btn btn-primary">Enviar</button>
                            </div>
                        </Form>
                    </ModalBase>
                </>}
                {tipo_user === 'DESENVOLVEDOR'
                    ? <InstallApp />
                    : ''
                }
            </Container>
            {dashboardStructure &&
                <TutorialBadge
                    steps={
                        [
                            {
                                title: 'Bem Vindo',
                                text: <div className="d-flex flex-col">
                                    <span className="font-12px font-cinza">(Aperte em próximo para ir para a próxima etapa, ou no X para sair do tutorial)</span>
                                    <span>Bem vindo ao Neuro Sistema! Passaremos por algumas etapas, para você aprender como utilizar o sistema!</span>
                                </div>
                            },
                            { title: 'Informações Básicas', selector: '#header', text: 'Aqui você tem algumas informações básicas!' },
                            { title: 'Indicadores Importantes', selector: '#mostradores', text: 'Nesse local, você pode ver alguns indicadores importantes, passe o mouse por cima, para ver seus detalhes!' },
                            { title: 'Acesso Rápido', selector: '#acesso', text: 'Temos aqui alguns links de Acesso Rápido, clique para acessa-los!' },
                            { title: 'Recados Importantes', selector: '#recados', text: 'Nesse local, você pode ver alguns recados importantes, sempre se atente a isso!' },
                        ]
                    }
                    hasTopicos={true}
                    standartFinal={true}
                />
            }
        </>
    );
    //#endregion
};