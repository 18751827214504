import Button from "components/Button";
import { useEffect, useState } from "react";

const InstallApp = () => {
    const [deferredPrompt, setDeferredPrompt] = useState(null);

    const handleInstallClick = () => {
        if (deferredPrompt) {
            deferredPrompt.prompt();
            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the install prompt');
                } else {
                    console.log('User dismissed the install prompt');
                }
                setDeferredPrompt(null);
            });
        }
    };
    
    useEffect(() => {
        const handler = (e) => {
            e.preventDefault();
            setDeferredPrompt(e);
        };
        window.addEventListener('beforeinstallprompt', handler);

        return () => {
            window.removeEventListener('beforeinstallprompt', handler);
        };
    }, []);
    return (
        <div>
            <Button onClick={handleInstallClick}>Adicionar ao celular</Button>
        </div>
    );
}

export default InstallApp;