import bgImage from 'assets/background-image.jpeg';
import ModalTitulo from 'components/ModalTitulo';
import { useSnackbar } from 'context/snackbar';
import { toPng } from 'html-to-image';
import moment from 'moment';
import { cloneElement, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { CgClose } from 'react-icons/cg';
import Modal from 'react-modal';
import { Navigate } from 'react-router-dom';
import 'styles/reparticoes-css/agenda/agenda-modal.css';
import userGeneric from '../../../../assets/img_avatar.png';
import { useAuth } from '../../../../context/auth';
import { getLegendaAgendamento } from '../../../../helpers/agenda.helper';
import DetalhesDoAgendamento from '../../../../pages/RecepcaoDashboard/Agenda/Terapeuta/Modal/DetalhesDoAgendamento';
import { listOfStyles } from '../../../../pages/RecepcaoDashboard/Agenda/Terapeuta/styles';
import api from '../../../../services/api';
import Carregamento from '../../../Carregamento';
import { unstable_batchedUpdates } from 'react-dom';
import useIcons from 'context/icons';
const AgendaModalTerapeuta = forwardRef(({ id = 'agendaModal', outSideSet = () => { }, mode = 0 }, ref) => {
    //#region VARIÁVEIS
    const title = 'Agenda do Terapeuta';
    const [isOpen, setIsOpen] = useState(false);
    const user = useAuth();
    const snackbar = useSnackbar();
    const [isModalDetalhesDoAtendimentoVisible, setIsModalDetalhesDoAtendimentoVisible] = useState(false);
    const [loading, setloading] = useState(false);
    const { iconArray } = useIcons();

    const elementRef = useRef();
    const [baseWidth, baseHeight] = [150, 100];
    const styles = listOfStyles(baseWidth, baseHeight);

    const [terapeuta, setTerapeuta] = useState(null);
    const [terapiasTerapeuta, setTerapiasTerapeuta] = useState([]);
    const [horarios, setHorarios] = useState([]);
    const [atendimentos, setAtendimentos] = useState([]);
    const [atendimentoId, setAtendimentoId] = useState();
    const [selectedTerapeutaId, setSelectedTerapeutaId] = useState();
    const diaSemanaIndex = moment().weekday();
    const diasDaSemanaArray = [
        {
            value: 1,
            label: 'Segunda',
            short_label: 'S'
        },
        {
            value: 2,
            label: 'Terça',
            short_label: 'T'
        },
        {
            value: 3,
            label: 'Quarta',
            short_label: 'Q'
        },
        {
            value: 4,
            label: 'Quinta',
            short_label: 'Q'
        },
        {
            value: 5,
            label: 'Sexta',
            short_label: 'S'
        },
        {
            value: 6,
            label: 'Sábado',
            short_label: 'S'
        },
    ];
    const customStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 15
        },
        content: {
            position: 'absolute',
            top: '90px',
            left: '0',
            height: `calc(100vh - 90px)`,
            width: `100vw`,
            backgroundColor: '#FFFFFF',
            zIndex: 100,
            paddingTop: '0px',
        },
    };
    //#endregion
    //#region FUNÇÕES DA MODAL
    /**
     * Abre o modal de agenda para o terapeuta selecionado.
     *
     * @param {number} terapeuta_id - O ID do terapeuta cuja agenda será exibida no modal.
     */
    const openModal = useCallback((terapeuta_id) => {
        setSelectedTerapeutaId(terapeuta_id);
        setIsOpen(true);
        outSideSet(true);
    }, []);
    /**
     * Fecha o modal de agenda do terapeuta.
     * 
     * Esta função redefine o ID do terapeuta selecionado para null,
     * define o estado do modal como fechado e ajusta o estado externo.
     * 
     * @function
     */
    const closeModal = useCallback(() => {
        setSelectedTerapeutaId(null);
        setIsOpen(false);
        outSideSet(false);
    }, []);
    useImperativeHandle(ref, () => ({ openModal, closeModal }));
    //#endregion
    //#region HANDLES
    /**
     * Exporta um elemento referenciado como imagem PNG.
     *
     * @param {string} baixar - Ação a ser realizada: 'baixar' para baixar a imagem ou 'copiar' para copiar a imagem para a área de transferência.
     * @returns {void}
     */
    const handleExportToImage = useCallback((baixar) => {
        toPng(elementRef.current)
            .then((dataUrl) => {
                switch (baixar) {
                    case 'baixar':
                        snackbar.displayMessage('Baixando...');
                        const link = document.createElement('a');
                        link.download = `agenda-${terapeuta?.nome}-${moment().format('DD_MM_YYYY-HH_MM')}.jpeg`;
                        link.href = dataUrl;
                        link.click();
                        break;
                    case 'copiar':
                        fetch(dataUrl)
                            .then(res => res.blob())
                            .then(blob => {
                                navigator.clipboard.write([
                                    new ClipboardItem({ 'image/png': blob })
                                ]).then(() => {
                                    snackbar.displayMessage('Imagem copiada para a área de transferência.');
                                }).catch((err) => {
                                    console.error('Erro ao copiar imagem: ', err);
                                });
                            })
                            .catch((err) => {
                                console.error('Erro ao gerar o blob da imagem: ', err);
                            });
                        break;
                    default:
                        break;
                }
            })
            .catch((err) => {
                console.error('Erro ao gerar imagem:', err);
            });
    }, [snackbar]);
    //#endregion
    //#region FUNCOES
    /**
     * Função para buscar os dados de um terapeuta específico.
     * 
     * Esta função faz uma requisição à API para obter as informações de um terapeuta,
     * incluindo suas especialidades, terapias e disponibilidades, com base no ID do terapeuta selecionado.
     * 
     * @function
     * @returns {void}
     */
    const getTerapeuta = useCallback(() => {
        let terapeuta_id = selectedTerapeutaId;
        if (terapeuta_id !== undefined && terapeuta_id !== null) {
            api.get(`api/terapeuta/${terapeuta_id}?with=terapeuta_especialidades.especialidade,terapeuta_terapia.terapium,terapeuta_disponibilidades`)
                .then(async ({ data }) => {
                    setTerapeuta(data);
                    setTerapiasTerapeuta(data.terapeuta_terapia);
                });
        }
    }, [selectedTerapeutaId]);
    /**
     * Função para buscar os horários de atendimento de um terapeuta.
     * Faz uma requisição GET para a API e atualiza o estado com os dados recebidos.
     * 
     * @function
     * @returns {void}
     */
    const getAgendaHorarios = useCallback(() => {
        api.get('/api/horario-atendimento?filter[dia_semana]=1').then(({ data }) => {
            setHorarios(data);
        });
    }, []);
    /**
     * Função para buscar a agenda do dia de um terapeuta específico.
     * Faz uma requisição à API para obter os agendamentos filtrados pelo ID do terapeuta,
     * incluindo informações adicionais sobre a estação, sala, terapia e especialidade.
     * Os dados retornados são processados para adicionar o índice do dia da semana e a hora inicial formatada.
     * 
     * @function
     * @returns {void}
     */
    const getAgendaDia = useCallback(() => {
        const terapeuta_id = selectedTerapeutaId;
        api.get(`/api/agendamento?filter[terapeuta_id]=${terapeuta_id}&with=estacao,estacao.sala,terapium,especialidade`).then(({ data }) => {
            data = data.map(atendimento => {
                atendimento.dia_semana_index = moment(atendimento.data_atendimento_inicial).weekday();
                const horaInicial = atendimento.data_atendimento_inicial.split('T')[1];
                atendimento.hora_inicial = moment(horaInicial, 'HH:mm:ss').format('HH:mm');
                return atendimento;
            });
            setAtendimentos(data);
        });
    }, [selectedTerapeutaId]);
    /**
     * Abre o modal de detalhes do atendimento.
     *
     * @param {Object} detalhes - Objeto contendo os detalhes do atendimento.
     * @param {number} detalhes.id - ID do atendimento.
     */
    const modalDetalhesDoAtendimento = useCallback((detalhes) => {
        unstable_batchedUpdates(() => {
            setAtendimentoId(detalhes.id);
            setIsModalDetalhesDoAtendimentoVisible(true);
        });
    }, []);
    //#endregion
    //#region USE EFFECTS
    useEffect(async () => {
        await getAgendaHorarios();
    }, []);
    useEffect(async () => {
        if (selectedTerapeutaId !== undefined && selectedTerapeutaId !== null) {
            const carregaTudo = () => {
                getTerapeuta();
                getAgendaDia();
            };
            setloading(true);
            setTerapeuta(null);
            setTerapiasTerapeuta([]);
            setAtendimentos([]);
            await carregaTudo();
            setloading(false);
        }
    }, [selectedTerapeutaId]);
    //#endregion
    //#region HTML
    return (
        <>
            {loading && <Carregamento></Carregamento>}
            <Modal style={customStyles} id={id} isOpen={isOpen} onRequestClose={closeModal} shouldCloseOnOverlayClick={true} appElement={document.getElementById('root')} transparent>
                <nav className='navbar sticky-top bg-cinza-claro shadow' style={{ margin: '0px -30px 0px -30px' }}>
                    <div className="container-fluid d-flex align-items-center justify-content-between">
                        <div className='w-200px'></div>
                        <ModalTitulo>{title}</ModalTitulo>
                        <label className='btn-outline w-200px text-right' onClick={() => closeModal()}>
                            <CgClose size={22} color='red' />
                        </label>
                    </div>
                </nav>
                <br></br>
                <div className='d-flex justify-content-between'>
                    <div style={{ width: '70%' }}>
                        <div className='w-100per px-4 pb-4' style={{ backgroundImage: `url(${bgImage})`, }} ref={elementRef}>
                            <h4 className='text-center w-100per font-bold font-roxo pt-4 pb-2'>{terapeuta?.nome}</h4>
                            <table className='table-agenda'>
                                <thead className='thead'>
                                    <tr>
                                        <td align={'center'} className='tr1'>Horário:</td>
                                        {diasDaSemanaArray.map(dia => (
                                            <td key={dia.value} align={'center'} className={diaSemanaIndex === dia.value ? 'tr2' : 'tr1'} style={{ width: baseWidth }}>{dia.label}</td>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody className='tbody'>
                                    {horarios.map((horario, horarioIdx) => {
                                        const horasExtenso = moment(horario.horario.split('T')[1], 'HH:mm:ss').format('HH:mm');
                                        return (
                                            <tr key={`linha-horario-${horarioIdx}`}>
                                                <td align={'center'} className='tr-hora' style={{ height: baseHeight }}>{horasExtenso}</td>
                                                {diasDaSemanaArray.map(dia => {
                                                    let atendimentoEncontrado = atendimentos.filter(atendimento => atendimento.reserva === 0 && (atendimento.dia_semana_index === dia.value && atendimento.hora_inicial === horasExtenso));
                                                    const temReserva = atendimentos.filter(atendimento => atendimento.reserva === 1 && (atendimento.dia_semana_index === dia.value && atendimento.hora_inicial === horasExtenso)).length > 0;
                                                    const temAtendimento = atendimentoEncontrado.length > 0;
                                                    let legenda;
                                                    let content;
                                                    let div2style;
                                                    if (temAtendimento) {
                                                        atendimentoEncontrado = atendimentoEncontrado[0];
                                                        legenda = getLegendaAgendamento(atendimentoEncontrado, atendimentos);
                                                        div2style = {
                                                            ...styles[legenda]
                                                        };
                                                        content = <div className='table-detaild-agenda'>
                                                            <span className='ns-abrevia-texto'>
                                                                {atendimentoEncontrado.estacao.sala.sala}/{atendimentoEncontrado.estacao.estacao}
                                                            </span>
                                                            <span className='ns-abrevia-texto'>
                                                                {atendimentoEncontrado?.terapium?.terapia}
                                                            </span>
                                                            <span className='ns-abrevia-texto'>
                                                                {atendimentoEncontrado?.especialidade?.especialidade}
                                                            </span>
                                                            <span className='ns-abrevia-texto'>
                                                                {atendimentoEncontrado.paciente.identificador}
                                                            </span>
                                                        </div>;
                                                    }
                                                    const disponibilidade = terapeuta?.terapeuta_disponibilidades?.some(d => (d.horario === horario.horario) && (d.dia_semana === dia.value));
                                                    if (!disponibilidade) {
                                                        div2style = {
                                                            ...div2style,
                                                            backgroundColor: 'rgba(255, 0, 0, 0.2)',
                                                            color: 'black',
                                                            fontWeight: 'bold'
                                                        };
                                                    }
                                                    return (
                                                        <td onClick={() => temAtendimento ? modalDetalhesDoAtendimento(atendimentoEncontrado) : null}
                                                            key={`linha-dia-${dia.value}-horario-${horarioIdx}`}
                                                            className={`tr-vazio text-left d-grid font-size-12px ps-1 ${temAtendimento ? 'cursor-zoom' : ''}`}
                                                            style={{ ...div2style, width: baseWidth, height: baseHeight, border: temReserva ? '1px solid red' : 'none' }}>
                                                            {content}
                                                        </td>

                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='w-200px-i d-flex flex-column'>
                        <button className='btn-padrao' type='button' onClick={() => handleExportToImage('baixar')}>
                            {cloneElement(iconArray['baixar'], { style: { marginRight: 10 } })} Baixar Agenda
                        </button>
                        <button className='btn-padrao mt-4' type='button' onClick={() => handleExportToImage('copiar')}>
                            {cloneElement(iconArray['copiar'], { style: { marginRight: 10 } })} Copiar Agenda
                        </button>
                    </div>
                    <div className='d-flex flex-column align-items-center bg-white w-300px h-content-i' style={{ boxShadow: '0 0 10px 0px silver', borderRadius: 10, height: 'auto' }}>
                        <div className='d-flex flex-column'>
                            <div className='d-flex justify-content-center'>
                                <img src={(terapeuta === null)
                                    ? userGeneric
                                    : (terapeuta.foto_perfil_full === null || terapeuta.foto_perfil_full === '')
                                        ? userGeneric
                                        : terapeuta.foto_perfil_full
                                } height={75} width={75} className='perfil-image rounded-circle mb-2 mt-2' alt='' />
                            </div>
                            <div className='d-flex flex-column m-2'>
                                <span style={{}}>
                                    <label style={{ color: '#707070', fontWeight: 'bold', textAlign: 'center' }}>Nome:</label>
                                    {terapeuta?.nome}
                                </span>
                                <span>
                                    <label style={{ color: '#707070', fontWeight: 'bold', }}>
                                        Terapias:
                                    </label>
                                    {terapiasTerapeuta &&
                                        <ul>
                                            {terapiasTerapeuta.map((value, index) => {
                                                return <li key={index}>{value.terapium.terapia}</li>;
                                            })}
                                        </ul>
                                    }
                                </span>
                            </div>
                        </div>
                        <button className='btn-padrao mb-2' onClick={() => { Navigate(`/dashboard/${user.tipo_user}/terapeutas/perfil/${id}`); }} >Cadastro</button>
                    </div>
                </div>
            </Modal>
            {isModalDetalhesDoAtendimentoVisible ? <DetalhesDoAgendamento
                agendamentoId={atendimentoId}
                onClose={() => setIsModalDetalhesDoAtendimentoVisible(false)}
            /> : (<></>)}
        </>
    );
    //#endregion
});
export default AgendaModalTerapeuta;