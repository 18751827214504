import { useState } from 'react';
import api from 'services/api';

const useEspecialidadesData = () => {
    const [especialidadesOptions, setEspecialidadesOptions] = useState([]);

    const fetchEspecialidades = async () => {
        try {
            const { data } = await api.get('api/especialidade');
            const options = mapEspecialidadesToOptions(data);
            setEspecialidadesOptions(options);
        } catch (error) {
            console.error('Erro ao carregar especialidades:', error);
        }
    };
    const mapEspecialidadesToOptions = (data) => {
        return data.map((especialidade) => ({
            value: especialidade.id,
            label: especialidade.especialidade
        }));
    };

    return { especialidadesOptions, fetchEspecialidades, mapEspecialidadesToOptions };
};

export default useEspecialidadesData;