import { useState } from 'react';
import api from 'services/api';

const useTerapeutasData = () => {
    const [terapeutasOptions, setTerapeutasOptions] = useState([]);

    const mapTerapeutasData = (data) => {
        return data.map((terapeuta) => ({
            value: terapeuta.id,
            label: terapeuta.nome
        }));
    };

    const fetchTerapeutasData = async () => {
        try {
            const { data } = await api.get('api/terapeuta?select=id,nome');
            const options = mapTerapeutasData(data);
            setTerapeutasOptions(options);
        } catch (error) {
            console.error('Erro ao carregar terapeutas:', error);
        }
    };

    return { terapeutasOptions, fetchTerapeutasData, mapTerapeutasData };
};

export default useTerapeutasData;