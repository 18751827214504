import Button from "components/Button";
import { useSnackbar } from "context/snackbar";
import Public from "layouts/Public";
import { useCallback, useEffect, useState } from "react";
import { ScaleLoader } from "react-spinners";
import api from "services/api";
import Swal from "sweetalert2";
import { Container } from "../styles";
import Input from "components/Input";
import { Form } from "@unform/web";
import { useNavigate } from "react-router-dom";
import { css } from "styled-components";
import { FiLock } from "react-icons/fi";

/**
 * Componente de recuperação de senha.
 * 
 * Este componente permite ao usuário redefinir sua senha utilizando um token de recuperação enviado por e-mail.
 * 
 * @component
 * @returns {JSX.Element} - O componente de recuperação de senha.
 * 
 * @example
 * // Exemplo de uso:
 * <RecuperarSenha />
 * 
 * @description
 * O componente RecuperarSenha renderiza um formulário onde o usuário pode inserir uma nova senha e confirmá-la.
 * Ele também lida com a verificação do token de recuperação e a comunicação com a API para redefinir a senha.
 * 
 * @function handleResetPassword
 * @description Função assíncrona que lida com a redefinição de senha do usuário.
 * 
 * @function useEffect
 * @description Hook que verifica o token e o e-mail na URL e valida o token com a API.
 */
const RecuperarSenha = () => {
    //#region VARIAVEIS
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState('');
    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const navigate = useNavigate();
    const snackbars = useSnackbar();
    const override = css`
        display: block;
        margin: 0 auto;
    `;
    //#endregion
    //#region HANDLES
    /**
     * Função assíncrona que lida com a redefinição de senha do usuário.
     * 
     * Esta função faz uma requisição POST para a API de redefinição de senha com os dados fornecidos.
     * Em caso de sucesso, exibe uma mensagem de sucesso e redireciona o usuário para a página de login.
     * Em caso de erro, exibe uma mensagem de erro.
     * 
     * @async
     * @function handleResetPassword
     * @returns {Promise<void>} - Uma promessa que resolve quando a operação de redefinição de senha é concluída.
     */
    const handleResetPassword = useCallback(async () => {
        try {
            setLoading(true);
            await api.post('/api/reset-password', {
                email,
                token,
                password: newPassword,
                password_confirmation: confirmPassword,
            });
            snackbars.displayMessage('Sua senha foi alterada com sucesso.', 'success');
            navigate('/');
        } catch (err) {
            snackbars.displayMessage('Ocorreu um erro ao redefinir sua senha, tente novamente.', 'error');
        } finally {
            setLoading(false);
        }
    }, [email, token, newPassword, confirmPassword, snackbars, navigate]);
    //#endregion
    //#region USE EFFECT
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        const email = urlParams.get('email');
        if (token && email) {
            setToken(token);
            setEmail(email);
            api.post('/api/verifica-esqueci-token', { token, email })
                .then(response => {
                    if (response.data.message !== 'Token válido.') {
                        throw new Error('Token inválido');
                    }
                })
                .catch(() => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Token inválido ou expirado',
                        text: 'Por favor, solicite uma nova redefinição de senha.',
                    }).then((response) => {
                        if (response.isConfirmed || response.isDismissed) {
                            navigate('/');
                        }
                    });
                });
        }
    }, [navigate]);
    //#endregion
    //#region HTML
    return (
        <Public>
            <Container>
                {(token && email) && (
                    <Form onSubmit={handleResetPassword}>
                        <div className='d-flex justify-content-center'>
                            <img className='pointer' onClick={() => navigate('/')} width={400} src={'logo.png'} alt="Logo" />
                        </div>
                        <div className="form-group font-branca">
                            <label className="form-label">Nova Senha:</label>
                            <Input
                                name="newPassword"
                                icon={FiLock}
                                placeholder="Digite sua nova senha"
                                type="password"
                                required
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                        </div>
                        <div className="form-group font-branca">
                            <label className="form-label">Confirme a Nova Senha:</label>
                            <Input
                                name="confirmPassword"
                                icon={FiLock}
                                placeholder="Confirme sua nova senha"
                                type="password"
                                required
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                        </div>
                        <div className='d-flex justify-content-center mt-4'>
                            <Button className='w-200px-i' type="submit" isDisabled={loading}>
                                {loading
                                    ? <ScaleLoader css={override} size={150} color={'#fd931a'} loading={loading} />
                                    : 'Redefinir Senha'
                                }
                            </Button>
                        </div>
                    </Form>
                )}
            </Container>
        </Public>
    );
    //#endregion
}

export default RecuperarSenha;
