import A4Page from 'components/Folha/A4-Retrato';
import { TableDefaultPagination } from 'components/TableDefaultPagination';
import React, { useMemo, useRef, useState } from 'react';
import RelatorioEvolucaoPaciente from '../../../../components/Modais/TerapeutaRelatorios/RelatorioEvolucaoPaciente';

export function TableUltimosAtendimentos({ source, loading }) {

    const data = React.useMemo(() => source, [source]);
    const relatorioEdicaoModalRef = useRef();

    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [filename, setFilename] = useState('');
    const [isGeraDocumentoOpen, setIsGeraDocumentoOpen] = useState(false);

    const columns = useMemo(
        () => [
            {
                Header: 'Data',
                accessor: 'data_atendimento',
            },
            {
                Header: 'Hora',
                accessor: 'hora_atendimento',
            },
            {
                Header: 'Paciente',
                accessor: 'nome',
                filterable: true,
            },
            {
                Header: 'Identificador',
                accessor: 'identificador',
                filterable: true,
            },
            {
                Header: 'Plano de Saúde',
                accessor: 'plano_saude',
                filterable: true,
            },
            {
                Header: 'Status',
                accessor: 'status',
                filterable: true,
            },
            {
                Header: 'Sala - Estação',
                accessor: 'sala_estacao',
                filterable: true
            },
        ]
    );
    /* 
    {
        Header: 'Terapia:',
        accessor: 'terapia',
        filterable: true,
    },
    {
        Header: 'Especialidade:',
        accessor: 'especialidade',
        filterable: true,
    },
     */
    /* TODO: Temporiamente removido. Etapa 1.5
    {
        Header: 'Ações',
        accessor: 'evolucao_paciente_id',
        filterable: false,
        Cell: (({ cell }) => {
            if (cell.value) {
                return (
                    <div className="w-100per">
                        <button className="btn-icone" type="button" onClick={() => {
                            relatorioEdicaoModalRef?.current.openModal(cell.value);
                        }}>
                            <BsEye size={22}></BsEye>
                        </button>
                        <button className="btn-icone" type="button" onClick={() => navigate(`/dashboard/terapeuta/gerador-de-documento/${cell.value}`)}>
                            <BsDownload size={22} />
                        </button>
                    </div>
                );
            } else {
                return ('');
            }
        })
    }
    */

    return (
        <>
            {isGeraDocumentoOpen
                && <A4Page
                    onCloseClick={setIsGeraDocumentoOpen(false)}
                    modalMode={true}
                    title={title}
                    content={content}
                    filename={filename}
                />
            }
            <TableDefaultPagination
                source={data}
                columns={columns}
                searchBar={true}
                enablePageSize={false}
                enableLinesCount={true}
                enableExport={true}
                enablePageCount={true}
                enableGoToPage={false}
                pageSize={15}
                loading={loading}
            />
            <RelatorioEvolucaoPaciente ref={relatorioEdicaoModalRef} tipo={'visualizacao'} />
        </>
    );
}