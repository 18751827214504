import { useState } from 'react';
import api from 'services/api';

const useTerapiasPacienteData = () => {
    const [terapiasPacienteOptions, setTerapiasPacienteOptions] = useState([]);

    const mapTerapias = (data) => {
        return data.map((terapia) => ({
            value: terapia.id,
            label: `${terapia.terapia} - ${terapia.especialidade} - ${terapia.nome_reduzido}`,
            terapia_id: terapia.terapia_id,
            especialidade_id: terapia.especialidade_id
        }));
    };

    const fetchTerapias = async (pacienteId) => {
        try {
            const { data } = await api.get(`api/terapia_paciente?filter[paciente_id]=${pacienteId}`);
            const options = mapTerapias(data);
            setTerapiasPacienteOptions(options);
        } catch (error) {
            console.error('Erro ao carregar terapias:', error);
        }
    };

    return { terapiasPacienteOptions, fetchTerapias, mapTerapiasPaciente: mapTerapias };
};

export default useTerapiasPacienteData;