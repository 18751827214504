import { useState } from 'react';
import api from 'services/api';
import useDiagnosticosData from './diagnostico';
import useEspecialidadesData from './especialidade';
import useTerapeutasData from './terapeuta';
import useStatusAtendimentosData from './status-atendimento';
import useSalaData from './sala';
import usePlanoSaudeData from './plano-saude';
import usePacienteData from './paciente';
import useModalidadeData from './modalidade';
import useHorarioAtendimentoData from './horario-atendimento';
import useTipoAtendimentosData from './tipo-atendimento';
import useTipoDemandaData from './tipo-demanda';
import useTerapiasData from './terapia';
import useTerapiasPacienteData from './terapia-paciente';

/**
 * Custom hook to fetch and manage selection lists from the API.
 * 
 * @param {Array<string>} [listasHabilitadas=null] - An array of enabled lists to fetch from the API.
 * @returns {Object} An object containing the fetched lists and a function to fetch the lists.
 * 
 * @property {Object} listas - The state object containing the fetched lists.
 * @property {Function} fetchListas - The function to fetch the lists from the API.
 * 
 * The following lists can be consulted:
 * - diagnostico
 * - especialidade
 * - terapeuta
 * - status_atendimento
 * - sala
 * - sala_estacao
 * - plano_saude
 * - paciente
 * - modalidade
 * - horarios_atendimento
 * - horarios_atendimento_com_fim
 * - tipo_atendimento
 * - tipo_demanda
 * - terapia
 * - terapia_paciente
 */
const useListas = (listasHabilitadas = null) => {
    // Hooks
    const { mapDiagnosticos } = useDiagnosticosData();
    const { mapEspecialidadesToOptions } = useEspecialidadesData();
    const { mapTerapeutasData } = useTerapeutasData();
    const { mapStatusAtendimentos } = useStatusAtendimentosData();
    const { mapSalaData, mapSalaEstacaoData } = useSalaData();
    const { mapPlanoSaudeData } = usePlanoSaudeData();
    const { mapPacienteData } = usePacienteData();
    const { mapModalidadeData } = useModalidadeData();
    const { mapHorarioAtendimentoData, mapHorarioAtendimentoComFimData } = useHorarioAtendimentoData();
    const { mapTipoAtendimentosData } = useTipoAtendimentosData();
    const { mapTipoDemandaToOptions } = useTipoDemandaData();
    const { mapTerapias } = useTerapiasData();
    const { mapTerapiasPaciente } = useTerapiasPacienteData();

    const [listas, setListas] = useState({});
    /**
     * Fetches selection lists from the API based on the enabled lists.
     * Maps the fetched data to appropriate formats using specific mapping functions.
     * Updates the state with the mapped lists.
     * 
     * @async
     * @function fetchListas
     * @returns {Promise<void>} A promise that resolves when the fetching and mapping are complete.
     * @throws Will log an error message if the API request fails.
     */
    const fetchListas = async () => {
        if (listasHabilitadas) {
            await api.get('api/lista-de-selecao?listas=' + listasHabilitadas.join(','))
                .then(({ data }) => {
                    const { lista } = data;
                    const listasMapeadas = {};
                    lista.forEach((item) => {
                        const chave = Object.keys(item)[0];
                        const valores = item[chave];
                        if (typeof valores === 'object') {
                            switch (chave) {
                                case 'diagnostico':
                                    listasMapeadas[chave] = mapDiagnosticos(valores);
                                    break;
                                case 'especialidade':
                                    listasMapeadas[chave] = mapEspecialidadesToOptions(valores);
                                    break;
                                case 'terapeuta':
                                    listasMapeadas[chave] = mapTerapeutasData(valores);
                                    break;
                                case 'status_atendimento':
                                    listasMapeadas[chave] = mapStatusAtendimentos(valores);
                                    break;
                                case 'sala':
                                    listasMapeadas[chave] = mapSalaData(valores);
                                    break;
                                case 'sala:|with=estacaos':
                                    listasMapeadas[chave] = mapSalaEstacaoData(valores);
                                    break;
                                case 'plano_saude':
                                    listasMapeadas[chave] = mapPlanoSaudeData(valores);
                                    break;
                                case 'paciente':
                                    listasMapeadas[chave] = mapPacienteData(valores);
                                    break;
                                case 'modalidade':
                                    listasMapeadas[chave] = mapModalidadeData(valores);
                                    break;
                                case 'horarios_atendimento':
                                    listasMapeadas[chave] = mapHorarioAtendimentoData(valores);
                                    break;
                                case 'horarios_atendimento_com_fim':
                                    listasMapeadas[chave] = mapHorarioAtendimentoComFimData(valores);
                                    break;
                                case 'tipo_atendimento':
                                    listasMapeadas[chave] = mapTipoAtendimentosData(valores);
                                    break;
                                case 'tipo_demanda':
                                    listasMapeadas[chave] = mapTipoDemandaToOptions(valores);
                                    break;
                                case 'terapia':
                                    listasMapeadas[chave] = mapTerapias(valores);
                                    break;
                                case 'terapia_paciente':
                                    listasMapeadas[chave] = mapTerapiasPaciente(valores);
                                    break;
                                default:
                                    listasMapeadas[chave] = valores;
                                    break;
                            }
                        }
                    });
                    setListas(listasMapeadas);
                })
                .catch((error) => {
                    console.error('Erro ao carregar listas:', error);
                });
        }
    };

    return { listas, fetchListas };
};

export default useListas;