//#region IMPORTAÇÕES
import { usePageBase } from 'context/page-base';
import moment from 'moment';
import ControleDiario from 'pages/RecepcaoDashboard/Agenda/ControleDiario';
import { useEffect, useState } from 'react';
//#endregion

/**
 * Componente CheckInPendentes
 * 
 * Este componente exibe os check-ins pendentes para o dia selecionado.
 * Utiliza o hook `usePageBase` para configurar a página com título, descrição e rotas.
 * 
 * @component
 * 
 * @example
 * return (
 *   <CheckInPendentes />
 * )
 * 
 * @returns {JSX.Element} Retorna o componente CheckInPendentes.
 * 
 * @function recuperarParametroDaUrl
 * @description Função para recuperar um parâmetro da URL.
 * @param {string} chave - A chave do parâmetro a ser recuperado.
 * @returns {string|null} O valor do parâmetro ou null se não encontrado.
 * 
 * @hook useEffect
 * @description Hook que recupera o parâmetro 'data' da URL e define o estado `urlData`.
 * 
 * @hook useState
 * @description Hook que gerencia o estado `urlData` e `dataMoment`.
 */
const CheckInPendentes = () => {
    usePageBase({
        title: 'Check-ins Pendentes',
        description: 'Visualize os check-ins pendentes para o dia selecionado.',
        hasTitle: true,
        hasSubmenu: true,
        routes: [
            'dashboard/{route_tipo_user}/controlediario/consutlas-e-alteraçoes',
        ]
    });
    //#region VARIAVEIS
    const [urlData, setUrlData] = useState();
    const [dataMoment, setDataMoment] = useState(moment(urlData));
    //#endregion

    //#region FUNCOES
    function recuperarParametroDaUrl(chave) {
        // Cria um objeto URLSearchParams a partir da string de consulta atual
        const parametros = new URLSearchParams(window.location.search);

        // Retorna o valor do parâmetro
        return parametros.get(chave);
    }
    //#endregion

    //#region USE EFFECTS
    useEffect(() => {
        const data = recuperarParametroDaUrl('data');
        setUrlData(data ? data : moment().format('YYYY-MM-DD'));
    }, []);
    //#endregion

    //#region HTML
    return (
        <div className='m-0 p-0' style={{ padding: '0 0 0 50' }}>
            <div className='container'>
                <div className='w-100per d-flex flex-col'>
                    <div className='col-12 d-flex text-right justify-content-center align-center mt-2'>
                        <span className='texto-data-controle-diario me-2 ml-2'>Data: <b>{dataMoment.format('dddd - DD/MM/YYYY')}</b></span>
                    </div>
                </div>
            </div>
            <ControleDiario
                modoIncorporado={true}
                modoDeConsulta='check-ins-pendentes'
                funcoesHabilitadas={{
                    filtro: true,
                    navegacao: false,
                    salvar: false,
                    carregar: false,
                    novo_atendimento: false,
                    solicitacoes_alteracao: false,
                    filtro_solicitacoes_alteracao: false,
                    atualizacao: true,
                    baixar_excel: false,
                    legenda: true,
                    botao_alteracao: false,
                    controle_de_execucao: true,
                    altera_sugestao_recepcao: false,
                    altera_liberado: false,
                    coluna_p: false,
                    paciente_filtro_inline: true,
                }}
                filtrosHabilitados={{
                    terapeuta: true,
                    paciente: true,
                    status_atendimento: false,
                    plano_saude: true,
                }}
            />
        </div>
    );
    //#endregion
};

export default CheckInPendentes;