import { useState } from 'react';
import api from 'services/api';

const useSalaData = () => {
    const [salaOptions, setSalaOptions] = useState([]);
    const [salaEstacaoOptions, setSalaEstacaoOptions] = useState([]);

    const mapSalaData = (data) => {
        return data.map((sala, index) => ({
            label: sala.sala,
            value: sala.id,
            key: index
        }));
    };

    const mapSalaEstacaoData = (data) => {
        let selectEstacao = [];
        data.forEach(s => {
            s.estacaos.forEach(e => {
                selectEstacao.push({
                    value: e.id,
                    label: s.sala + ' - ' + e.estacao,
                });
            });
        });
        return selectEstacao;
    };

    const fetchSalaData = async () => {
        try {
            const { data } = await api.get('api/sala');
            const options = mapSalaData(data);
            setSalaOptions(options);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchSalaEstacaoData = async () => {
        try {
            const { data } = await api.get('api/sala?with=estacaos');
            const options = mapSalaEstacaoData(data);
            setSalaEstacaoOptions(options);
        } catch (error) {
            console.error(error);
        }
    };

    return { salaOptions, salaEstacaoOptions, fetchSalaData, fetchSalaEstacaoData, mapSalaData, mapSalaEstacaoData };
};

export default useSalaData;