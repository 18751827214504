import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { AiOutlineCalendar, AiOutlineEdit, AiOutlineEye, AiOutlineUnlock } from 'react-icons/ai';
import Swal from 'sweetalert2';
import api from '../../../../services/api';
import { FaBookOpen, FaRegAddressBook } from 'react-icons/fa';
import { TableDefaultPagination } from '../../../../components/TableDefaultPagination';
import AgendaModalTerapeuta from '../../../../components/Modais/Terapeuta/AgendaModal';
import ContatosModal from '../../../../components/Modais/Terapeuta/ContatosModal';
import MiniCurriculoModal from '../../../../components/Modais/Terapeuta/MiniCurriculoModal';
import DadosBasicosModal from '../../../../components/Modais/Terapeuta/DadosBasicosModal';
import TerapeutaService from 'services/TerapeutaService';

export function TableTerapeutas({ source, tipo_usuario, carregaTerapeuta, permissoes, createButton = null }) {
    const navigate = useNavigate();
    const dadosBasicosModalRef = useRef();
    const agendaModalRef = useRef();
    const contatosModalRef = useRef();
    const miniCurriculoModalRef = useRef();
    const [selectedTerapeutaId, setSelectedTerapeutaId] = useState(null);

    const columns = useMemo(() => [
        {
            Header: 'Nome:',
            accessor: 'nome',
            filterable: true,
        },
        {
            Header: 'Terapias:',
            accessor: 'terapias',
            filterAccessor: 'terapeuta_terapia',
            filterValue: 'terapium.id',
            filterLabel: 'terapium.terapia',
            filterable: false,
            Cell: ({ cell }) => {
                const strigifyTerapias = cell.row.original?.terapeuta_terapia?.map(terapia => terapia.terapium.terapia).join(', ');
                return strigifyTerapias && strigifyTerapias.length > 0 ? strigifyTerapias : 'Sem terapias cadastradas';
            }
        },
        {
            Header: 'Status:',
            accessor: 'status',
            filterable: true,
            Cell: ({ cell }) => {
                const { className, title } = TerapeutaService.getStatusAttributes(cell.row.original.status);
                return (
                    <p className='p-0 m-0 d-flex align-items-center justify-content-center' title={title}>
                        <b className={className}>
                            {cell.row.original.status}
                        </b>
                    </p>
                );
            }
        },
        {
            Header: 'Ações',
            accessor: 'acoes',
            Cell: ({ cell }) => (<div className='d-flex align-center justify-center'>
                {(permissoes['liberar_acesso'] && cell.row.original.status === 'Bloqueado')
                    ? <button className='btn-icone' title='Liberar do acesso do terapeuta.' onClick={() => liberarTerapeuta(cell.row.original.id, cell.row.original.nome)}>
                        <AiOutlineUnlock size={22} />
                    </button>
                    : null
                }
                {permissoes['edição'] ?
                    <>
                        <button className="btn-icone" title='Editar informações.' onClick={() => navigate('/dashboard/' + tipo_usuario + '/terapeuta/perfil/' + cell.row.original.id)}>
                            <AiOutlineEdit size={22} />
                        </button>
                    </>
                    : null
                }
                {permissoes['dados_básicos'] ?
                    <button className="btn-icone" title='Visuializar informações básicas.' onClick={() => handleOpenDadosBaiscos(cell.row.original.id)}>
                        <AiOutlineEye size={22} />
                    </button>
                    : null
                }
                {permissoes['agenda'] ?
                    <button className="btn-icone" title='Acessar agenda.' onClick={() => handleOpenModalAgenda(cell.row.original.id)}>
                        <AiOutlineCalendar size={22} />
                    </button>
                    : null
                }
                {permissoes['contatos'] ?
                    <button className="btn-icone" title='Visualizar lista de contatos.' onClick={() => handleOpenContatosModal(cell.row.original.id)}>
                        <FaRegAddressBook size={22} />
                    </button>
                    : null
                }
                {permissoes['mini_curriculo'] ?
                    <button className="btn-icone" title='Visualizar Mini Curriculo.' onClick={() => handleOpenMiniCurriculoModal(cell.row.original.id)}>
                        <FaBookOpen size={22} />
                    </button>
                    : null
                }
            </div>
            )
        }
    ], [permissoes, tipo_usuario]);

    const handleOpenMiniCurriculoModal = useCallback((id) => {
        if (miniCurriculoModalRef.current) {
            miniCurriculoModalRef.current.openModal(id);
        }
    }, []);

    const handleOpenContatosModal = useCallback((id) => {
        if (contatosModalRef.current) {
            contatosModalRef.current.openModal(id);
        }
    }, []);

    const handleOpenModalAgenda = useCallback((id) => {
        setSelectedTerapeutaId(id);
        if (agendaModalRef.current) {
            agendaModalRef.current.openModal(id);
        }
    }, []);

    const handleOpenDadosBaiscos = useCallback((id) => {
        setSelectedTerapeutaId(id);
        if (dadosBasicosModalRef.current) {
            dadosBasicosModalRef.current.openModal(id);
        }
    }, []);

    const liberarTerapeuta = useCallback((id, nome) => {
        let options = {
            title: 'Deseja realmente liberar o acesso deste terapeuta? (' + id + ')',
            text: 'O terapeuta ' + nome + ' possui evoluções atrasadas em seu cadastro. Deseja liberar seu acesso?',
            icon: 'question',
            showDenyButton: true,
            denyButtonText: 'Voltar',
            confirmButtonText: 'Liberar',
        };
        Swal.fire(options).then((response) => {
            if (response.isConfirmed) {
                api.patch('api/terapeuta/' + id + '?id=' + id, { status: 'Liberado' }).then(() => {
                    Swal.fire('Atualizado seu status com sucesso!', '', 'success');
                    carregaTerapeuta();
                }).catch((e) => {
                    Swal.fire('Houve um erro ao atualizar o status deste terapeuta, atualize a tela, e tente novamente.', '', 'error');
                    console.error(e, 'Erro na atualização de status do terapeuta de id: ' + id);
                });
            }
        });
    }, [carregaTerapeuta]);

    return (
        <>
            <TableDefaultPagination columns={columns} source={source} initialState={{ pageIndex: 0, pageSize: 4 }} tableTitle={'Listagem de Terapeutas'} searchBar={true} createButton={createButton} />
            <DadosBasicosModal ref={dadosBasicosModalRef} terapeuta_id={selectedTerapeutaId} />
            <AgendaModalTerapeuta ref={agendaModalRef} terapeuta_id={selectedTerapeutaId} />
            <ContatosModal ref={contatosModalRef} terapeuta_id={selectedTerapeutaId} />
            <MiniCurriculoModal ref={miniCurriculoModalRef} />
        </>
    );
}