import React from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../context/auth';
import moment from 'moment';
import NavLink from 'components/Header/NavLink';
moment().utc();

const HeaderTerapeuta = () => {
    const { hasDocumentosObrigatoriosPendentes } = useAuth();
    let url_atual = useLocation();

    return (
        <>
            {!hasDocumentosObrigatoriosPendentes &&
                <>
                    <NavLink key={'dashboard/terapeuta/agenda'}
                        link={'/dashboard/terapeuta/agenda'}
                        name={'Agenda'}
                        icon={'agenda'}
                        atualLocal={url_atual.pathname.split('/')[3]}
                        notificacao={0} />
                    <NavLink key={'dashboard/terapeuta/status-diario'}
                        link={'/dashboard/terapeuta/statusdiario'}
                        name={'Status Diário'}
                        icon={'status-diario'}
                        atualLocal={url_atual.pathname.split('/')[3]}
                        notificacao={0} />
                    {/* 
                    <NavLink key={'dashboard/terapeuta/pendencias'}
                        link={'/dashboard/terapeuta/pendencias'}
                        name={'Pendências'}
                        icon={'pendencias'}
                        atualLocal={url_atual.pathname.split('/')[3]}
                        notificacao={0} />
                    <NavLink key={'dashboard/terapeuta/solicitacoes'}
                        link={'/dashboard/terapeuta/solicitacoes'}
                        name={'Solicitações'}
                        icon={'solicitacoes'}
                        atualLocal={url_atual.pathname.split('/')[3]}
                        disabled={true}
                        notificacao={0} />
                        */}
                    <NavLink key={'dashboard/terapeuta/ultimos-atendimentos'}
                        link={'/dashboard/terapeuta/ultimos-atendimentos'}
                        name={'Atendimentos'}
                        icon={'atendimentos'}
                        atualLocal={url_atual.pathname.split('/')[3]}
                        notificacao={0} />
                </>
            }
        </>
    );
};

export default HeaderTerapeuta;
