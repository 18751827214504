import { Card } from 'components/Card/styles';
import { useAuth } from 'context/auth';
import useIcons from 'context/icons';
import { usePageBase } from 'context/page-base';
import { cloneElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { Container } from 'pages/AdminDashboard/Configuracoes/styles.js'

/**
 * Componente de configuração do administrador.
 * 
 * Este componente renderiza a tela de configurações do administrador, permitindo a navegação
 * para diferentes seções de configuração, como permissões, tipos de atendimento, terapias, 
 * especialidades, modalidades, salas, estações, documentos, área compartilhada, status, 
 * tipos de avaliação, horários de atendimento e cadastro de diagnóstico.
 * 
 * @component
 * @returns {JSX.Element} Retorna o componente de configuração do administrador.
 */
export default function FinanceiroHome() {
    //#region VARIAVEIS
    usePageBase({
        title: 'Financeiro',
        description: 'Tela inicial, que da acesso a todos as funcionalidades do Financeiro.',
        hasTitle: true,
        hasSubMenu: true
    });
    const navigate = useNavigate();
    const { coletaPermissoesFull } = useAuth();
    const { getIcon } = useIcons();
    const { user } = useAuth();
    const [permissoes, setPermissoes] = useState([]);
    const size = 24;
    //#endregion

    //#region USE EFFECTS
    useEffect(() => {
        const newPermissoes = coletaPermissoesFull();
        setPermissoes(newPermissoes);
    }, []);
    //#endregion

    //#region HTML
    return (
        <Container>
            <Card className='d-flex all-center px-4 py-2 flex-wrap w-100per-i h-content-i'>
                {permissoes
                    ? permissoes.map((permissao) => (
                        <button key={permissao.id} className='btn-acesso-rapido' onClick={() => navigate(`/dashboard/${user.route_tipo_user}${permissao.link}`)}>
                            {cloneElement(getIcon(permissao.icon), { size: size })}
                            <span>{permissao.title}</span>
                        </button>
                    ))
                    : <ClipLoader color='#000' />
                }
            </Card>
        </Container>
    );
    //#endregion
}
