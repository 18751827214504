import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Carregamento from 'components/Carregamento';
import { useAuth } from 'context/auth';
import api from 'services/api';
import { Container } from '../styles';
import { TableDefaultPagination } from 'components/TableDefaultPagination';
import RelatorioEvolucaoPaciente from 'components/Modais/TerapeutaRelatorios/RelatorioEvolucaoPaciente';
import { ChangeCase } from 'services/ChangeCase';
import { FiCheckCircle, FiXCircle } from 'react-icons/fi';
import { usePageBase } from 'context/page-base';
import useIcons from 'context/icons';
import Swal from 'sweetalert2';
import { useSnackbar } from 'context/snackbar';
import moment from 'moment';
import usePacienteData from 'context/paciente';
import useTerapeutasData from 'context/terapeuta';
import { Form } from '@unform/web';
import Input from 'components/Input';
import { AiOutlineClear } from 'react-icons/ai';
import Select from 'components/Select';
import Button from 'components/Button';
import { BsSearch } from 'react-icons/bs';

/**
 * Componente de listagem de glosas.
 * 
 * Este componente exibe uma tabela com os atendimentos e glosas, permitindo a visualização e edição de cada item.
 * 
 * @component
 * @returns {JSX.Element} Retorna o componente de listagem de glosas.
 * 
 * @example
 * <ListagemDeGlosas />
 * 
 * @function
 * @name ListagemDeGlosas
 * 
 * @description
 * O componente utiliza hooks do React para gerenciar o estado e efeitos colaterais. Ele carrega os atendimentos e glosas ao montar e coleta as permissões do usuário para a página atual.
 * 
 * @hook
 * @name usePageBase
 * @description Hook que configura a base da página, incluindo título e descrição.
 * 
 * @hook
 * @name useAuth
 * @description Hook que fornece informações de autenticação do usuário.
 * 
 * @hook
 * @name useState
 * @description Hook que gerencia o estado local do componente.
 * 
 * @hook
 * @name useRef
 * @description Hook que cria uma referência persistente para o modal de edição de relatório.
 * 
 * @hook
 * @name useMemo
 * @description Hook que memoiza as colunas da tabela para evitar re-renderizações desnecessárias.
 * 
 * @hook
 * @name useEffect
 * @description Hook que executa efeitos colaterais, como carregar dados ao montar o componente.
 * 
 * @function
 * @name coletaPermissoes
 * @description Função que coleta permissões do usuário para a página atual.
 * 
 * @function
 * @name carregaAtendimentosEGlosas
 * @description Função assíncrona que carrega os atendimentos e glosas da API.
 * 
 * @function
 * @name onLoad
 * @description Função que é executada ao carregar o componente, chamando as funções de carregar dados e coletar permissões.
 */
export default function ListagemDeGlosas() {
    const pageBase = usePageBase({
        title: 'Atendimentos e Glosas',
        description: 'Tela onde é possível visualizar os atendimentos e glosas. Além de glosar manualmente um atendimento.',
        hasTitle: true,
        hasSubMenu: false
    });
    const { getIcon } = useIcons();
    const snackbar = useSnackbar();
    const [loading, setloading] = useState(false);
    const [glosasList, setGlosasList] = useState([]);
    const [glosasListOriginal, setGlosasListOriginal] = useState([]);
    const [selectedEvolucao, setSelectedEvolucao] = useState([]);
    const relatorioEdicaoModalRef = useRef();
    const { pacienteOptions, fetchPacienteOptionsData } = usePacienteData();
    const { terapeutasOptions, fetchTerapeutasData } = useTerapeutasData();
    const [dataInicio, setDataInicio] = useState('');
    const [dataFim, setDataFim] = useState('');
    const [pacienteId, setPacienteId] = useState('');
    const [terapeutaId, setTerapeutaId] = useState('');
    const statusEvolucaoOptions = [
        { value: 'pendente', label: 'Pendente' },
        { value: 'realizada', label: 'Realizada' },
        { value: 'glosa_auditoria', label: 'Glosa Auditoria' },
        { value: 'glosa_aceita', label: 'Glosa Aceita' },
        { value: 'glosa_negada', label: 'Glosa Negada' },
        { value: 'glosa_interna', label: 'Glosa Interna' }
    ];
    const [statusEvolucao, setStatusEvolucao] = useState('');
    const [filters, setFilters] = useState({
        data_inicio: '',
        data_fim: '',
        paciente_id: '',
        terapeuta_id: '',
        status_evolucao: '',
    });
    const columns = useMemo(() => [
        {
            Header: 'Data do Atendimento',
            accessor: 'data_atendimento_inicial',
            Cell: (({ cell }) => moment(cell.value).format('DD/MM/YYYY HH:mm')),
            filterable: true,
        },
        {
            Header: 'Terapeuta',
            accessor: 'terapeuta_nome',
            Cell: (({ cell }) => cell.value),
            filterable: true,
        },
        {
            Header: 'Paciente',
            accessor: 'paciente_nome',
            Cell: (({ cell }) => cell.value),
            filterable: true,
        },
        {
            Header: 'Status',
            accessor: 'status_de_glosa',
            filterable: true,
            Cell: (({ cell }) => {
                const style = { width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '15px', margin: 'auto' };
                const status = ChangeCase.toSnakeCase(String(cell.value).toLowerCase());
                switch (status) {
                    case 'realizada':
                        return (
                            <b style={{ ...style, backgroundColor: '#00ff00' }}>
                                Realizada
                            </b>
                        );
                    case 'glosa_auditoria':
                        return (
                            <b style={{ ...style, backgroundColor: '#ff0000', color: 'white' }}>
                                Glosa Auditoria
                            </b>
                        );
                    case 'glosa_aceita':
                        return (
                            <b style={{ ...style, backgroundColor: '#0000ff', color: 'yellow' }}>
                                Glosa Aceita
                            </b>
                        );
                    case 'glosa_negada':
                        return (
                            <b style={{ ...style, backgroundColor: '#f0f0f0' }}>
                                Glosa Negada
                            </b>
                        );
                    case 'pendente':
                        return (
                            <b style={{ ...style, backgroundColor: '#ffff00' }}>
                                Pendente
                            </b>
                        );
                    case 'glosa_interna':
                        return (
                            <b style={{ ...style, backgroundColor: '#ff0000', color: 'white' }}>
                                Glosa Interna
                            </b>
                        );
                    default:
                        return (
                            <b style={{ ...style, backgroundColor: '#ccc' }}>
                                ...
                            </b>
                        );
                }
            })
        },
        {
            Header: 'Ações',
            Cell: (({ cell }) => {
                const id = cell.row.original.evolucao_paciente_id;
                const glosado = ChangeCase.toSnakeCase(String(cell.row.original.status_de_glosa).toLowerCase()).includes('glosa');
                setSelectedEvolucao(cell.row.original);
                return (
                    <div className="w-100per d-flex justify-content-center ">
                        {(id) && <>
                            <button className="btn-icone" type="button" onClick={() => relatorioEdicaoModalRef?.current.openModal(id)}>
                                {getIcon('visualizar')}
                            </button>
                            <button className='btn-icone' disabled={true} onClick={() => { }}>
                                {getIcon('editar')}
                            </button>
                            {!glosado &&
                                <button title='Botão que define a linha como desistênte.' color="#7340BD" className='btn-icone' onClick={() => handleGlosaAtendimento(id)}>
                                    <FiXCircle color="#7340BD" size={22} />
                                </button>
                            }

                            <button className='btn-icone some' title='Botão que define a linha como aceita.' color="#7340BD" onClick={() => handleAceitaAtendimento(id)}>
                                <FiCheckCircle color="#7340BD" size={22} />
                            </button>
                        </>
                        }
                    </div>
                );
            })
        }
    ], [getIcon]);

    //#region HANDLES
    /**
     * Função para tratar a glosa de um atendimento.
     * Exibe um modal de confirmação utilizando o Swal.fire, onde o usuário pode confirmar ou cancelar a ação.
     * Se confirmado, envia uma requisição PATCH para atualizar o status do atendimento e adicionar uma mensagem de resposta.
     * Exibe mensagens de sucesso ou erro utilizando o snackbar.
     *
     * @param {number} id - O ID do atendimento a ser glosado.
     */
    const handleGlosaAtendimento = useCallback((id) => {
        try {
            Swal.fire({
                title: 'Glosar Atendimento',
                text: 'Deseja realmente glosar este atendimento?',
                icon: 'warning',
                input: 'textarea',
                inputPlaceholder: 'Digite a mensagem de resposta...',
                inputAttributes: {
                    'aria-label': 'Digite a mensagem de resposta'
                },
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim',
                cancelButtonText: 'Cancelar'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const mensagem_resposta = result.value;
                    const updateObj = { status: 5, mensagem_resposta: mensagem_resposta };
                    await api.patch(`api/evolucao-paciente/${id}`, updateObj)
                        .then(() => {
                            snackbar.displayMessage('Atendimento glosado com sucesso!', 'success');
                            carregaAtendimentosEGlosas();
                        }).catch((error) => {
                            snackbar.displayMessage('Erro ao glosar atendimento!', 'error');
                            console.error(error);
                        });
                }
            })
        } catch (e) {
            snackbar.displayMessage('Erro ao glosar atendimento!', 'error');
            console.error(e);
        }
    }, []);

    const handleAceitaAtendimento = useCallback((id) => {
        try {
            Swal.fire({
                title: 'Aceitar Atendimento',
                text: 'Deseja realmente aceitar este atendimento?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim',
                cancelButtonText: 'Cancelar'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const updateObj = { status: 1 };
                    await api.patch(`api/evolucao-paciente/${id}`, updateObj)
                        .then(() => {
                            snackbar.displayMessage('Atendimento aceito com sucesso!', 'success');
                            carregaAtendimentosEGlosas();
                        }).catch((error) => {
                            snackbar.displayMessage('Erro ao aceitar atendimento!', 'error');
                            console.error(error);
                        });
                }
            })
        } catch (e) {
            snackbar.displayMessage('Erro ao aceitar atendimento!', 'error');
            console.error(e);
        }
    }, []);
    const handleChangeFilters = useCallback((e) => {
        const name = e.target.id || e.target.name;
        const value = e.target.value;

        switch (name) {
            case 'data_inicio':
                setDataInicio(value);
                break;
            case 'data_fim':
                setDataFim(value);
                break;
            case 'paciente_id':
                setPacienteId(value);
                break;
            case 'terapeuta_id':
                setTerapeutaId(value);
                break;
            case 'status_evolucao':
                setStatusEvolucao(value);
                break;
            default:
                break;
        }
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value
        }));
    }, []);
    //#endregion
    //#region FUNCOES
    /**
     * Função assíncrona que carrega atendimentos e glosas.
     * Faz uma requisição POST para a API com os parâmetros fornecidos e atualiza as listas de glosas.
     * 
     * @async
     * @function carregaAtendimentosEGlosas
     * @returns {Promise<void>} - Uma promessa que resolve quando os dados são carregados e as listas são atualizadas.
     * @throws {Error} - Lança um erro se a requisição falhar.
     */
    async function carregaAtendimentosEGlosas() {
        setloading(true);
        try {
            if (!(dataInicio || dataFim)) {
                setloading(false);
                Swal.fire({
                    title: 'Atenção!',
                    text: 'Informe a data de início e/ou fim para realizar a consulta.',
                    icon: 'warning',
                    confirmButtonText: 'OK'
                });
                return;
            }
            const { data } = await api.post('api/atendimento/relatorio/filtrado', {
                paciente_id: pacienteId || null,
                tipo_atendimento_id: null,
                data_inicio: dataInicio || null,
                data_fim: dataFim || null,
                terapeuta_id: terapeutaId || null,
                status_atendimento_id: 1,
                liberado: null,
                executado: null,
                especialidade_id: null,
                terapia_id: null,
                plano_saude_id: null,
                status_de_assinatura: null,
            });
            setGlosasList(data);
            setGlosasListOriginal(data);
            setloading(false);
        } catch (e) {
            console.error(e);
        }
    }
    //#endregion
    //#region USE EFFECTS
    useEffect(() => {
        fetchPacienteOptionsData();
        fetchTerapeutasData();
    }, []);
    //#endregion
    //#region HTML
    return (
        <Container>
            {loading ? <Carregamento></Carregamento> : ''}
            <Form id="div-filtro" className='col-12 row flex-wrap p-0 m-0'>
                <fieldset id="fieldset-periodo" name="parametros-consultar" className="ns-fieldset mt-4 col-sm-12 col-lg-6 mx-auto">
                    <legend className="mb-0">Período</legend>
                    <div className='row'>
                        <div className='col-lg-6 col-sm-12'>
                            <Input type="date" title="Data Inicio:" label="Data Inicio:" id="data_inicio" name="data_inicio" value={dataInicio} onChange={handleChangeFilters} />
                        </div>
                        <div className='col-lg-6 col-sm-12'>
                            <Input type="date" title="Data Fim:" label="Data Fim:" id="data_fim" name="data_fim" value={dataFim} onChange={handleChangeFilters} />
                        </div>
                    </div>
                </fieldset>
                <fieldset id="fieldset-paciente" name="parametros-consultar" className="ns-fieldset mt-4 col-sm-12 col-lg-6 mx-auto">
                    <legend className="mb-0">Paciente</legend>
                    <div className='row'>
                        <div className='col-12'>
                            <Select id="paciente_id" name="paciente_id" label="Paciente:" title="Paciente:" options={pacienteOptions} isClearable={true} value={pacienteId} onChange={(e) => handleChangeFilters({ target: { id: 'paciente_id', name: 'paciente_id', value: e.value } })} />
                        </div>
                    </div>
                </fieldset>
                <fieldset id="fieldset-terapeuta" name="parametros-consultar" className="ns-fieldset mt-4 col-sm-12 col-lg-12 mx-auto">
                    <legend className="mb-0">Terapeuta</legend>
                    <div className='row'>
                        <div className='col-12'>
                            <Select id="terapeuta_id" name="terapeuta_id" label="Terapeuta:" title="Terapeuta:" options={terapeutasOptions} isClearable={true} value={terapeutaId} onChange={(e) => handleChangeFilters({ target: { id: 'terapeuta_id', name: 'terapeuta_id', value: e.value } })} />
                        </div>
                    </div>
                </fieldset>
                <fieldset id="fieldset-status-evolucao" name="parametros-filtrar" className="ns-fieldset mt-4 col-sm-12 col-lg-3">
                    <legend className="mb-0">Status de Evolução</legend>
                    <div className='row'>
                        <div className='col-12' id={'filter_status_evolucao'}>
                            <Select id="status_evolucao" name="status_evolucao" label="Status de Evolução:" title="Status de Evolução:" options={statusEvolucaoOptions} isClearable={true} value={statusEvolucao} onChange={(e) => handleChangeFilters({ target: { id: 'status_evolucao', name: 'status_evolucao', value: e.value } })} />
                        </div>
                    </div>
                </fieldset>
            </Form>
            <TableDefaultPagination columns={columns} source={glosasList} searchBar={true} tableTitle={pageBase.title} createButton={
                <>
                    <Button className="w-50px-i btn-padrao me-2" type={'button'} onClick={() => { setDataInicio(''); setDataFim(''); setPacienteId(null); setTerapeutaId(null) }}>
                        <AiOutlineClear className="icone" size={22} />
                    </Button>
                    <Button className='w-300px-i searching' type={'button'} onClick={() => { carregaAtendimentosEGlosas(); }}>
                        <BsSearch size={22} className="icone me-3" /> Consultar Atendimentos
                    </Button>
                </>
            }></TableDefaultPagination>
            <RelatorioEvolucaoPaciente ref={relatorioEdicaoModalRef} onClose={() => { }} tipo={'visualizacao'} />
        </Container>
    );
    //#endregion
}