import React, { useEffect, useState } from 'react';
import PerfilTabela from './tabelas';
import { TableDefaultPagination } from 'components/TableDefaultPagination';
import { usePageBase } from 'context/page-base';
import api from 'services/api';
import Button from 'components/Button';
import useIcons from 'context/icons';
import { useNavigate } from 'react-router-dom';

const Tarefas = () => {
	//#region VARIAVEIS
	usePageBase({
		title: 'Tabelas de Tarefas',
		description: 'Gerencie as tarefas do sistema.',
		routes: [
			'dashboard/desenvolvedor/tarefas',
		],
	});
    const navigate = useNavigate();
	const { iconArray } = useIcons();
	const [tabelas, setTabelas] = useState([]);
	const [loading, setLoading] = useState(true);
	const [tabelaId, setTabelaId] = useState(null);
	const columns = [
		{
			Header: 'Nome',
			accessor: 'nome',
		},
		{
			Header: 'Descrição',
			accessor: 'descricao',
		},
		{
			Header: 'Etiquetas',
			accessor: 'etiquetas',
		},
		{
			Header: 'Habilitado',
			accessor: 'habilitado',
			Cell: ({ value }) => (value ? 'Sim' : 'Não'),
		},
		{
			Header: 'Ações',
			accessor: 'id',
			Cell: ({ value }) => (
				<div className="d-flex justify-content-center">
					<Button type="button" onClick={() => handleEditarTabela(value)} >
						{iconArray['editar']}
					</Button>
					<Button type="button" onClick={() => handleVisualizaTabela(value)} >
						{iconArray['observando']}
					</Button>
				</div>
			),
		}
	];
	//#endregion

	//#region HANDLES
	const handleVisualizaTabela = (id) => {
		navigate(`/dashboard/desenvolvedor/tarefas/${id}`);
	}

	const handleEditarTabela = (id) => {
		setTabelaId(id);
	}
	//#endregion

	//#region FUNCOES
	const fetchTabelas = async () => {
		try {
			const response = await api.get('/api/tabelas');
			setTabelas(response.data);
		} catch (error) {
			console.error('Erro ao buscar tabelas:', error);
		} finally {
			setLoading(false);
		}
	}
	//#endregion

	//#region USE EFFECTS
	useEffect(() => {
		fetchTabelas();
	}, []);
	//#endregion

	//#region HTML
	return (
		<div className="container mt-5">
			<TableDefaultPagination source={tabelas} columns={columns} createButton={
				<PerfilTabela tabelaId={tabelaId} setTabelaId={setTabelaId} onClose={fetchTabelas} />
			} tableTitle={'Listagem de Tabelas'} searchBar={true} loading={loading} />
		</div>
	)
	//#endregion
};

export default Tarefas;