import React, { useMemo, useRef } from 'react';
import Swal from 'sweetalert2';
import FuncionarioService from '../../../../services/FuncionarioService';
import { TableDefaultPagination } from '../../../../components/TableDefaultPagination';
import DadosBasicosModal from '../../../../components/Modais/FuncionariosModal/DadosBasicosModal';
import useIcons from '../../../../context/icons';

export function TableFuncionarios({ source, callback, openModal, setFuncionarioId, permissoes, createButton }) {
    const dadosBasicosRef = useRef();
    const { iconArray } = useIcons();

    const handleEditModal = (data) => {
        setFuncionarioId(data);
        openModal(true);
    };
    const handleDadosBasicosModal = (id) => {
        if (dadosBasicosRef.current) {
            dadosBasicosRef.current.openModal(id);
        }
    };
    const handleEnviaEmailRecuperaSenha = (id) => {
        FuncionarioService.enviarEmailRecuperacaoSenha(id)
            .then(() => {
                Swal.fire('E-mail enviado com sucesso!', '', 'success');
            })
            .catch(() => Swal.fire('Houve um erro ao tentar enviar o e-mail', '', 'error'));
    };

    const handleAtivarInativar = (id, status) => {
        const action = status === 'Ativo' ? 'inativar' : 'ativar';
        const confirmButtonText = status === 'Ativo' ? 'Inativar' : 'Ativar';
        const successMessage = status === 'Ativo' ? 'Registro inativado com sucesso!' : 'Registro ativado com sucesso!';
        const errorMessage = status === 'Ativo' ? 'Houve um erro ao tentar inativar este registro' : 'Houve um erro ao tentar ativar este registro';

        Swal.fire({
            title: `Deseja realmente ${action} este funcionário ?`,
            showDenyButton: true,
            confirmButtonText: confirmButtonText,
            denyButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                FuncionarioService[action](id)
                    .then(() => {
                        Swal.fire(successMessage, '', 'success');
                        callback();
                    })
                    .catch(() => Swal.fire(errorMessage, '', 'error'));
            }
        });
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Nome:',
                accessor: 'name',
                filterable: true,
            },
            {
                Header: 'E-mail:',
                accessor: 'email',
                filterable: true,
            },
            {
                Header: 'Tipo:',
                accessor: 'tipo_user',
                filterable: true,
            },
            {
                Header: 'Telefone:',
                accessor: 'telefone',
                filterable: true,
            },
            {
                Header: 'Status:',
                accessor: 'status',
                filterable: true,
                Cell: ({ cell }) => (!cell.value || String(cell.value) === 'Ativo' ? 'Ativo' : 'Inativo'),
            },
            {
                Header: 'Ações',
                accessor: 'acoes',
                Cell: ({ cell }) => (
                    <div className="d-flex align-center justify-arround" style={{ backgroundColor: 'transparent' }}>
                        {permissoes['edição'] &&
                            React.cloneElement(iconArray['editar'], { title: 'Editar usuário', className: "pointer", onClick: () => handleEditModal(cell.row.original.id), color: "#7340BD", size: 22 })}
                        {permissoes['dados_básicos'] &&
                            React.cloneElement(iconArray['observando'], { title: 'Ver dados básicos', className: "pointer", onClick: () => handleDadosBasicosModal(cell.row.original.id), color: "#7340BD", size: 22 })}
                        {permissoes['exclusão'] &&
                            (!cell.row.original.status || String(cell.row.original.status) === 'Ativo'
                                ? React.cloneElement(iconArray['usuario-inativo'], { title: 'Inativar usuário', className: "pointer", onClick: () => handleAtivarInativar(cell.row.original.id, 'Ativo'), color: "#7340BD", size: 22 })
                                : React.cloneElement(iconArray['usuario-ativo'], { title: 'Ativar usuário', className: "pointer", onClick: () => handleAtivarInativar(cell.row.original.id, 'Inativo'), color: "#7340BD", size: 22 })
                            )
                        }
                        {React.cloneElement(iconArray['email'], { title: 'Enviar e-mail de recuperação de senha.', className: "pointer", onClick: () => handleEnviaEmailRecuperaSenha(cell.row.original.id), color: "#7340BD", size: 22 })}
                    </div>
                )
            }
        ],
        [iconArray, permissoes]
    );

    return (
        <>
            <TableDefaultPagination columns={columns} source={source} searchBar={true} createButton={createButton} />
            <DadosBasicosModal ref={dadosBasicosRef}></DadosBasicosModal>
        </>
    );
}