import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useAuth } from '../../context/auth';
import { FiPower } from 'react-icons/fi';
import UsuarioLogado from '../../components/UsuarioLogado';
import NotificacaoService from '../../services/NotificacaoService';
import user_default from 'assets/img_avatar.png';
import { AiOutlineUser, AiOutlineBell, AiOutlineDelete, AiFillBell, AiFillHeart, AiFillFile } from 'react-icons/ai';
import ClipLoader from 'react-spinners/ClipLoader';
import Swal from 'sweetalert2';
import './styles.css';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'components/Dropdown';

const FuncoesUsuario = (props) => {
    const { signOut, user } = useAuth();
    const [abreNotificacoes, setAbreNotificacoes] = useState(false);
    const [notificacoes, setNotificacoes] = useState([]);
    const [notificacaoPendente, setNotificacaoPendente] = useState(0);
    const [loading, setLoading] = useState(false);
    const [mostrandoOpcoesUsuario, setMostrandoOpcoesUsuario] = useState(false);
    const navigate = useNavigate();
    const isMountedRef = useRef(null);

    const getWindowHeight = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return window.screen.height;
        }
        return window.innerHeight;
    };

    const windowHeight = useMemo(() => getWindowHeight(), []);

    const abrirNotificacao = (id, titulo, conteudo, tipo, visualizado) => {
        const option = {
            title: titulo,
            html: `<div style="max-height: ${windowHeight - 400}px; overflow-y: auto">${conteudo}</div>`,
            icon: tipo,
            confirmButtonText: 'Visualizar',
            denyButtonText: 'Excluir',
            closeButtonText: 'Fechar',
            showConfirmButton: !visualizado,
            showDenyButton: !visualizado,
            showCloseButton: true,
        };

        Swal.fire(option).then((result) => {
            if (result.isConfirmed) visualizarNotificacao(id);
            else if (result.isDenied) deletarNotificacao(id);
        });
    };

    const visualizarNotificacao = async (id) => {
        await NotificacaoService.visualiza(id).finally(() => getNotificacoes());
    };

    const deletarNotificacao = async (id) => {
        await NotificacaoService.delete(id).finally(() => getNotificacoes());
    };

    const atualizarTitulo = (notificacaoPendente_save) => {
        const splitedFilter = document.title.split(')');
        const filtredTitle = splitedFilter[1] ? splitedFilter[1] : splitedFilter;

        if (notificacaoPendente_save > 0) {
            document.title = `(${notificacaoPendente_save}) ${filtredTitle}`;
            enviarNotificacao('Neuro Sistema', {
                body: `Você tem ${notificacaoPendente_save} notificações pendentes. Clique para vê-las 😁`,
                icon: process.env.PUBLIC_URL + '/icone-teste-1.png'
            });
        } else {
            document.title = `${filtredTitle}`;
        }
    };

    const getNotificacoes = async () => {
        setLoading(true);
        if (user?.id) {
            await NotificacaoService.userIndex(user.id, 'sistema').then((response) => {
                if (isMountedRef.current) {
                    let lista_notificacoes = [];
                    let notificacaoPendente_save = 0;

                    response.data.forEach((res) => {
                        if (res && res.visualizado == null) {
                            notificacaoPendente_save++;
                        }
                        lista_notificacoes.push(
                            <li key={res.id} className='ns-dropdown-item d-inline-flex justify-content-between mt-2 border-bottom w-100per' style={{ backgroundColor: '#f8f8f8' }}>
                                <div className={`ps-2 ${res.visualizado == null ? 'font-vermelha' : ''}`} onClick={() => abrirNotificacao(res.id, res.titulo, res.conteudo, res.tipo, res.visualizado)}>
                                    <button className='btn-outline'>{res.titulo}</button>
                                </div>
                                <div className='px-2' style={{ borderLeft: '0.5px solid rgb(50,50,50)' }}>
                                    <button onClick={() => deletarNotificacao(res.id)} className='btn-outline'>
                                        <AiOutlineDelete />
                                    </button>
                                </div>
                            </li>
                        );
                    });

                    atualizarTitulo(notificacaoPendente_save);
                    setNotificacaoPendente(notificacaoPendente_save);
                    setNotificacoes(lista_notificacoes);
                }
            }).finally(() => {
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    };

    const enviarNotificacao = () => {
    };

    useEffect(() => {
        if (abreNotificacoes) {
            getNotificacoes();
        }
    }, [abreNotificacoes]);

    return (
        <div className='mr-20px flex-col' style={{ minWidth: '190px' }}>
            <div className='d-flex align-center justify-content-end flex-row'>
                <div className=''>
                    <Dropdown
                        clickMode={true}
                        buttonOver={true}
                        button={
                            <div className='p-0 m-0'>
                                <button className={`${abreNotificacoes ? 'manual-hover manual-border-remove-s-b manual-bg-white font-preta ' : 'font-branca '}ns-bell-button p-0 no-box-shadow me-2 pop-light-blue bg-transparente ns-hover-border-transition-manual`}>
                                    {loading
                                        ? <>
                                            <div className='notificacaoPendente'>
                                                <p> <ClipLoader className='back-azul' color={'white'} size={8} /> </p>
                                                <AiOutlineBell size={20} />
                                            </div>
                                        </>
                                        : notificacaoPendente > 0 ?
                                            <>
                                                <div className='notificacaoPendente'>
                                                    <p>{notificacaoPendente}</p>
                                                    <AiFillBell size={20} />
                                                </div>
                                            </>
                                            :
                                            <AiOutlineBell size={20} />
                                    }
                                </button>
                            </div>
                        }
                        content={
                            <div className='ns-dropdown-menu ns-dropdown-menu-right bg-branco' style={{ marginTop: '-17px', marginRight: '-4px', width: '350px', borderRadius: '15px', color: '#000', border: '4px solid #00728C' }}>
                                <p className='ns-dropdown-menu-title' style={{ borderTopRightRadius: '15px', borderTopLeftRadius: '15px', width: '100%' }}>
                                    Notificações
                                </p>
                                <ul className='ns-dropdown-list min-h-100px w-100per pb-2'>
                                    {(loading)
                                        ? <ClipLoader className='text-center m-auto' size={20} />
                                        : notificacoes
                                    }
                                </ul>
                            </div>
                        }
                        setMostrando={setAbreNotificacoes}
                    />
                </div>
                <div className=''>
                    <Dropdown
                        clickMode={true}
                        buttonOver={true}
                        button={
                            <div style={{ margin: 0, padding: 0 }}>
                                <img className={`perfil-image btn-sair no-box-shadow pop-light-blue ns-hover-border-transition-manual${mostrandoOpcoesUsuario ? ' manual-hover manual-border-remove-s-b' : ''}`} style={{ maxWidth: '50px', maxHeight: '50px' }} src={user?.foto_perfil_full ? user?.foto_perfil_full : user_default} alt='' />
                            </div>
                        }
                        content={
                            <div className='ns-dropdown-menu ns-dropdown-menu-right bg-branco' style={{ marginTop: '-23px', marginRight: '-7px', width: '200px', borderRadius: '15px', color: '#000', border: '4px solid #00728C' }}>
                                <p className='ns-dropdown-menu-title ns-abrevia-texto' style={{ borderTopRightRadius: '15px', borderTopLeftRadius: '15px' }}>
                                    {user?.name}
                                </p>
                                <ul className='ns-dropdown-list'>
                                    <li><button className='ns-dropdown-item' onClick={() => navigate('/dashboard/perfil')}><AiOutlineUser /> Perfil</button></li>
                                    <li><button className='ns-dropdown-item ns-dropdown-item-disabled'><AiFillFile /> Documentos</button></li>
                                    <li><button className='ns-dropdown-item ns-dropdown-item-disabled'><AiFillHeart /> Favoritos</button></li>
                                    <li><hr className='ns-dropdown-item-divider'></hr></li>
                                    <li> <button className='ns-dropdown-item' onClick={signOut}> <FiPower /> Sair </button> </li>
                                </ul>
                            </div>
                        }
                        setMostrando={setMostrandoOpcoesUsuario}
                    />
                </div>
            </div>
            <div className='d-flex align-center justify-center'>
                {props && props.menu}
            </div>
            <div className='p-2 align-center justify-center d-sm-none'>
                <UsuarioLogado />
            </div>
        </div>
    );
};

export default FuncoesUsuario;
