import React from 'react';
import { TableAgendamentos } from "./styles";

const TablePeriodosPaciente = ({ cell, diasDaSemana, handleRealcaMouseEnterLeaveCell }) => {
    return (
        <TableAgendamentos className="table table-bordered text-center"
            onMouseEnter={() => handleRealcaMouseEnterLeaveCell(['periodos', 'dia_semana'])}
            onMouseLeave={() => handleRealcaMouseEnterLeaveCell(['periodos', 'dia_semana'], false)}
        >
            <thead>
                <tr>
                    <th></th>
                    {diasDaSemana.map((dia, index) => (
                        <th key={`th:${index}|table:${cell.row.original.id}`}>{dia.label.slice(0, 1)}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th>M</th>
                    {diasDaSemana.map((dia, index) => {
                        const periodo = cell.value.find(p => p.dia_semana === dia.label)?.periodo || '';
                        const isMorning = periodo.includes('Manhã');
                        return <td key={`td-m:${index}|table:${cell.row.original.id}`} style={{ backgroundColor: isMorning ? 'green' : 'red', width: 15, height: 15 }}> </td>;
                    })}
                </tr>
                <tr>
                    <th>T</th>
                    {diasDaSemana.map((dia, index) => {
                        const periodo = cell.value.find(p => p.dia_semana === dia.label)?.periodo || '';
                        const isAfternoon = periodo.includes('Tarde');
                        return <td key={`td-t:${index}|table:${cell.row.original.id}`} style={{ backgroundColor: isAfternoon ? 'green' : 'red', width: 15, height: 15 }}> </td>;
                    })}
                </tr>
            </tbody>
        </TableAgendamentos>
    )
}
export default TablePeriodosPaciente;