import styled from 'styled-components';

export const Modal = styled.div`
  flex-direction: column;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  align-items: stretch;
  padding: 30px;
  color: #000;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 10px;
  margin-top: 15px;
  padding-top: 0px;
`;