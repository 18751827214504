//#region IMPORTAÇÕES
import React, { createContext, useContext, useEffect, useState } from 'react';
//#endregion

const PageBaseContext = createContext({});
/**
 * Provedor de contexto para a página base.
 *
 * @component
 * @param {Object} props - Propriedades do componente.
 * @param {React.ReactNode} props.children - Elementos filhos a serem renderizados dentro do provedor.
 *
 * @returns {JSX.Element} Provedor de contexto para a página base.
 *
 * @example
 * <PageBaseProvider>
 *   <YourComponent />
 * </PageBaseProvider>
 */
const PageBaseProvider = ({ children }) => {
    //#region VARIAVEIS
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [routes, setRoutes] = useState('');
    const [hasSubNavbar, setHasSubNavbar] = useState(false);
    const [hasTitle, setHasTitle] = useState(false);
    const [conectaComSocket, setConectaComSocket] = useState(false);
    const [socketLink, setSocketLink] = useState('');
    const [socketResponse, setSocketResponse] = useState(() => { });
    //#endregion

    //#region FUNCOES
    const toggleSubNavbar = () => {
        setHasSubNavbar(!hasSubNavbar);
    };

    const notifySubNavbar = () => {
        alert('Subnavbar is not implemented yet');
    };
    //#endregion

    //#region RETURN
    return (
        <PageBaseContext.Provider
            value={{
                title,
                setTitle,
                description,
                setDescription,
                hasSubNavbar,
                setHasSubNavbar,
                routes,
                setRoutes,
                toggleSubNavbar,
                notifySubNavbar,
                hasTitle,
                setHasTitle,
                conectaComSocket,
                setConectaComSocket,
                socketLink,
                setSocketLink,
                socketResponse,
                setSocketResponse
            }}
        >
            {children}
        </PageBaseContext.Provider>
    );
    //#endregion
};

//#region USE
/**
 * Hook personalizado para gerenciar o contexto da página base.
 *
 * @param {Object} options - Opções para configurar a página base.
 * @param {string} [options.title=''] - Título da página.
 * @param {string} [options.description=''] - Descrição da página.
 * @param {boolean} [options.hasTitle=true] - Indica se a página tem título.
 * @param {boolean} [options.hasSubMenu=false] - Indica se a página tem submenu.
 * @param {Array|string} [options.routes=[]] - Rotas da página.
 * @param {boolean} [options.conditionalPageBase] - Condição para aplicar as configurações da página base.
 * @param {boolean} [options.conectaComSocket=false] - Indica se a página deve conectar com o socket.
 * @param {string} [options.socketLink=''] - Link do socket.
 * @param {Function} [options.socketResponse=() => {}] - Função de resposta do socket.
 *
 * @returns {Object} - Retorna o contexto da página base.
 *
 * @throws {Error} - Lança um erro se o hook for usado fora de um PageBaseProvider.
 */
const usePageBase = ({
    title,
    description,
    hasTitle,
    hasSubMenu,
    routes,
    conditionalPageBase,
    conectaComSocket,
    socketLink,
    socketResponse = () => { }
} = {}) => {
    //#region VARIAVEIS
    const context = useContext(PageBaseContext);
    //#endregion

    //#region TESTE
    if (context === undefined) {
        throw new Error('usePageBase must be used within a PageBaseProvider');
    }
    //#endregion

    //#region DEFINIÇÕES
    useEffect(() => {
        if (conditionalPageBase === undefined || conditionalPageBase === true) {
            if (title !== undefined) context.setTitle(title);
            if (description !== undefined) context.setDescription(description);
            if (hasTitle !== undefined) context.setHasTitle(hasTitle);
            if (hasSubMenu !== undefined) context.setHasSubNavbar(hasSubMenu);
            if (routes !== undefined) context.setRoutes(routes);
            if (conectaComSocket !== undefined) context.setConectaComSocket(conectaComSocket);
            if (socketLink !== undefined) context.setSocketLink(socketLink);
            if (socketResponse) context.setSocketResponse(socketResponse);
        }
    }, [title, description, hasTitle, hasSubMenu, conditionalPageBase]);
    //#endregion

    //#region RETURN
    return context;
    //#endregion
};
//#endregion
//EXPORT
export { PageBaseProvider, usePageBase };
