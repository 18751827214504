//#region IMPORTAÇÕES
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from './styles';
import api from '../../../services/api';
import { TablePaciente } from './TablePaciente';
import FiltroPaciente from '../../../components/Filter/FiltroPacientes/FiltroPaciente';
import { useAuth } from '../../../context/auth';
import Carregamento from 'components/Carregamento';
import { BsGrid, BsList } from 'react-icons/bs';
import TableProntuarios from './TableProntuario';
import { usePageBase } from 'context/page-base';
import Button from 'components/Button';
import SelectPaciente from 'components/Select/SelectPaciente';
import useIcons from 'context/icons';
import { Form } from '@unform/web';
import Swal from 'sweetalert2';
//#endregion
/**
 * Componente de listagem de pacientes.
 * 
 * Este componente exibe uma lista de pacientes cadastrados no sistema, permitindo visualizar, editar e excluir pacientes.
 * 
 * @component
 * @returns {JSX.Element} Retorna o componente de listagem de pacientes.
 * 
 * @example
 * <ListagemPacientes />
 * 
 * @function
 * @name ListagemPacientes
 * 
 * @description
 * O componente utiliza o hook `usePageBase` para configurar a página, incluindo título e descrição.
 * Utiliza vários estados para gerenciar os dados dos pacientes, permissões do usuário e modo de visualização.
 * 
 * @hook
 * @name usePageBase
 * @description Hook utilizado para configurar a página.
 * 
 * @hook
 * @name useNavigate
 * @description Hook utilizado para navegação entre páginas.
 * 
 * @hook
 * @name useAuth
 * @description Hook utilizado para obter informações de autenticação do usuário.
 * 
 * @hook
 * @name useState
 * @description Hook utilizado para gerenciar estados locais do componente.
 * 
 * @hook
 * @name useEffect
 * @description Hook utilizado para realizar efeitos colaterais, como carregar dados de pacientes e permissões do usuário.
 * 
 * @function
 * @name coletaPermissoesPagina
 * @description Função que coleta as permissões do usuário com base na URL atual.
 * 
 * @async
 * @function
 * @name carregarPacientes
 * @description Função assíncrona que carrega a lista de pacientes do servidor.
 * 
 * @state {boolean} loadingDadosPacientes - Estado que indica se os dados dos pacientes estão sendo carregados.
 * @state {Array} paciente - Estado que armazena a lista de pacientes.
 * @state {Array} pacienteOriginal - Estado que armazena a lista original de pacientes.
 * @state {Array|null} pacienteIdentList - Estado que armazena a lista de identificadores de pacientes.
 * @state {Array} permissoes - Estado que armazena as permissões do usuário.
 * @state {string} viewMode - Estado que armazena o modo de visualização (lista ou grade).
 * 
 * @returns {JSX.Element} Retorna o componente de listagem de pacientes.
 */
export default function ListagemPacientes() {
    //#region VARIAVEIS
    // Hooks
    usePageBase({
        title: 'Listagem de Pacientes',
        description: 'Tela de listagem de pacientes, onde é possível visualizar, editar e excluir os pacientes cadastrados no sistema.',
        hasTitle: true,
        hasSubMenu: true
    });
    const navigate = useNavigate();
    const { user, coletaPermissoesPagina } = useAuth();
    const { iconArray } = useIcons();

    // Seleciondos
    const [pacienteSelecionado, setPacienteSelecionado] = useState(null);

    // Estado de Carregamento
    const [loadingDadosPacientes, setLoadingDadosPacientes] = useState(false);

    // Listagem de Pacientes
    const [pacientes, setPacientes] = useState([]);
    const [pacientesOriginal, setPacientesOriginal] = useState([]);
    const [pacienteIdentList, setPacienteIdentList] = useState(null);

    // Permissões
    const [permissoes, setPermissoes] = useState([]);

    // Modo de Visualização
    const [viewMode, setViewMode] = useState('list');

    //#endregion
    async function fetchPacientes(paciente_id = null) {
        api.get(`api/paciente/lista/simples?with=responsavel&select=id,nome,identificador,status,created_at,nascimento,responsavel.nome${paciente_id ? `&paciente_id=${paciente_id}` : ''}`).then(({ data }) => {
            data.sort((a, b) => {
                return new Date(b.created_at) - new Date(a.created_at);
            });
            setPacientesOriginal(data);
            setPacientes(data);
            setLoadingDadosPacientes(false);
        });
    }
    const handleConsultaPaciente = () => {
        setLoadingDadosPacientes(true);
        if (pacienteSelecionado?.value) {
            fetchPacientes(pacienteSelecionado?.value);
        } else {
            Swal.fire({
                title: 'Consultar todos os pacientes?',
                text: "Isso pode demorar um pouco. Deseja continuar?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim, consultar todos!'
            }).then((result) => {
                if (result.isConfirmed) {
                    fetchPacientes();
                } else {
                    setLoadingDadosPacientes(false);
                }
            });
        }
    };
    const handleLimparConsulta = () => {
        setPacienteSelecionado(null);
        setPacientes([]);
        setPacientesOriginal([]);
    };
    //#region USE EFFECTS
    useEffect(() => {
        const newPermissions = coletaPermissoesPagina();
        console.log('newPermissions', newPermissions);
        setPermissoes(newPermissions);
    }, []);
    useEffect(() => {
        let idList = [];
        let responsaveis = [];
        pacientesOriginal.forEach((data) => {
            if (data.status !== 'Inativo') {
                if (data.identificador !== null) {
                    idList.push(data.identificador);
                };
            };
            if (data.responsavel !== undefined && data.responsavel !== null && data.responsavel.length > 0) {
                if (responsaveis.find((e) => e.value === data.responsavel[0].id) === undefined) {
                    responsaveis.push({ value: data.responsavel[0].id, label: data.responsavel[0].nome });
                }
            }
        });
        setPacienteIdentList(idList);
    }, [pacientesOriginal, loadingDadosPacientes]);
    //#endregion
    //#region HTML
    return (
        <Container>
            {loadingDadosPacientes ? <Carregamento /> : ''}
            <Form className="row">
                <div className='col-7 d-flex flex-nowrap flex-row'>
                    <div className='m-0' style={{ width: '90%' }}>
                        <SelectPaciente
                            id="paciente_id"
                            name="paciente_id"
                            label="Paciente:"
                            value={pacienteSelecionado}
                            setPaciente={(paciente) => {
                                handleLimparConsulta();
                                setPacienteSelecionado(paciente);
                            }}
                        />
                    </div>
                    <div className='m-0 d-flex align-items-end' style={{ width: '10%' }}>
                        <Button onClick={() => handleLimparConsulta()}>{iconArray['limpar']}</Button>
                    </div>
                </div>
                <div className={`col-${permissoes['criação'] ? '2' : '4'} d-flex align-items-end`}>
                    <Button className="btn-padrao h-content-i" onClick={() => handleConsultaPaciente()}>
                        <span className='me-2'>{iconArray['pesquisar']}</span>
                        Consultar Paciente
                    </Button>
                </div>
                {permissoes['criação'] &&
                    <div className='col-3 d-flex align-items-end'>
                        <Button onClick={() => navigate(`/dashboard/${user.route_tipo_user}/pacientes/novo`, { state: { pacienteIdentList } })} className="btn-padrao">
                            <span className='me-2'>{iconArray['adicionar']}</span>
                            Cadastrar Paciente
                        </Button>
                    </div>
                }
            </Form>
            {pacientes.length !== 0 &&
                <div className="m-0 mt-3 p-0">
                    {viewMode === 'grid' && permissoes['prontuários'] &&
                        <div className='w-100per d-flex align-items-center justify-content-end'>
                            <div className="col-1 text-left">
                                <button onClick={() => setViewMode(prev => prev === 'list' ? 'grid' : 'list')}>
                                    {viewMode === 'grid'
                                        ? <BsList size={25} />
                                        : <BsGrid size={25} />
                                    }
                                </button>
                            </div>
                        </div>
                    }
                    <div style={{ marginBottom: 20 }}>
                        {viewMode === 'grid' && <TableProntuarios paciente={pacientes} />}
                        {viewMode === 'list' && <TablePaciente source={pacientes} pacienteIdentList={pacienteIdentList} permissoes={permissoes} user={user} createButton={
                            <div className="row w-100 align-items-center justify-content-center">
                                {!pacienteSelecionado &&
                                    <div className="col-11 text-left">
                                        <FiltroPaciente resultRef={setPacientes} list={pacientesOriginal} />
                                    </div>
                                }
                                {permissoes['prontuários'] &&
                                    <div className="col-1 text-left">
                                        <button onClick={() => setViewMode(prev => prev === 'list' ? 'grid' : 'list')}>
                                            {viewMode === 'grid'
                                                ? <BsList size={25} />
                                                : <BsGrid size={25} />
                                            }
                                        </button>
                                    </div>
                                }
                            </div>
                        } />}
                    </div>
                </div>
            }
        </Container>
    );
    //#endregion
}