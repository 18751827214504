import React, { useEffect, useMemo, useRef, useState } from 'react';
import { BsEye } from 'react-icons/bs';
import api from 'services/api';
import Swal from 'sweetalert2';
import { ModalNotificacao } from './ModalNotificacao';
import { TableDefaultPagination } from 'components/TableDefaultPagination';
import TituloPagina from 'components/TituloPagina';
import Button from 'components/Button';

/**
 * Componente de Notificações.
 * 
 * @component
 * @returns {JSX.Element} Componente de Notificações.
 * 
 * @example
 * <Notificacoes />
 * 
 * @description
 * Este componente exibe uma lista de notificações com várias colunas, incluindo usuário, título, nível de urgência, tipo, modo de envio, visualizado e uma opção para visualizar a notificação.
 * 
 * @function
 * @name Notificacoes
 * 
 * @property {Array} notificacoes - Estado que armazena a lista de notificações.
 * @property {Function} setNotificacoes - Função para atualizar o estado das notificações.
 * @property {Object} modalNotificacaoRef - Referência para o modal de notificação.
 * @property {Array} columns - Configuração das colunas da tabela de notificações.
 * 
 * @function getNotificacao
 * @description Função assíncrona para buscar as notificações da API.
 * 
 * @function handleVisualizar
 * @description Função para exibir uma notificação em um modal de visualização.
 * @param {Object} notificacao - Objeto de notificação a ser visualizado.
 * 
 * @useEffect
 * @description Hook de efeito que chama a função getNotificacao ao montar o componente.
 * 
 * @returns {JSX.Element} Retorna o componente de Notificações.
 */
export default function Notificacoes() {
    //#region VARIAVEIS
    const [modalNotificacaoOpen, setModalNotificacaoOpen] = useState(false);
    const [notificacoes, setNotificacoes] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState(null);
    const columns = useMemo(() => [
        {
            Header: 'Usuário',
            accessor: 'user.name',
            filterable: true,
        },
        {
            Header: 'Título',
            accessor: 'titulo',
            filterable: true,
        },
        {
            Header: 'Nível de Urgência',
            accessor: 'nivel_urgencia',
            filterable: true,
        },
        {
            Header: 'Tipo',
            accessor: 'tipo',
            filterable: true,
        },
        {
            Header: 'Modo de Envio',
            accessor: 'modo_envio',
            filterable: true,
        },
        {
            Header: 'Visualizado',
            accessor: 'visualizado',
            filterable: true,
            Cell: ({ value }) => (value ? 'Sim' : 'Não'),
        },
        {
            Header: 'Visualizar',
            accessor: 'id',
            filterable: true,
            Cell: ({ row }) => (
                <BsEye className='pointer' size={25} onClick={() => handleVisualizar(row.original)} />
            ),
        }
    ], []);
    //#endregion
    //#region FUNCOES
    const getNotificacao = async () => {
        try {
            api.get('api/notificacao?with=user')
                .then(({ data }) => {
                    setNotificacoes(data);
                })
                .catch(error => console.error(error));
        } catch (error) {
            console.error(error);
        }
    };
    //#endregion
    //#region HANDLES
    const handleVisualizar = (notificacao) => {
        Swal.fire({
            title: notificacao.titulo + ' - (Preview)',
            html: notificacao.conteudo,
            icon: notificacao.tipo,
            confirmButtonText: 'Visualizar',
            denyButtonText: 'Excluir',
            showConfirmButton: true,
            showDenyButton: true,
        });
    };
    //#endregion
    //#region USE EFFECT
    useEffect(() => {
        getNotificacao();
        setIsModalOpen(true);
    }, []);
    //#endregion
    //#region RENDER
    return (
        <>
            <TituloPagina>Notificações</TituloPagina>
            <div className='container'>
                <TableDefaultPagination columns={columns} source={notificacoes} />
                <Button onClick={() => setModalNotificacaoOpen(true)}>Enviar Notificações</Button>
                {modalNotificacaoOpen &&
                    <ModalNotificacao
                        modalData={modalData}
                        getNotificacao={getNotificacao}
                        setModalNotificacaoOpen={setModalNotificacaoOpen}
                    />
                }
            </div>
        </>
    );
    //#endregion
};