import { useState } from 'react';
import api from 'services/api';

const useTipoAtendimentosData = () => {
    const [tipoAtendimentosOptions, setTipoAtendimentosOptions] = useState([]);

    const mapTipoAtendimentosData = (data) => {
        return data
            .filter(tipoAtendimento => tipoAtendimento.id !== 4 && tipoAtendimento.id !== 6 && tipoAtendimento.id !== 7)
            .map(tipoAtendimento => ({
                value: tipoAtendimento.id,
                label: tipoAtendimento.tipo_atendimento,
                slug: tipoAtendimento.slug
            }));
    };

    const fetchTipoAtendimentosData = async () => {
        try {
            const { data } = await api.get('api/tipo-atendimento');
            const options = mapTipoAtendimentosData(data);
            setTipoAtendimentosOptions(options);
        } catch (error) {
            console.error('Erro ao carregar tipo atendimentos:', error);
        }
    };

    return { tipoAtendimentosOptions, fetchTipoAtendimentosData, mapTipoAtendimentosData };
};

export default useTipoAtendimentosData;