import { useState } from 'react';
import api from 'services/api';

const useTerapiasData = () => {
    const [terapiasOptions, setTerapiasOptions] = useState([]);

    const mapTerapias = (data) => {
        return data.map((terapia) => ({
            value: terapia.id,
            label: terapia.terapia
        }));
    };

    const fetchTerapias = async () => {
        try {
            const { data } = await api.get('api/terapia/');
            const options = mapTerapias(data);
            setTerapiasOptions(options);
        } catch (error) {
            console.error('Erro ao carregar terapias:', error);
        }
    };

    return { terapiasOptions, fetchTerapias, mapTerapias };
};

export default useTerapiasData;