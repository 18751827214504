import React, { forwardRef, useCallback, useRef, useState } from 'react';
import { Form } from '@unform/web';
import Input from '../../Input';
import TipoAtendimentoService from '../../../services/TipoAtendimentoService';
import { useSnackbar } from '../../../context/snackbar';
import Swal from 'sweetalert2';
import Button from '../../Button';
import { ClipLoader } from 'react-spinners';

const TipoAtendimentoModal = ({ modalData, callback, closeModal }, ref) => {
    const [loading, setLoading] = useState();

    const formRef = useRef(null);
    const snackbar = useSnackbar();

    async function handleSubmit(data) {
        try {
            setLoading(true);
            if (modalData === null) {
                await TipoAtendimentoService.store(data);
                snackbar.displayMessage('Cadastro criado com sucesso!', 'success');
                closeModal();
                callback();
            } else {
                await TipoAtendimentoService.update(data, modalData.id);
                snackbar.displayMessage('Cadastro alterado com sucesso!', 'success');
                closeModal();
                callback();
            }
        } catch (error) {
            Swal.fire('Houve um problema ao cadastrar!', '', 'error');
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Form ref={formRef} onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', borderWidth: 1, borderTopColor: 'red' }} >
                    <div style={{ paddingTop: 30 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: 450 }}>
                            <Input name={'tipo_atendimento'} title={'Tipo de atendimento'} required={'required'}
                                defaultValue={modalData === null ? '' : modalData.tipo_atendimento} />
                        </div>
                    </div>
                    <Button className='btn-padrao' type="submit" disabled={loading}>
                        {loading
                            ? <ClipLoader size={22} />
                            : modalData === null
                                ? 'Adicionar'
                                : 'Alterar'
                        }
                    </Button>
                </Form>
            </div>
        </div>
    );
};

export default forwardRef(TipoAtendimentoModal);
