//#region IMPORTAÇÕES
import { Form } from '@unform/web';
import Button from 'components/Button';
import Calendario from 'components/Calendario';
import Select from 'components/Select';
import SelectPaciente from 'components/Select/SelectPaciente';
import { useAuth } from 'context/auth';
import useIcons from 'context/icons';
import usePacienteData from 'context/paciente';
import { usePageBase } from 'context/page-base';
import useTerapeutasData from 'context/terapeuta';
import moment from 'moment';
import ControleDiario from 'pages/RecepcaoDashboard/Agenda/ControleDiario';
import { cloneElement, createRef, useEffect, useMemo, useState } from 'react';
import { AiOutlineClose, AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
//#endregion

const ControleDiarioDB = () => {
    usePageBase({
        title: 'Edição Geral de Atendimentos',
        description: 'Tela onde é possível visualizar e editar todos os atendimentos de um selecionado dia.',
        hasTitle: true,
        hasSubmenu: true,
        routes: [
            'dashboard/{route_tipo_user}/controlediario/atendimentos-pendentes',
        ]
    });
    //#region VARIAVEIS
    // Hooks
    const { iconArray } = useIcons();
    const { user } = useAuth();
    const [isPermissaoMaster, setIsPermissaoMaster] = useState(false);
    const [isCalendarioVisible, setIsCalendarioVisible] = useState(false);

    // Parâmetros
    const { data } = useParams();
    const [dataMoment, setDataMoment] = useState(moment(data));
    const dataNome = useMemo(() => dataMoment.format('dddd - DD/MM/YYYY'), [dataMoment]);
    const dataFormatada = useMemo(() => dataMoment.format('YYYY-MM-DD'), [dataMoment]);

    // Estado de Carregamento
    const [loading, setloading] = useState(false);

    // Listas
    const { terapeutasOptions, fetchTerapeutasData } = useTerapeutasData();
    const { pacienteOptions } = usePacienteData(['nome', 'identificador', 'aviso', 'terapia_paciente']);

    // Selecionados
    const [pacienteSelecionado, setPacienteSelecionado] = useState(null);
    const [terapeutaSelecionado, setTerapeutaSelecionado] = useState(null);
    const [modoDeConsulta, setModoDeConsulta] = useState(null);

    // Referecias
    const calendarioRef = createRef();

    //#endregion
    //#region HANDLES
    /**
     * Função para pesquisar e definir o modo de consulta baseado nos IDs do paciente e terapeuta selecionados.
     * 
     * Esta função cria um array de condições de consulta (`novoModoDeConsulta`) com base nos valores selecionados
     * de paciente e terapeuta. Se um paciente ou terapeuta estiver selecionado, suas respectivas condições são
     * adicionadas ao array. Em seguida, o array é definido como o novo modo de consulta.
     * 
     * @function handlePesquisar
     */
    const handlePesquisar = () => {
        setModoDeConsulta(null);
        let novoModoDeConsulta = [];
        const paciente_id = pacienteSelecionado?.value;
        const terapeuta_id = terapeutaSelecionado?.value;
        if (paciente_id) novoModoDeConsulta.push(`atendimento.paciente_id=${paciente_id}`);
        if (terapeuta_id) novoModoDeConsulta.push(`atendimento.terapeuta_id=${terapeuta_id}`);
        if (!paciente_id && !terapeuta_id) novoModoDeConsulta = `normal`;
        setModoDeConsulta(novoModoDeConsulta);
    };
    const handleClickNextDay = () => {
        let dataSaveLocal = dataMoment.add(1, 'days');
        selecionaData(dataSaveLocal);
    };
    const handleClickPrevDay = () => {
        let dataSaveLocal = dataMoment.subtract(1, 'days');
        selecionaData(dataSaveLocal);
    };
    const handleToggleCalendario = () => {
        setIsCalendarioVisible(!isCalendarioVisible);
    };
    const handleClickDay = (day, month, year) => {
        let dia = moment.utc(`${year}-${month + 1}-${day}`, 'YYYY-MM-DD');
        let dataSaveLocal = moment.utc(dia);
        selecionaData(dataSaveLocal);
        handleToggleCalendario();
    };
    const handleLimpaFiltros = () => {
        setPacienteSelecionado(null);
        setTerapeutaSelecionado(null);
    };

    const selecionaData = async (dataSaveLocal) => {
        setDataMoment(dataSaveLocal);
        const chave = 'data';
        const valor = dataSaveLocal.format('YYYY-MM-DD');
        // Cria um objeto URLSearchParams a partir da string de consulta atual
        const parametros = new URLSearchParams(window.location.search);

        // Adiciona ou atualiza o parâmetro
        parametros.set(chave, valor);

        // Modifica a URL sem recarregar a página
        window.history.pushState({}, '', `${window.location.pathname}?${parametros}`);
    };
    //#endregion
    //#region USE EFFECTS
    useEffect(() => {// Verifica Listas
        if (terapeutasOptions && pacienteOptions) {
            setloading(false);
        }
    }, [terapeutasOptions, pacienteOptions]);
    useEffect(() => {
        setModoDeConsulta(null);
    }, [pacienteSelecionado, terapeutaSelecionado, dataMoment]);
    useEffect(() => {
        if (user?.tipo_user?.toLowerCase() === 'administrador' || user?.tipo_user?.toLowerCase() === 'desenvolvedor' || user?.tipo_user?.toLowerCase() === 'guias' || user?.tipo_user?.toLowerCase() === 'financeiro') {
            setIsPermissaoMaster(true);
        }
    }, [user]);
    useEffect(() => {// ON LOAD
        setloading(true);
        fetchTerapeutasData();
        //fetchPacienteData(['nome', 'identificador', 'aviso', 'terapia_paciente']);
    }, []);
    //#endregion
    //#region HTML
    return (
        <div className='m-0 p-0' style={{ padding: '0 0 0 50' }}>
            <div className='col-9 mx-auto'>
                <Form className='row'>
                    <div className='col-12'>
                        <div className='w-100per d-flex flex-col'>
                            <div className='col-12 d-flex text-right justify-content-center align-center mt-2'>
                                <button className='btn-icone'>
                                    <AiOutlineLeft className='pointer' color='black' size={22} onClick={() => handleClickPrevDay()} />
                                </button>
                                <span className='texto-navegacao me-2 ml-2' onClick={handleToggleCalendario}>{`${dataNome.charAt(0).toUpperCase() + dataNome.slice(1)}`}</span>
                                <button className='btn-icone'>
                                    {moment.utc().format('YYYY-MM-DD') !== dataFormatada ?
                                        <AiOutlineRight className='pointer' color='black' size={22} onClick={() => handleClickNextDay()} />
                                        : <AiOutlineRight color='white' size={22} />
                                    }
                                </button>
                            </div>
                        </div>
                        <div ref={calendarioRef} className={isCalendarioVisible ? 'div-expand-over calendar-div p-0 w-fit-content-i bg-white' : 'some'}>
                            <div className='w-100 d-flex justify-content-end'>
                                <button className='btn-icone' onClick={handleToggleCalendario}>
                                    <AiOutlineClose color='red' size={22} />
                                </button>
                            </div>

                            <Calendario dateLimit={true} style={{ borderTop: 'none' }} daysMark={[]} handleClickDay={handleClickDay} maxDate={moment.utc().format('YYYY-MM-DD')} selectedDate={dataFormatada}></Calendario>
                        </div>
                    </div>
                    <div className='col-4'>
                        <SelectPaciente
                            id={'paciente_selecionado'}
                            name={'paciente_selecionado'}
                            label='Paciente'
                            placeholder='Selecione o paciente, ou deixe em branco para todos.'
                            setPaciente={setPacienteSelecionado}
                            isDisabled={loading}
                            value={pacienteSelecionado}
                        />
                    </div>
                    <div className='col-5'>
                        <Select
                            id={'terapeuta_selecionado'}
                            name={'terapeuta_selecionado'}
                            label='Terapeuta'
                            placeholder='Selecione o terapeuta, ou deixe em branco para todos.'
                            onChange={setTerapeutaSelecionado}
                            options={terapeutasOptions}
                            isDisabled={loading}
                            value={terapeutaSelecionado}
                        />
                    </div>
                    <div className='col-1 d-flex align-items-end'>
                        <Button type={'button'} className="h-38px" onClick={() => handleLimpaFiltros()}>
                            {cloneElement(iconArray['limpar-geral'], { size: 24 })}
                        </Button>
                    </div>
                    <div className='col-2 d-flex align-items-end'>
                        <Button type={'button'} className="h-38px" onClick={() => handlePesquisar()}>
                            {loading
                                ? <ClipLoader />
                                : <> {cloneElement(iconArray['pesquisar'], { size: 24 })} Pesquisar </>
                            }
                        </Button>
                    </div>
                </Form>
            </div>
            {(modoDeConsulta) &&
                <ControleDiario
                    dataSelecionada={dataFormatada}
                    modoIncorporado={true}
                    modoDeConsulta={modoDeConsulta}
                    funcoesHabilitadas={{
                        filtro: true,
                        navegacao: false,
                        salvar: false,
                        carregar: false,
                        novo_atendimento: isPermissaoMaster,
                        solicitacoes_alteracao: false,
                        filtro_solicitacoes_alteracao: false,
                        atualizacao: true,
                        baixar_excel: false,
                        legenda: true,
                        botao_alteracao: true,
                        controle_de_execucao: isPermissaoMaster,
                        altera_sugestao_recepcao: isPermissaoMaster,
                        altera_liberado: isPermissaoMaster,
                        coluna_p: true,
                        paciente_filtro_inline: true,
                        modal_alteracao_opcoes: {
                            aprova: isPermissaoMaster,
                            reprova: isPermissaoMaster,
                            cria: isPermissaoMaster,
                        }
                    }}
                    filtrosHabilitados={{
                        paciente: pacienteSelecionado ? false : true,
                        terapeuta: terapeutaSelecionado ? false : true,
                        status_atendimento: true,
                        plano_saude: true,
                    }}
                />
            }
        </div>
    );
    //#endregion
};

export default ControleDiarioDB;