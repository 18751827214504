import React, { useEffect, useState, useRef } from 'react';
import { AiFillFileAdd } from 'react-icons/ai';
import { Container } from './styles';

import { css } from '@emotion/react';
import ClipLoader from 'react-spinners/ClipLoader';

import TableTipoDemandas from './TableTipoDemandas';
import Admin from '../../../../layouts/Admin';
import api from 'services/api';
import ModalBase from '../../../../components/ModalBase';
import TipoDemandaModal from './TipoDemandaModal';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default function TipoDemandas() {

    const [loading, setLoading] = useState(true);
    const [color] = useState('#8ed4ae');
    const [modalIsOpen, setIsOpen] = useState(false);
    const [tipoDemandas, setTipoDemandas] = useState([]);
    const [selectedTipoDemanda, setSelectedTipoDemanda] = useState(null);
    const modalRef = useRef(null);

    const loadTipoDemandas = () => {
        api.get('api/tipo-demanda')
            .then(({ data }) => {
                data.sort((a, b) => b.prioridade - a.prioridade); // Ordena por prioridade
                setTipoDemandas(data);
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        loadTipoDemandas();
    }, []);

    const handleNewTipoDemanda = () => {
        setSelectedTipoDemanda(null);
        modalRef.current.open();
    };

    const showEditModal = (tipoDemandaData) => {
        setSelectedTipoDemanda(tipoDemandaData);
        modalRef.current.open();
    };

    return (
        <Container>
            <div>
                <button
                    onClick={() => handleNewTipoDemanda()} className="btn-padrao">
                    <AiFillFileAdd style={{ marginRight: 5 }} />
                    Novo Tipo de Demanda
                </button>
            </div>
            <div>
                {
                    (tipoDemandas.length !== 0 && loading === false)
                        ? <TableTipoDemandas callback={loadTipoDemandas} showEditModal={showEditModal} source={tipoDemandas} />
                        : <ClipLoader color={color} loading={loading} css={override} size={50} />
                }
            </div>
            <ModalBase
                ref={modalRef}
                title="Tipo de Demanda"
                onClose={() => setIsOpen(false)}
            >
                <TipoDemandaModal
                    modalData={selectedTipoDemanda}
                    callback={loadTipoDemandas}
                    closeModal={() => modalRef.current.close()}
                />
            </ModalBase>
        </Container>
    );
}