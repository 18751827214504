import { Form } from '@unform/web';
import axios from 'axios';
import Button from 'components/Button';
import Input from 'components/Input';
import Select from 'components/Select';
import useEspecialidadesData from 'context/especialidade';
import usePacienteData from 'context/paciente';
import { usePageBase } from 'context/page-base';
import usePlanoSaudeData from 'context/plano-saude';
import useStatusAtendimentosData from 'context/status-atendimento';
import useTerapeutasData from 'context/terapeuta';
import useTerapiasData from 'context/terapia';
import { useEffect, useState, useRef } from 'react';
import { BsSearch, BsPrinter, BsSortDown, BsSortUp } from 'react-icons/bs';
import { FaBroom, FaFilePdf } from 'react-icons/fa';
import { CgExport } from 'react-icons/cg';
import { RiFileExcel2Fill } from 'react-icons/ri';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';
import htmlDocx from 'html-docx-js/dist/html-docx';
import { toPng } from 'html-to-image';
import html2PDF from 'jspdf-html2canvas';
import * as XLSX from 'xlsx';
import api from 'services/api';

const RelatoriosTerapeutas = () => {
    usePageBase({
        title: 'Relatório de Terapeutas',
        description: 'Tela onde é possível visualizar os terapeutas cadastrados no sistema.',
        routes: [
            'dashboard/[modulo]/relatorios/terapeutas'
        ]
    })
    const [terapeutas, setTerapeutas] = useState([]);
    const [loading, setLoading] = useState(true);
    const { especialidadesOptions, fetchEspecialidades } = useEspecialidadesData();
    const statusTerapeuta = [
        { value: 'Ativo', label: 'Ativo' },
        { value: 'Inativo', label: 'Inativo' },
        { value: 'Bloqueado', label: 'Bloqueado' },
    ]
    const { terapeutasOptions, fetchTerapeutasData } = useTerapeutasData();
    const { terapiasOptions, fetchTerapias } = useTerapiasData();
    const { planoSaudeOptions, fetchPlanoSaude } = usePlanoSaudeData();
    const { pacienteOptions, fetchPacienteOptionsData } = usePacienteData();
    const [filters, setFilters] = useState({
        nome: '',
        email: '',
        cpf: '',
        status: '',
        especialidade_id: '',
        terapia_id: '',
        plano_saude_id: '',
        paciente_id: ''
    });

    const toExportRef = useRef(null);

    useEffect(() => {
        const fetchTerapeutas = async () => {
            try {
                const response = await axios.get('/api/terapeutas');
                setTerapeutas(response.data);
            } catch (error) {
                console.error('Erro ao buscar terapeutas:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchTerapeutas();
        fetchEspecialidades();
        fetchTerapeutasData();
        fetchTerapias();
        fetchPlanoSaude();
        fetchPacienteOptionsData();
    }, []);

    const handleChange = (e) => {
        const name = e.target.id || e.target.name;
        const value = e.target.value;

        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value
        }));
    };

    const handleSubmit = async () => {
        setLoading(true);
        const noFiltersApplied = Object.values(filters).every(value => value === '' || value === null);

        if (noFiltersApplied) {
            const confirmProceed = await Swal.fire({
                title: 'Nenhum filtro foi aplicado',
                text: 'Deseja continuar?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não'
            });
            if (!confirmProceed.isConfirmed) {
                setLoading(false);
                return;
            }
        }

        try {
            const response = await api.post('/api/terapeuta/filtrado', filters);
            setTerapeutas(response.data);
        } catch (error) {
            console.error('Erro ao buscar terapeutas:', error);
        } finally {
            setLoading(false);
        }
    };

    const limpaFiltros = () => {
        setFilters({
            nome: '',
            email: '',
            cpf: '',
            status: '',
            especialidade_id: '',
            terapia_id: '',
            plano_saude_id: '',
            paciente_id: ''
        });
    };

    const exportToPrint = () => {
        const printContents = toExportRef.current.outerHTML;
        const originalContents = document.body.innerHTML;

        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    };

    const exportToImage = () => {
        toPng(toExportRef.current).then((dataUrl) => {
            const link = document.createElement('a');
            link.download = 'relatorio.png';
            link.href = dataUrl;
            link.click();
        });
    };

    const exportToExcel = () => {
        const wb = XLSX.utils.book_new();
        const wsData = [
            ["Nome", "Email", "CPF", "Status"]
        ];

        terapeutas.forEach(terapeuta => {
            wsData.push([
                terapeuta.nome,
                terapeuta.email,
                terapeuta.cpf,
                terapeuta.status
            ]);
        });

        const ws = XLSX.utils.aoa_to_sheet(wsData);

        XLSX.utils.book_append_sheet(wb, ws, 'Relatório');
        XLSX.writeFile(wb, 'relatorio.xlsx');
    };

    const exportToPdf = () => {
        const filename = `Relatório de Terapeutas`;
        const newOptions = {
            jsPDF: { unit: 'px', format: 'a4', orientation: 'portrait' },
            html2canvas: { scale: 2, useCORS: true, logging: true },
            image: { type: 'jpeg', quality: 0.98 },
            output: `${filename}.pdf`
        };
        html2PDF(toExportRef.current, newOptions);
    };

    const exportToWord = () => {
        const htmlString = toExportRef.current.innerHTML;
        const converted = htmlDocx.asBlob(htmlString);
        saveAs(converted, 'output.docx');
    };

    if (loading) {
        return <div>Carregando...</div>;
    }

    return (
        <div>
            <Form onSubmit={handleSubmit} className='row d-flex flex-wrap gy-4 p-4'>
                <fieldset className="ns-fieldset col-sm-12 col-lg-4">
                    <legend>Informações do Terapeuta</legend>
                    <div className='row'>
                        <div className='col-lg-6 col-sm-12' id='filter_nome'>
                            <Input type="text" title="Nome:" label="Nome:" id="nome" name="nome" value={filters.nome} onChange={handleChange} />
                        </div>
                        <div className='col-lg-6 col-sm-12' id='filter_email'>
                            <Input type="text" title="Email:" label="Email:" id="email" name="email" value={filters.email} onChange={handleChange} />
                        </div>
                        <div className='col-lg-6 col-sm-12' id='filter_cpf'>
                            <Input type="text" title="CPF:" label="CPF:" id="cpf" name="cpf" value={filters.cpf} onChange={handleChange} />
                        </div>
                        <div className='col-lg-6 col-sm-12' id='filter_status'>
                            <Select id="status" name="status" label="Status:" title="Status:" options={statusTerapeuta} isClearable={true} value={filters.status} onChange={(e) => handleChange({ target: { id: 'status', name: 'status', value: e.value } })} />
                        </div>
                    </div>
                </fieldset>
                <fieldset className="ns-fieldset col-sm-12 col-lg-4">
                    <legend>Informações de Formação</legend>
                    <div className='row'>
                        <div className='col-12' id='filter_terapia_id'>
                            <Select id="terapia_id" name="terapia_id" label="Terapia:" title="Terapia:" options={terapiasOptions} isClearable={true} value={filters.terapia_id} onChange={(e) => handleChange({ target: { id: 'terapia_id', name: 'terapia_id', value: e.value } })} />
                        </div>
                        <div className='col-12' id='filter_especialidade_id'>
                            <Select id="especialidade_id" name="especialidade_id" label="Especialidade:" title="Especialidade:" options={especialidadesOptions} isClearable={true} value={filters.especialidade_id} onChange={(e) => handleChange({ target: { id: 'especialidade_id', name: 'especialidade_id', value: e.value } })} />
                        </div>
                    </div>
                </fieldset>
                <fieldset className="ns-fieldset col-sm-12 col-lg-4">
                    <legend>Informações do Paciente</legend>
                    <div className='row'>
                        <div className='col-12' id='filter_paciente_id'>
                            <Select id="paciente_id" name="paciente_id" label="Paciente:" title="Paciente:" options={pacienteOptions} isClearable={true} value={filters.paciente_id} onChange={(e) => handleChange({ target: { id: 'paciente_id', name: 'paciente_id', value: e.value } })} />
                        </div>
                    </div>
                </fieldset>
                <div className='col-12 mt-2 d-flex justify-content-end'>
                    <Button type="submit" className={'w-fit-content-i searching'}>
                        <BsSearch className='icone' size={30} /> Pesquisar
                    </Button>
                    <Button type="button" onClick={limpaFiltros} className={`ms-2 broom-sweep w-fit-content-i ${Object.values(filters).some(value => value !== null && value !== '') ? 'font-amarelo-claro' : ''}`} >
                        <FaBroom size={30} type="button" className="icone" /> Limpar Filtros
                    </Button>
                </div>
            </Form>
            <div className='row p-0 m-0 justify-content-end align-items-center'>
                <Button className='d-flex flex-column align-items-center w-100px-i mx-4' onClick={exportToPrint}>
                    <BsPrinter size={30} />Imprimir
                </Button>
                <Button className='d-flex flex-column align-items-center w-100px-i mx-4' onClick={exportToImage}>
                    <CgExport size={30} />Imagem
                </Button>
                <Button className='d-flex flex-column align-items-center w-100px-i mx-4' onClick={exportToExcel}>
                    <RiFileExcel2Fill size={30} />Excel
                </Button>
                <Button className='d-flex flex-column align-items-center w-100px-i mx-4' onClick={exportToPdf}>
                    <FaFilePdf size={30} />PDF
                </Button>
                <Button className='d-flex flex-column align-items-center w-100px-i mx-4' onClick={exportToWord}>
                    <FaFilePdf size={30} />Word
                </Button>
            </div>
            <div ref={toExportRef}>
                <table className='table table-striped table-hover'>
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Email</th>
                            <th>CPF</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {terapeutas.map((terapeuta) => (
                            <tr key={terapeuta.id}>
                                <td>{terapeuta.nome}</td>
                                <td>{terapeuta.email}</td>
                                <td>{terapeuta.cpf}</td>
                                <td>{terapeuta.status}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default RelatoriosTerapeutas;
