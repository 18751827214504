import moment from 'moment';
import { useState } from 'react';
import api from 'services/api';

const useHorarioAtendimentoData = (diaSemana) => {
    const [horarioAtendimentoOptions, setHorarioAtendimentoOptions] = useState([]);
    const [horarioAtendimentoComFimOptions, setHorarioAtendimentoComFimOptions] = useState([]);

    const mapHorarioAtendimentoData = (data) => {
        return data.map((horario, index) => {
            let horaAtual = moment.utc(horario.horario).format('HH:mm');
            let hora40mais = moment.utc(horario.horario).add(40, 'minutes').format('HH:mm');
            return {
                value: horaAtual,
                hora_final: hora40mais,
                label: horaAtual + ' - ' + hora40mais,
                id: horario.id,
                key: index,
            };
        });
    };

    const mapHorarioAtendimentoComFimData = (data) => {
        return data.map((horario, index) => ({
            value: horario.id,
            label: moment.utc(horario.horario).format('HH:mm'),
            hora_final: moment.utc(horario.horario).add(40, 'minutes').format('HH:mm'),
            id: horario.id,
            key: index,
        }));
    };

    const fetchHorarioAtendimentoData = async (weekDay = moment.utc().weekday()) => {
        try {
            const { data } = await api.get(`api/horario-atendimento${weekDay ? '?filter[dia_semana]=' + weekDay : ''}`);
            const options = mapHorarioAtendimentoData(data);
            setHorarioAtendimentoOptions(options);
        } catch (error) {
            console.error('Erro ao carregar modalidades:', error);
        }
    };

    const fetchHorarioAtendimentoComFimData = async (weekDay = moment.utc().weekday()) => {
        try {
            const { data } = await api.get(`api/horario-atendimento${weekDay ? '?filter[dia_semana]=' + weekDay : ''}`);
            const options = mapHorarioAtendimentoComFimData(data);
            setHorarioAtendimentoComFimOptions(options);
        } catch (error) {
            console.error('Erro ao carregar modalidades:', error);
        }
    };

    return { horarioAtendimentoOptions, horarioAtendimentoComFimOptions, fetchHorarioAtendimentoData, fetchHorarioAtendimentoComFimData, mapHorarioAtendimentoData, mapHorarioAtendimentoComFimData };
};

export default useHorarioAtendimentoData;