import styled from 'styled-components';
import { shade } from 'polished';

//#region VARIAVEIS
const alturaTela = window.innerHeight;
const larguraTela = window.innerWidth;
const altura = '100px';
const alturaMenor = '60px';
const largura = '20%';
const borderSize = '.2px';
const fontPadrao = '12px';
const fontMenorPadrao = '10px';
//#region

export const Tr0 = styled.tr`
  background-color: transparent;
  width: 10%;
  height: ${alturaMenor};
`;

export const Th1 = styled.th`
  background-color: #7441BF;
  color: #fff;
  border-radius: 6px;
  border: 0.1px solid #F5F7FA;
  min-width: ${largura};
  max-width: ${largura};
  width: ${largura};
  height: ${alturaMenor};
  min-height: ${alturaMenor};
  max-height: ${alturaMenor};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${fontPadrao};
  
  &.selecionado {
    font-size: 14px;
    background-color: rgb(0, 177, 157);
  }
`;

export const ThHora = styled.th`
  background-color: #DFCDF8;
  color: #9586AC;
  padding: 5px;
  border-radius: 6px;
  border: 0.1px solid #F5F7FA;
  font-size: 11px;
  min-width: 10%;
  max-width: 10%;
  width: 10%;
  height: ${altura};
  min-height: ${altura};
  max-height: ${altura};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TdVazio = styled.td`
  background-color: #fff;
  padding: 5px;
  border-radius: 6px;
  border: 0.1px solid #F5F7FA;
  min-width: ${largura};
  max-width: ${largura};
  width: ${largura};
  height: ${altura};
  min-height: ${altura};
  max-height: ${altura};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${fontPadrao};
`;

export const TdOcupado = styled.td`
  background-color: #41D263;
  font-size: ${fontPadrao};
  color: #000000;
  padding: 0;
  border-radius: 6px;
  border: 0.1px solid #F5F7FA;
  cursor: zoom-in;

  min-width: ${largura};
  max-width: ${largura};
  width: ${largura};
  height: ${altura};
  min-height: ${altura};
  max-height: ${altura};

  display: flex;
  flex-direction: column;

  .linha {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0;
    padding: 0;
    border: 0;
  }

  .horario {
    border-right: ${borderSize} solid #000;
    border-bottom: ${borderSize} solid #000;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
  }

  .sala {
    border-bottom: ${borderSize} solid #000;
    display: flex;
    flex-direction: column;
    width: 70%;
  }
  .sala .linha {
    padding-left: 4px;
  }
  .sala .linha:first-child {
    border-bottom: ${borderSize} solid #000;
  }

  .terapeuta {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
  }
`;