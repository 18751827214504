import React, { useImperativeHandle, useState, forwardRef, useMemo } from 'react';
import { Container, Modal } from './styles';
import ModalTitulo from 'components/ModalTitulo';
import { CgClose } from 'react-icons/cg';

/**
 * ModalBase component renders a modal with a customizable title, left title, and close button.
 *
 * @param {Object} props - The properties object.
 * @param {string} [props.id='base'] - The id for the modal content.
 * @param {string} props.title - The main title of the modal.
 * @param {string} props.leftTitle - The title displayed on the left side of the modal.
 * @param {string} [props.sidesTitleSize='200px'] - The width of the left and right title sections.
 * @param {boolean} [props.hasCloseButton=true] - Determines if the close button should be displayed.
 * @param {Function} [props.onClose=() => {}] - The function to call when the close button is clicked.
 * @param {Function} [props.onOpen=() => {}] - The function to call when the modal is opened.
 * @param {React.ReactNode} props.children - The content to be displayed inside the modal.
 * @param {React.Ref} props.ref - The reference to the modal element.
 *
 * @returns {JSX.Element} The rendered ModalBase component.
 */
/**
 * ModalBase component is a customizable modal dialog that can be controlled externally via ref.
 * 
 * @component
 * @param {Object} props - The properties object.
 * @param {string} [props.id='base'] - The id of the modal.
 * @param {string} props.title - The title of the modal.
 * @param {string} props.leftTitle - The title on the left side of the navbar.
 * @param {string} [props.sidesTitleSize='200px'] - The width of the sides of the title in the navbar.
 * @param {string} [props.width='70%'] - The width of the modal.
 * @param {string} [props.height='auto'] - The height of the modal.
 * @param {string} [props.maxHeight='75vh'] - The maximum height of the modal.
 * @param {string} [props.smWidth='100vw'] - The width of the modal on small screens.
 * @param {string} [props.smHeight='100vh'] - The height of the modal on small screens.
 * @param {string} [props.backgroundColor='rgba(0, 0, 0)'] - The background color of the modal.
 * @param {number} [props.backgroundOpacity=0.8] - The opacity of the background.
 * @param {boolean} [props.hasCloseButton=true] - Whether the modal has a close button.
 * @param {function} [props.onClose] - Callback function to be called when the modal is closed.
 * @param {function} [props.onOpen] - Callback function to be called when the modal is opened.
 * @param {React.ReactNode} props.children - The content of the modal.
 * @param {React.Ref} ref - The ref to control the modal externally.
 * 
 * @example
 * const modalRef = useRef();
 * 
 * const openModal = () => {
 *   modalRef.current.open();
 * };
 * 
 * const closeModal = () => {
 *   modalRef.current.close();
 * };
 * 
 * return (
 *   <>
 *     <button onClick={openModal}>Open Modal</button>
 *     <ModalBase
 *       ref={modalRef}
 *       title="My Modal"
 *       leftTitle="Left Title"
 *       onClose={() => console.log('Modal closed')}
 *       onOpen={() => console.log('Modal opened')}
 *     >
 *       <p>This is the content of the modal</p>
 *     </ModalBase>
 *   </>
 * );
 */
const ModalBase = forwardRef(({
    id = 'base',
    //Configurações da navbar
    title,
    leftTitle,
    sidesTitleSize = '200px',
    //Tamanho da modal
    width = '70%',
    height = 'auto',
    maxHeight = '75vh',
    smWidth = '100vw',
    smHeight = '100vh',
    //Estilos do background
    backgroundColor = 'rgba(0, 0, 0)',
    backgroundOpacity = 0.8,
    //Configurações do modal
    hasCloseButton = true,
    //Funções de callback
    onClose = () => { },
    onOpen = () => { },
    //Conteúdo do modal
    children,
}, ref) => {
    //#region VARIAVEIS
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isOpen, setIsOpen] = useState(false);
    const isMobile = useMemo(() => windowWidth <= 768, [windowWidth]);
    const sizes = useMemo(() => {
        return {
            width: isMobile ? smWidth : width,
            height: isMobile ? smHeight : height,
            maxHeight: maxHeight
        }
    }, [isMobile, smWidth, width, smHeight, height, maxHeight]);
    //#endregion
    //#region CALLBACKS
    /**
     * Opens the modal by setting the state to true and calling the onOpen callback.
     */
    const open = () => {
        setIsOpen(true);
        onOpen();
    }
    /**
     * Closes the modal by setting the `isOpen` state to false and calling the `onClose` callback.
     */
    const close = () => {
        setIsOpen(false);
        onClose();
    };
    //#endregion
    //#region FUNÇÕES EXTERNAS
    useImperativeHandle(ref, () => ({
        open,
        close
    }));
    //#endregion
    //#region HTML
    return (
        <>
            {isOpen &&
                <Modal id={`${id}:modal`} style={{ backgroundColor: `${backgroundColor}(${backgroundOpacity})` }}>
                    <Container id={id} style={sizes}>
                        <nav className='navbar sticky-top bg-cinza-claro shadow' style={{ margin: '0px -30px 0px -30px' }}>
                            <div className="container-fluid d-flex align-items-center justify-content-between">
                                <div style={{ width: sidesTitleSize }}>
                                    {leftTitle}
                                </div>
                                <ModalTitulo>{title}</ModalTitulo>
                                {hasCloseButton === true
                                    ? <label style={{ width: sidesTitleSize }} className='btn-outline text-right' onClick={close}>
                                        <CgClose size={22} color='red' />
                                    </label>
                                    : <div style={{ width: sidesTitleSize }}> </div>
                                }
                            </div>
                        </nav>
                        <br></br>
                        <div id={`${id}:modal__content`} className="">{children}</div>
                    </Container>
                </Modal>
            }
        </>
    );
    //#endregion
});

export default ModalBase;