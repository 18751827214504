import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { BsCheck } from 'react-icons/bs';
import { CgClose } from 'react-icons/cg';
import Modal from 'react-modal';
import api from '../../../services/api';
import ModalTitulo from 'components/ModalTitulo';
const ComentariosModal = forwardRef(({ id = 'agendaModal', getAgendaDia = () => { }, atualizaSocket = () => { } }, ref) => {
    //#region VARIÁVEIS
    const title = 'Comentários';
    const [isOpen, setIsOpen] = useState(false);
    const [atendimento_id, setAtendimentoId] = useState();
    const [comentario, setComentario] = useState();
    const [dados, setDados] = useState();

    const customStyles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 10,
        },
        content: {
            position: 'fixed',
            zIndex: '1001',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            height: '50vh',
            width: '60vw',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#FFFFFF',
            padding: '0',
        },
    };
    //#endregion
    //#region FUNÇÕES DA MODAL
    const openModal = useCallback((dados) => {
        setIsOpen(true);
        dados['liberado'] = dados['liberado'] === 1
            ? <BsCheck className='success' size={30} />
                : dados['liberado'] === 0
                    ? <CgClose className='font-vermelho' size={22} />
                    : 'vazio';
        setDados(dados);
        setAtendimentoId(dados['id']);
        setComentario(dados['comentario']);
    }, []);
    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, []);
    useImperativeHandle(ref, () => ({ openModal, closeModal }));
    //#endregion
    const handleSalvaComentario = () => {
        api.patch('api/atendimento/' + atendimento_id, { comentario: comentario })
            .then((e) => {
                setComentario('');
                getAgendaDia();
                atualizaSocket();
                closeModal();
            }).catch((e) => {
                console.error(e);
            });
    };
    return (
        <Modal style={customStyles} id={id} isOpen={isOpen} onRequestClose={closeModal} appElement={document.getElementById('root')} transparent>
            <nav className='navbar sticky-top bg-cinza-claro shadow m-0'>
                <div className='container-fluid d-flex align-items-center justify-content-between'>
                    <div className='col-4 d-flex flex-row'>
                    </div>
                    <ModalTitulo className='col-4 text-center'>{title}</ModalTitulo>
                    <label className={`btn-outline col-4 text-right pointer`} onClick={() => closeModal()}>
                        <CgClose size={22} color='red' />
                    </label>
                </div>
            </nav>
            {dados &&
                <div className='row p-3'>
                    <div className='col-4'>
                        <b>Paciente: </b> <p>{dados['paciente'] && `${dados['paciente']} - ${dados['paciente_nome']}`}</p>
                    </div>
                    <div className='col-4'>
                        <b>Dia da Semana: </b> <p>{dados['data_semana'] && dados['data_semana']}</p>
                    </div>
                    <div className='col-4'>
                        <b>Período de Atendimento: </b> <p>{(dados['data_inicio'] && dados['data_final']) && `${dados['data_inicio']} - ${dados['data_final']}`}</p>
                    </div>

                    <div className='col-6'>
                        <b>Terapia do Paciente: </b> <p>{(dados['terapia'] && dados['especialidade'] && dados['plano_saude']) && `${dados['terapia']} - ${dados['especialidade']} - ${dados['plano_saude']}`}</p>
                    </div>
                    <div className='col-6'>
                        <b>Terapeuta: </b> <p>{dados['terapeuta'] && dados['terapeuta']}</p>
                    </div>
                    <div className='col-4'>
                        <b>Modalidade: </b> <p>{dados['modalidade'] && dados['modalidade']}</p>
                    </div>
                    <div className='col-4'>
                        <b>Tipo de Atendimento: </b> <p>{dados['tipo_atendimento'] && dados['tipo_atendimento']}</p>
                    </div>
                    <div className='col-4'>
                        <b>Sala / Estação: </b> <p>{(dados['sala'] && dados['estacao']) && `${dados['sala']} / ${dados['estacao']}`}</p>
                    </div>
                    <div className='col-4'>
                        <b>Status Terapeuta: </b> <p>{(dados['terapeuta_status_nome']) && `${dados['terapeuta_status_nome']}`}</p>
                    </div>
                    <div className='col-4'>
                        <b>Status Recepcao: </b> <p>{(dados['recepcao_status_nome']) && `${dados['recepcao_status_nome']}`}</p>
                    </div>
                    <div className='col-4'>
                        <b>Status de Liberação: </b> <p>{(dados['liberado']) && dados['liberado']}</p>
                    </div>
                    <div className='col-4'>
                        <b>Status de Execução: </b>
                        {dados['executado'] &&
                            <p className={
                                (dados['executado'] === 'pendente')
                                    ? 'p-pendende td-content cd-col-10'
                                    : (dados['executado'] === 'executado')
                                        ? 'p-executado td-content cd-col-10'
                                        : (dados['executado'] === 'pago')
                                            ? 'p-executado td-content cd-col-10'
                                            : 'td-content cd-col-10'
                            }>
                                {dados['executado']}
                            </p>}
                    </div>
                    <div className='col-4'>
                        <b>Código 1: </b> <p>{(dados['codigo_1']) && `${dados['codigo_1']}`}</p>
                    </div>
                    <div className='col-4'>
                        <b>Código 2: </b> <p>{(dados['codigo_2']) && dados['codigo_2']}</p>
                    </div>

                    <div className='col-12'>
                        <label htmlFor='comentario'><b>Comentários:</b></label>
                        <textarea id='comentario' className='form-control' rows={10} defaultValue={comentario} onChange={(e) => { setComentario(e.target.value); }} placeholder='Nenhum comentário...'></textarea>
                        <button className='btn-padrao' onClick={(e) => handleSalvaComentario()}>Salvar</button>
                    </div>
                </div>
            }
        </Modal>
    );
});

ComentariosModal.displayName = 'ComentariosModal';
export default ComentariosModal;