import { useState } from 'react';
import api from 'services/api';

const usePacienteData = () => {
    const [pacientes, setPacientes] = useState([]);
    const [pacienteOptions, setPacienteOptions] = useState([]);

    const mapPacienteData = (data, campos, agendamentos = null, hour = null, agendamentoId = null) => {
        return data.map((paciente, index) => {
            const isIndisponivel = agendamentos && hour && agendamentoId && agendamentos.some((agendamento) =>
                agendamento.paciente_id === paciente.id && agendamento.data_atendimento_inicial === hour._i && agendamento.id !== agendamentoId
            );

            const addon = isIndisponivel ? '(Indisponível)' : paciente.status === 'Ativo' ? '' : `(${paciente.status})`;

            const option = {
                value: paciente.id,
                label: `(${paciente.identificador}) ${paciente.nome} ${addon}`,
                key: index,
            };

            campos.forEach(campo => {
                if (paciente[campo] !== undefined) {
                    option[campo] = paciente[campo];
                }
            });

            return option;
        });
    };

    const fetchPacienteData = async (campos = ['nome', 'identificador', 'aviso', 'terapia_paciente'], filterInativos = true) => {
        try {
            const com = `${campos.filter(filter => filter === 'terapia_paciente').length === 0 ? '?' : '?with=terapia_paciente&'}`;
            const selecionar = `${campos.filter(filter => filter === 'terapia_paciente').length === 0 ? '' : 'terapia_paciente.terapia,terapia_paciente.especialidade,terapia_paciente.plano_saude,' + campos['aviso_agendamento_check'] === undefined ? '' : 'aviso_agendamento_check,'}`;
            const url = 'api/paciente/lista/simples?with=terapia_paciente&select=terapia_paciente.terapia,terapia_paciente.especialidade,terapia_paciente.plano_saude,id,nome,identificador,status,aviso_agendamento_check';
            let { data } = await api.get(url);
            if (filterInativos) {
                data = data.filter((filter) => filter.status.toLowerCase() !== 'inativo');
            }
            const options = mapPacienteData(data, campos);
            setPacienteOptions(options);
            setPacientes(data);
        } catch (error) {
            console.error('Erro ao carregar pacientes:', error);
        }
    };

    const fetchPacienteOptionsData = async (campos = ['nome', 'identificador', 'aviso', 'terapia_paciente'], filterInativos = true, agendamentos = null, hour = null, agendamentoId = null) => {
        try {
            const com = campos.includes('terapia_paciente') ? '?with=terapia_paciente&' : '?';
            const selecionar = `${campos.includes('terapia_paciente') ? 'terapia_paciente.terapia,terapia_paciente.especialidade,terapia_paciente.plano_saude,' : ''}id,nome,identificador,status,aviso_agendamento_check`;

            const url = `api/paciente/lista/simples${com}select=${selecionar}`;
            let { data } = await api.get(url);

            if (filterInativos) {
                data = data.filter((filter) => filter.status.toLowerCase() !== 'inativo');
            }

            data.sort((a, b) => a.nome.localeCompare(b.nome));

            const options = mapPacienteData(data, campos, agendamentos, hour, agendamentoId);
            setPacienteOptions(options);
            setPacientes(data);
        } catch (error) {
            console.error('Erro ao carregar pacientes:', error);
        }
    };

    const customPacienteFilter = (option, inputValue) => {
        if (!inputValue) return true;

        const lowerInputValue = inputValue.toLowerCase();
        const identificador = option.data.identificador.toLowerCase();
        const nome = option.data.nome.toLowerCase();
        const identificadorResult = identificador.includes(lowerInputValue);

        if (pacienteOptions.some(opt => opt.identificador.toLowerCase().includes(lowerInputValue))) {
            return identificadorResult;
        }
        return nome.includes(lowerInputValue);
    };

    return { pacientes, pacienteOptions, fetchPacienteData, fetchPacienteOptionsData, customPacienteFilter, mapPacienteData };
};

export default usePacienteData;
