//#region IMPORTAÇÕES
import { Route } from 'react-router-dom';
import Atendimentos from './../pages/AdminDashboard/Atendimentos';
import ConfiguracoesAdmin from './../pages/AdminDashboard/Configuracoes';
import AreaCompartilhada from './../pages/AdminDashboard/Configuracoes/AreaCompartilhada';
import Diagnosticos from './../pages/AdminDashboard/Configuracoes/Diagnosticos';
import Documentos from './../pages/AdminDashboard/Configuracoes/Documentos';
import Especialidade from './../pages/AdminDashboard/Configuracoes/Especialidade';
import Estacao from './../pages/AdminDashboard/Configuracoes/Estacao';
import HorariosAtendimento from './../pages/AdminDashboard/Configuracoes/HorariosAtendimento';
import Modalidade from './../pages/AdminDashboard/Configuracoes/Modalidade';
import GrupoPermissao from './../pages/AdminDashboard/Configuracoes/Permissoes';
import Sala from './../pages/AdminDashboard/Configuracoes/Sala';
import Status from './../pages/AdminDashboard/Configuracoes/Status';
import Terapias from './../pages/AdminDashboard/Configuracoes/Terapias';
import TiposAtendimento from './../pages/AdminDashboard/Configuracoes/TiposAtendimento';
import TiposAvaliacao from './../pages/AdminDashboard/Configuracoes/TiposAvaliacao';
import ListagemFuncionarios from './../pages/AdminDashboard/Funcionarios';
import ListagemPacientes from './../pages/AdminDashboard/Pacientes';
import PerfilPacienteAdmin from './../pages/AdminDashboard/Pacientes/Perfil';
import PlanoDeSaudeAdmin from './../pages/AdminDashboard/PlanoDeSaude';
import RelatóriosEstrategicos from './../pages/AdminDashboard/RelatoriosEstrategicos';
import ListagemTerapeutas from './../pages/AdminDashboard/Terapeutas';
import AgendaTerapeutaAdmin from './../pages/AdminDashboard/Terapeutas/AgendaTerapeutaAdmin';
import ListagemDeGlosas from './../pages/AdminDashboard/Terapeutas/Glosas';
import CadastroPerfilTerapeuta from './../pages/CoordenacaoDashboard/Terapeutas/Novo';
import DashboardPadrao from './../pages/Desenvolvedor';
import Postman from './../pages/Desenvolvedor/Postman';
import DocumentoAssinatura from './../pages/DocumentoAssinatura';
import DocumentosPendentes from './../pages/DocumentosPendentes';
import Notificacoes from './../pages/Notificacoes';
import PadraoDocumento from './../pages/Parametros/PadraoDocumento';
import Recados from './../pages/Recados';
import AgendaAreaCompartilhada from './../pages/RecepcaoDashboard/Agenda/AreaCompartilhada';
import ControleDiario from './../pages/RecepcaoDashboard/Agenda/ControleDiario';
import ListaEspera from './../pages/RecepcaoDashboard/Agenda/ListaDeEspera';
import AgendaSemanal from './../pages/RecepcaoDashboard/Agenda/Terapeuta';
import AgendaTerapeutas from './../pages/RecepcaoDashboard/Agenda/Terapeutas';
import Guias from './../pages/RecepcaoDashboard/Guias';
import Prontuarios from './../pages/RecepcaoDashboard/Pacientes/Prontuarios';
import { GraficosPacientes } from './../pages/Relatorios/Graficos/Pacientes';
import Pesquisar from './../pages/TerapeutaDashboard/TerapeutaPacientes/Pesquisar';
import Testes from './../pages/Testes';
import Testes2 from './../pages/Testes2';
import Uploads from './../pages/Uploads';
import ActiveConnections from './../pages/WebSocketManager';

import Documentacao from 'components/Documentacao';
import DragAndDrop from 'components/DragAndDrop';
import RelatorioFinanceiro from 'components/RelatorioFinanceiro';
import TipoDemandas from 'pages/AdminDashboard/Configuracoes/TipoDemanda';
import RelatoriosHome from 'pages/AdminDashboard/RelatoriosEstrategicos/home';
import AtendimentosEGlosas from 'pages/AtendimentosEGlosas';
import DashboardControleDiario from 'pages/ControleDiario';
import AlteracoesPendentes from 'pages/ControleDiario/AlteracoesPendentes';
import AtendimentosAguardandoTerapeuta from 'pages/ControleDiario/AtendimentosAguardandoTerapeuta';
import AtendimentosPendentes from 'pages/ControleDiario/AtendimentosPendentes';
import CheckIn from 'pages/ControleDiario/CheckIn';
import CheckInPendentes from 'pages/ControleDiario/CheckInPendentes';
import ConsultarAlteracoes from 'pages/ControleDiario/ConsultarAlteracoes';
import ConsultasAlteracoes from 'pages/ControleDiario/ConsultasEAlteracoes';
import ExecucoesPendentes from 'pages/ControleDiario/ExecucoesPendentes';
import OfflineIndexDev from 'pages/Desenvolvedor/offline-index-dev';
import EnviarEmail from 'pages/Enviar/Email';
import GeradorDeDocumento from 'pages/GeradorDeDocumento';
import Logs from 'pages/Logs';
import LogModels from 'pages/Logs/LogModels';
import RelatoriosAtendimentos from 'pages/Relatorios/Atendimentos';
import Tarefas from 'pages/Tarefas';
import TarefasTabela from 'pages/Tarefas/tabelas/visualizacoes/tabela';
import TesteVirtualizador from './../pages/Testes';
import { getAuditorPaths } from './auditor-route';
import { getResponsavelPaths } from './responsavel-route';
import { getTerapeutaPaths } from './terapeuta-route';
import ConsultarExecucoes from 'pages/ControleDiario/ConsultarExecucoes';
import Declaracoes from 'pages/Declaracoes';
import ControleDiarioDB from 'pages/ControleDiario/DB';
import ErrosEConflitos from 'pages/ControleDiario/ErrosEConflitos';
import FinanceiroHome from 'pages/FinanceiroHome/home';
import RelatoriosAgendamentos from 'pages/Relatorios/Agendamentos';
import RelatoriosTerapeutas from 'pages/Relatorios/Terapeutas';
//#endregion

/**
 * Gera as rotas de funcionários com base no tipo de usuário.
 *
 * @param {string} tipo_user - O tipo de usuário (terapeuta, responsavel, auditor, etc.).
 * @returns {JSX.Element[]} - Um array de elementos de rota JSX.
 *
 * As rotas são filtradas com base no tipo de usuário fornecido. Se nenhum tipo de usuário for fornecido ou se o tipo de usuário não corresponder a nenhum caso específico, todas as rotas serão retornadas.
 *
 * @example
 * <FuncionariosRoutes tipo_user="terapeuta" />
 */
const FuncionariosRoutes = (tipo_user) => {
    let filterLinks = [];
    const linkPrefix = `/dashboard/${tipo_user}`;
    const links = [
        { path: '', component: <DashboardPadrao /> },
        { path: 'documentos-pendentes/usuario/:id', component: <DocumentoAssinatura /> },
        { path: 'documentos-pendentes/usuario', component: <DocumentosPendentes /> },
        { path: 'agenda', component: <AgendaSemanal /> },
        { path: 'agenda/terapeutas', component: <AgendaTerapeutas /> },
        { path: 'agenda/listadeespera', component: <ListaEspera /> },
        { path: 'agenda/areacompartilhada', component: <AgendaAreaCompartilhada /> },
        { path: 'controle_diario/', component: <DashboardControleDiario /> },
        { path: 'controle_diario/check-in', component: <CheckIn /> },
        { path: 'controle_diario/check_in', component: <CheckIn /> },
        { path: 'controle_diario/atendimentos-pendentes', component: <AtendimentosPendentes /> },
        { path: 'controle_diario/atendimentos-aguardando-terapeuta', component: <AtendimentosAguardandoTerapeuta /> },
        { path: 'controle_diario/check-in-pendentes', component: <CheckInPendentes /> },
        { path: 'controle_diario/consultar-alterar-atendimentos', component: <ConsultasAlteracoes /> },
        { path: 'controle_diario/adicionar-atendimento', component: <ControleDiario /> },
        { path: 'controle_diario/execucoes-pendentes', component: <ExecucoesPendentes /> },
        { path: 'controle_diario/consultar-execucoes', component: <ConsultarExecucoes /> },
        { path: 'controle_diario/alteracoes-pendentes', component: <AlteracoesPendentes /> },
        { path: 'controle_diario/consultar-alteracoes', component: <ConsultarAlteracoes /> },
        { path: 'controle_diario/erros-e-conflitos', component: <ErrosEConflitos /> },
        { path: 'controle_diario/db', component: <ControleDiario /> },
        { path: 'controle_diario/geral', component: <ControleDiarioDB /> },
        { path: 'terapeutas', component: <ListagemTerapeutas /> },
        { path: 'terapeutas/glosas', component: <ListagemDeGlosas /> },
        { path: 'terapeutas/novo', component: <CadastroPerfilTerapeuta /> },
        { path: 'terapeuta/perfil/:id', component: <CadastroPerfilTerapeuta /> },
        { path: 'terapeuta/agenda/:id', component: <AgendaTerapeutaAdmin /> },
        { path: 'guias', component: <Guias /> },
        { path: 'pacientes', component: <ListagemPacientes /> },
        { path: 'pacientes/novo', component: <PerfilPacienteAdmin /> },
        { path: 'pacientes/novo/:lista_id', component: <PerfilPacienteAdmin /> },
        { path: 'pacientes/perfil/:id', component: <PerfilPacienteAdmin /> },
        { path: 'pacientes/prontuarios', component: <Prontuarios /> },
        { path: 'pacientes/prontuarios/:id', component: <Prontuarios /> },
        { path: 'pacientes/pesquisar', component: <Pesquisar /> },
        { path: 'funcionarios', component: <ListagemFuncionarios /> },
        { path: 'planodesaude', component: <PlanoDeSaudeAdmin /> },
        { path: 'relatorios', component: <RelatoriosHome /> },
        { path: 'relatorios-estategicos', component: <RelatóriosEstrategicos /> },
        { path: 'relatorios/graficos', component: <GraficosPacientes /> },
        { path: 'relatorios/agendamentos', component: <RelatoriosAgendamentos /> },
        { path: 'relatorios/atendimentos', component: <RelatoriosAtendimentos /> },
        { path: 'relatorios/pacientes', component: <RelatoriosAtendimentos /> },
        { path: 'relatorios/terapeutas', component: <RelatoriosTerapeutas /> },
        { path: 'financeiro', component: <FinanceiroHome /> },
        { path: 'financeiro/relatorio', component: <RelatorioFinanceiro /> },
        { path: 'declaracoes', component: <Declaracoes /> },
        { path: 'atendimentos', component: <Atendimentos /> },
        { path: 'auditoria', component: <AtendimentosEGlosas /> },
        { path: 'auditoria/glosas', component: <AtendimentosEGlosas /> },
        { path: 'configuracoes', component: <ConfiguracoesAdmin /> },
        { path: 'configuracoes/documentos', component: <Documentos /> },
        { path: 'configuracoes/grupopermissoes', component: <GrupoPermissao /> },
        { path: 'configuracoes/tipoatendimento', component: <TiposAtendimento /> },
        { path: 'configuracoes/tipodemanda', component: <TipoDemandas /> },
        { path: 'configuracoes/terapias', component: <Terapias /> },
        { path: 'configuracoes/especialidades', component: <Especialidade /> },
        { path: 'configuracoes/modalidades', component: <Modalidade /> },
        { path: 'configuracoes/salas', component: <Sala /> },
        { path: 'configuracoes/estacoes', component: <Estacao /> },
        { path: 'configuracoes/areacompartilhada', component: <AreaCompartilhada /> },
        { path: 'configuracoes/tiposavaliacao', component: <TiposAvaliacao /> },
        { path: 'configuracoes/status', component: <Status /> },
        { path: 'configuracoes/diagnosticos', component: <Diagnosticos /> },
        { path: 'configuracoes/horarios-atendimento', component: <HorariosAtendimento /> },
        { path: 'gerador-de-documento/:id', component: <GeradorDeDocumento /> },
        { path: 'padrao', component: <DashboardPadrao /> },
        { path: 'd', component: <OfflineIndexDev /> },
        { path: 'dnd-testes', component: <DragAndDrop /> },
        { path: 'testes', component: <Testes /> },
        { path: 'testes2', component: <Testes2 /> },
        { path: 'testes3', component: <TesteVirtualizador /> },
        { path: 'homem-postagem', component: <Postman /> },
        { path: 'websocket', component: <ActiveConnections /> },
        { path: 'uploads', component: <Uploads /> },
        { path: 'padrao-documento', component: <PadraoDocumento /> },
        { path: 'notificacoes', component: <Notificacoes /> },
        { path: 'notificacoes/recados', component: <Recados /> },
        { path: 'documentacao', component: <Documentacao /> },
        { path: 'logs', component: <Logs /> },
        { path: 'logs/logs-models', component: <LogModels /> },
        { path: 'logs/logs-models/:id', component: <LogModels /> },
        { path: 'tarefas', component: <Tarefas /> },
        { path: 'tarefas/:tabelaId', component: <TarefasTabela /> },
        { path: 'enviar/e-mail', component: <EnviarEmail /> },
    ];

    switch (tipo_user) {
        case 'terapeuta':
            filterLinks = getTerapeutaPaths();
            break;
        case 'responsavel':
            filterLinks = getResponsavelPaths();
            break;
        case 'auditor':
            filterLinks = getAuditorPaths();
            break;
        default:
            break;
    }

    const filteredLinks = filterLinks.length > 0
        ? links.filter(link => filterLinks.includes(link.path))
        : links;

    return filteredLinks.map((link, index) => (
        <Route key={`funcionario-route-${index}`} path={`${linkPrefix}/${link.path}`} element={link.component} />
    ));
};

export default FuncionariosRoutes;
