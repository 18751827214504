import React, { useState, useEffect, forwardRef, useCallback, useImperativeHandle, useRef, useMemo } from 'react';
import Modal from 'react-modal';
import Select from '../../../../../components/Select';
import { Form } from '@unform/web';
import api from '../../../../../services/api';
import Input from '../../../../../components/Input';
import Swal from 'sweetalert2';
import { useSnackbar } from '../../../../../context/snackbar';
import moment from 'moment';
import { ClipLoader } from 'react-spinners';
import { Fieldset } from 'primereact/fieldset';
import { useAuth } from '../../../../../context/auth';
import SelectPaciente from 'components/Select/SelectPaciente';
import { depois } from 'helpers/data.helper';
import { antes } from 'helpers/data.helper';
import { CgClose, CgCopy } from 'react-icons/cg';
import ModalTitulo from 'components/ModalTitulo';
import TextArea from 'components/TextArea';

/**
 * Componente ModalGuias.
 *
 * @component
 * @param {Object} props - As propriedades do componente.
 * @param {Function} props.callBack - Função a ser chamada após o fechamento do modal.
 * @param {Array} props.listaGuias - Lista de guias para validação.
 * @param {React.Ref} ref - Referência para o componente.
 * @returns {React.Element} O componente ModalGuias.
 */
const ModalGuias = forwardRef(({
    callBack,
    listaGuias,
    pacienteSelecionadoOpt,
    pacienteOptions
}, ref) => {
    //#region VARIAVEIS
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            height: 'auto',
            maxHeight: '70vh',
            width: '85vw',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#FFFFFF',
            padding: 0
        },
    };
    const subtitle = useRef(null);
    /* FORM DADOS */
    const [tresHorasDiff, setTresHorasDiff] = useState();
    const [validade, setValidade] = useState('');
    const [inputValidade, setInputValidade] = useState('');
    const [inicio, setInicio] = useState('');
    const [inputInicio, setInputInicio] = useState('');
    const [solicitacao, setSolicitacao] = useState('');
    const [inputSolicitacao, setInputSolicitacao] = useState('');
    const [aceitacao, setAceitacao] = useState('');
    const [inputAceitacao, setInputAceitacao] = useState('');
    const [vigenciaValida, setVigenciaValida] = useState(true);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [valor, setValor] = useState(0);
    const [idGuia, setIdGuia] = useState(null);
    const [visualizacao, setVisualizacao] = useState(false);
    const [comentario, setComentario] = useState('');
    /* PRÉ LISTAS */
    const [historicosDaGuia, setListaHistorico] = useState(null);
    const [atendimentosGuia, setListaAtendimentos] = useState(null);
    /* LISTAS */
    const [pacienteSelectBox, setPacienteSelectBox] = useState([]);
    const [terapiaPacienteSelectBox, setTerapiaPacienteSelectBox] = useState([]);
    /* SELECIONADOS */
    const [pacienteEscolhido, setPacienteEscolhido] = useState(null);
    const [terapiaEscolhido, setTerapiaEscolhida] = useState(null);
    const [terapiaPacienteEscolhido, setTerapiaPacienteEscolhido] = useState([]);
    const [planoDeSaudeEscolhido, setPlanoDeSaudeEscolhido] = useState(null);
    const [planoDeSaudeEscolhidoNome, setPlanoDeSaudeEscolhidoNome] = useState(null);
    const [especialidadeEscolhido, setEspecialidadeEscolhido] = useState(null);
    const [loadingDadosIniciais, setLoadingDadosIniciais] = useState(false);
    const [identificadorGeral, setIdentificadorGeral] = useState('');
    const [numeroGuia, setNumeroGuia] = useState('');
    /* UTILITARIOS */
    const [salvando, setSalvando] = useState(false);
    const snackbar = useSnackbar();
    /* LISTA DE GUIAS MEMORIZADA */
    const memoListaGuias = useMemo(() => listaGuias, [listaGuias]);
    /* REFS */
    const formRef = useRef(null);
    //#endregion
    //#region MÉTODOS
    //#region MODAL
    /**
     * Abre o modal e inicializa os estados necessários.
     * 
     * @param {Object} elem - Dados do elemento a ser editado.
     * @param {boolean} modoEdicao - Indica se o modal está em modo de edição.
     */
    const openModal = useCallback((elem, modoEdicao) => {
        zeraDados();
        setIsOpen(true);
        setModalData(elem);
        if (pacienteSelecionadoOpt) {
            setPacienteEscolhido(pacienteSelecionadoOpt);
            const terapiaPacientesList = pacienteSelecionadoOpt?.terapia_paciente?.filter(filter => !filter.deleted_at)?.map(el => ({
                value: el.id,
                label: `${el.terapia} - ${el.especialidade} - ${el.nome_reduzido}`,
                terapia_id: el.terapia_id,
                especialidade_id: el.especialidade_id,
                plano_saude_id: el.plano_saude_id,
                plano_saude: el.nome_reduzido,
                limite_diario_terapias: el.limite_diario_terapias,
                deleted_at: el.deleted_at,
            })) || [];
            setTerapiaPacienteSelectBox(terapiaPacientesList);
        }
        if (elem) {
            const created_at = moment.utc(elem.created_at);
            const now = moment.utc();
            const tresHorasDiff = now.diff(created_at, 'hours');
            setTresHorasDiff(tresHorasDiff > 3);
            setInicio(elem?.validade);
            setVisualizacao(!modoEdicao);
        } else {
            setVisualizacao(false);
        }
    }, [pacienteSelecionadoOpt]);

    useImperativeHandle(
        ref,
        () => {
            return {
                openModal,
                modalData,
            };
        }
    );

    /**
     * Função executada após a abertura do modal.
     * 
     * Sincroniza referências e estilos após a abertura do modal.
     */
    function afterOpenModal() {
        if (subtitle.current) {
            subtitle.current.style.color = '#6A3BAF';
        }
    }

    /**
     * Zera os dados selecionados no modal.
     * 
     * @param {boolean} paciente - Se deve zerar o paciente selecionado.
     * @param {boolean} plano - Se deve zerar o plano de saúde selecionado.
     * @param {boolean} plano_nome - Se deve zerar o nome do plano de saúde.
     * @param {boolean} especialidade - Se deve zerar a especialidade selecionada.
     * @param {boolean} terapia - Se deve zerar a terapia selecionada.
     * @param {boolean} valor - Se deve zerar o valor.
     * @param {boolean} inicio - Se deve zerar a data de início.
     * @param {boolean} validade - Se deve zerar a data de validade.
     * @param {boolean} numeros - Se deve zerar os números de guia.
     */
    const zeraDados = (pacienteZ = true, planoZ = true, plano_nomeZ = true, especialidadeZ = true, terapiaZ = true, valorZ = true, inicioZ = true, validadeZ = true, numerosZ = true) => {
        if (pacienteZ)
            setPacienteEscolhido(null);
        if (planoZ)
            setPlanoDeSaudeEscolhido(null);
        if (plano_nomeZ)
            setPlanoDeSaudeEscolhidoNome(null);
        if (especialidadeZ)
            setEspecialidadeEscolhido(null);
        if (terapiaZ) {
            setTerapiaEscolhida(null);
            setTerapiaPacienteEscolhido([]);
        }
        if (valorZ)
            setValor(0);
        if (inicioZ) {
            setInicio('');
            setInputInicio('');
        }
        if (validadeZ) {
            setValidade('');
            setInputValidade('');
        }
        if (numerosZ) {
            setNumeroGuia('');
            setIdentificadorGeral('');
        }
    };

    /**
     * Fecha o modal e chama a função de callback.
     */
    const closeModal = useCallback(() => {
        zeraDados(true, true, true, true, true, true, true, true, true);
        callBack();
        setIsOpen(false);
    }, [callBack]);

    //#endregion
    //#region GETTERS
    /**
     * Busca todas as listas necessárias para preencher os campos do modal.
     * 
     * @async
     */
    async function getTodasListas() {
        setLoadingDadosIniciais(true);
        const dadosPaciente = await api.get('api/paciente/lista/simples?with=terapia_paciente&select=terapia_paciente.terapia,terapia_paciente.especialidade,terapia_paciente.plano_saude,identificador,nome,id');
        const selectPaciente = dadosPaciente.data.map((d) => {
            return ({
                value: d.id,
                label: `${d.nome} (${d.identificador})`,
                terapia_pacientes: d.terapia_paciente,
            });
        });
        setPacienteSelectBox(selectPaciente);

        setLoadingDadosIniciais(false);
    }

    /**
     * Define o paciente padrão com base nos dados fornecidos.
     * 
     * @param {Array} pacientes - Lista de pacientes disponíveis.
     */
    const getDefaultPaciente = (pacientes) => {
        if (!modalData) return;
        const paciente = pacientes.filter((data) => {
            return data.value === modalData.paciente_id;
        })[0];
        setPacienteEscolhido(paciente);
        let terapiaPacientesList = [];
        if (paciente.terapia_pacientes !== undefined) {
            for (let index = 0; index < paciente.terapia_pacientes.length; index++) {
                const el = paciente.terapia_pacientes[index];
                terapiaPacientesList.push({
                    value: el.id,
                    label: `${el.terapia} - ${el.especialidade} - ${el.nome_reduzido}`,
                    terapia_id: el.terapia_id,
                    especialidade_id: el.especialidade_id,
                    plano_saude_id: el.plano_saude_id,
                    plano_saude: el?.plano_saude ? el?.plano_saude : el?.nome_reduzido,
                });
            }
        }
        const t_p_id = modalData.terapia_paciente_id;
        const terapia_p = terapiaPacientesList.filter((data) => {
            return Number(data.value) === Number(t_p_id);
        })[0];
        const terapia_id = terapia_p.terapia_id;
        const especialidade_id = terapia_p.especialidade_id;
        const plano_saude_id = terapia_p?.plano_saude_id;
        setPlanoDeSaudeEscolhido(plano_saude_id);
        setPlanoDeSaudeEscolhidoNome(terapia_p?.plano_saude?.plano);
        setTerapiaEscolhida(terapia_id);
        setEspecialidadeEscolhido(especialidade_id);
        setTerapiaPacienteEscolhido(terapia_p);
        setTerapiaPacienteSelectBox(terapiaPacientesList);
    };
    /**
     * Define os dados padrão do modal com base em `modalData`.
     * 
     * @async
     */
    async function getDefaultDados() {
        if (modalData === null) {
            zeraDados();
        } else {
            getDefaultPaciente(pacienteSelectBox);
            setValor(modalData.valor);
        }
    }

    //#endregion
    //#region HANDLES
    const handleClickCopiaGuia = (guia) => {
        setNumeroGuia(guia.numero_guia);
        setIdentificadorGeral(guia.identificador_geral);
    };
    /**
     * Lida com o envio do formulário.
     * 
     * @async
     * @param {Object} data - Dados do formulário.
     */
    const handleSubmit = async (data) => {
        if (!pacienteEscolhido?.value) {
            Swal.fire('Faltando campos!', 'O campo paciente, não foi preenchido!', 'warning');
            return;
        }
        if (!terapiaPacienteEscolhido?.value) {
            Swal.fire('Faltando campos!', 'O campo terapia do paciente não foi preenchido!', 'warning');
            return;
        }
        if (!((data.inicio && data.validade) || data.solicitacao)) {
            Swal.fire('Falta alguma data!', 'Na guia, você pode preencher a data de início e validade, ou a data de solicitação, mas não nenhuma.', 'warning');
            return;
        }
        if ((data.inicio && data.validade) && !data.solicitacao) {
            if (!data.quantidade) {
                Swal.fire('Faltando campos!', 'O campo quantidade, não foi preenchido!', 'warning');
                return;
            }
            if (planoDeSaudeEscolhidoNome?.toLowerCase() === 'particular') {
                if (!valor) {
                    Swal.fire('Faltando campos!', 'O campo valor, não foi preenchido!', 'warning');
                    return;
                }
            } else {
                if (!identificadorGeral) {
                    Swal.fire('Faltando campos!', 'O campo Código 1, não foi preenchido!', 'warning');
                    return;
                }
            }
        }
        if (vigenciaValida === false) {
            Swal.fire('Vigência inválida!', 'A vigência da Guia que esta tentando incluir, esta conflitando com outra guia.', 'error');
            return;
        }
        setSalvando(true);
        const dados = {
            terapia_paciente_id: terapiaPacienteEscolhido.value,
            paciente_id: pacienteEscolhido.value,
            terapia_id: terapiaEscolhido,
            especialidade_id: especialidadeEscolhido,
            plano_saude_id: planoDeSaudeEscolhido,
            tipo_terapia: 'Tratamento',
            quantidade_liberada: Number(data.quantidade),
            numero_guia: numeroGuia,
            identificador_geral: identificadorGeral,
            inicio: data.inicio,
            validade: data.validade,
            solicitacao: data.solicitacao,
            aceitacao: data.aceitacao,
            valor: valor,
            comentario: comentario,
        };

        try {
            if (terapiaEscolhido === null || planoDeSaudeEscolhido === null || especialidadeEscolhido === null) {
                Swal.fire('Por favor preencha todos os campos!', '', 'warning');
            } else {
                Swal.fire({
                    title: modalData ? 'Deseja confirmar a alteração dos dados guia?' : 'Deseja confirmar a criação da guia?',
                    icon: 'question',
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonAriaLabel: 'Confirmar',
                    cancelButtonAriaLabel: 'Cancelar',
                })
                    .then(async (response) => {
                        if (response.isConfirmed) {
                            /* Confere se é ou não Edição */
                            if (!modalData) {
                                /* CRIAÇÃO */
                                if (planoDeSaudeEscolhidoNome?.toLowerCase() === 'particular') {
                                    dados.status = 'Ativo';
                                }
                                /* Confere se é edição de paciente, ou paciente novo */
                                await api.post('api/guia', dados)
                                    .then(() => {
                                        snackbar.displayMessage(
                                            'Adicionada nova terapia com sucesso!',
                                            'success'
                                        );
                                    })
                                    .catch((e) => {
                                        console.error(e);
                                        snackbar.displayMessage(
                                            'Houve um problema ao adicionar a terapia! Aperte F12 para ver o erro!',
                                            'error'
                                        );
                                    })
                                    .finally(() => {
                                        closeModal();
                                    });
                            } else {
                                /* EDIÇÃO */
                                if (planoDeSaudeEscolhidoNome?.toLowerCase() === 'particular' && dados.status !== 'Bloqueado') {
                                    dados.status = 'Ativo';
                                } else {
                                    let data_a = moment().utc();
                                    let data_i = moment(dados.inicio).utc();
                                    let data_v = moment(dados.validade).utc();
                                    if (data_a.isAfter(data_i)) {
                                        if (data_a.isBefore(data_v)) {
                                            dados.status = 'Ativo';
                                        } else {
                                            dados.status = 'Vencido';
                                        }
                                    }
                                }
                                api.patch('api/guia/' + modalData.id + '?id=' + modalData.id, dados)
                                    .then((e) => {
                                        snackbar.displayMessage('Alterado com sucesso!', 'success');
                                    })
                                    .catch(({ e }) => {
                                        snackbar.displayMessage('Houve um problema ao tentar alterar!', 'error');
                                        console.error(e);
                                    }).finally(() => {
                                        setEspecialidadeEscolhido(null);
                                        setPlanoDeSaudeEscolhidoNome(null);
                                        setPlanoDeSaudeEscolhido(null);
                                        setTerapiaPacienteEscolhido(null);
                                        setTerapiaEscolhida(null);
                                        setPacienteEscolhido(null);
                                        setTerapiaPacienteSelectBox(null);
                                        setPacienteSelectBox(null);
                                        setPacienteSelectBox(null);
                                        setValor(0);
                                        closeModal();
                                    });
                            }
                        }
                    })
                    .catch((e) => console.error(e));
            }
        } catch (error) {
            console.error(error);
            Swal.fire('Houve um problema ao tentar cadastrar!', '', 'error');
        } finally {
            setSalvando(false);
        }
    };

    /**
     * Verifica se o período de vigência está completamente fora de qualquer período de validade no histórico.
     *
     * @returns {boolean} Retorna true se não houver conflito, ou seja, se o período estiver antes ou depois de todos os períodos no histórico. Retorna false se houver algum conflito.
     */
    const handleValidadata = () => {
        //#region VARIAVEIS
        const tInicio = inicio;
        const tFim = validade;
        let conflito = false;
        //#endregion
        //#region EXECUÇÃO
        if (historicosDaGuia && historicosDaGuia.length > 0) {
            historicosDaGuia.forEach((historico) => {
                let testa = true;
                if (modalData) {
                    if (modalData.id === historico.id) {
                        testa = false;
                    }
                }

                if (testa) {
                    const hInicio = historico.inicio;
                    const hFim = historico.validade;
                    const invalidoInicio = (depois(tInicio, hInicio) && antes(tInicio, hFim));
                    const invalidoFim = (depois(tFim, hInicio) && antes(tFim, hFim));
                    if (invalidoInicio || invalidoFim) {
                        conflito = true;
                    }
                }
            });
        }
        return conflito;
        //#endregion
    };
    /**
     * Lida com a seleção de terapia do paciente.
     * 
     * @param {Object} e - Evento de alteração.
     */
    const handleChangeTerapiaPaciente = async (e) => {
        setTerapiaPacienteEscolhido(e);
        setTerapiaEscolhida(e.terapia_id);
        setEspecialidadeEscolhido(e.especialidade_id);
        setPlanoDeSaudeEscolhido(e.plano_saude_id);
        setPlanoDeSaudeEscolhidoNome(e.plano_saude);
        let lista = [];
        memoListaGuias.forEach((filter) => {
            if (Number(filter.terapia_paciente_id) === Number(e.value)) {
                lista.push(filter);
            }
        });
        if (lista) {
            setListaHistorico(lista);
        } else {
            setListaHistorico(null);
        }
    };

    const handleTestaIgualdadeGuia = () => {
        if (pacienteEscolhido && terapiaEscolhido && identificadorGeral) {
            const index = historicosDaGuia.length - 1;
            const last = historicosDaGuia[index];
            if (last.identificador_geral !== identificadorGeral) {
                Swal.fire('Atenção!', 'O Código 1 da sua guia, esta diferenta da anterior, tenha certeza de por as informações corretas.');
            } else {
                if (last.numero_guia) {
                    if (last.numero_guia !== numeroGuia) {
                        Swal.fire('Atenção!', 'O Código 2 da sua guia, esta diferenta da anterior, tenha certeza de por as informações corretas.');
                    }
                }
            }
        }
    };

    /**
     * Submete o formulário de forma programática.
     */
    function functionThatSubmitsForm() {
        formRef.current.submitForm();
    }
    //#endregion
    //#endregion
    //#region USE EFFECT

    useEffect(() => {
        getTodasListas();
        getDefaultDados();
        if (modalData) {
            const identificador = modalData === null ? idGuia : modalData.identificador_geral;
            const numero = modalData === null ? idGuia : modalData.numero_guia;
            const newInicio = modalData === null ? '' : moment(modalData.inicio).format('YYYY-MM-DD');
            const newFim = modalData === null ? '' : moment(modalData.validade).format('YYYY-MM-DD');
            setInicio(newInicio);
            setInputInicio(newInicio);
            setValidade(newFim);
            setInputValidade(newFim);
            setIdentificadorGeral(identificador);
            setNumeroGuia(numero);
        }
    }, [modalData]);

    useEffect(() => {
        if (!visualizacao) {
            if (inicio && validade) {
                let novaVigenciaInvalida = handleValidadata();
                setVigenciaValida(!novaVigenciaInvalida);
                if (novaVigenciaInvalida) {
                    Swal.fire('Vigência inválida!', 'A vigência da Guia que esta tentando incluir, esta conflitando com outra guia.', 'error');
                }
            }
        }
    }, [inicio, validade]);

    //#endregion
    //#region HTML
    return (
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
            transparent
            appElement={document.getElementById('root')}
        >
            <nav className='navbar sticky-top bg-cinza-claro shadow' style={{ margin: '0px' }}>
                <div className="container-fluid d-flex align-items-center justify-content-between">
                    <div className='w-200px'></div>
                    <ModalTitulo>{modalData === null ? 'Adicionar Nova Guia' : visualizacao === true ? 'Detalhes da Guia' : 'Alterar Dados da Guia'}</ModalTitulo>
                    <label className='btn-outline w-200px text-right' onClick={() => setIsOpen(false)}>
                        <CgClose size={22} color='red' />
                    </label>
                </div>
            </nav>
            <br></br>
            <div className="d-flex flex-col p-4">
                <Form ref={formRef} onSubmit={handleSubmit} className='d-flex flex-col'>
                    <div className="row">
                        <div className={'col-12'}>
                            <div className="col-12">
                                <SelectPaciente
                                    id="paciente_id"
                                    name="paciente_id"
                                    label="Paciente:"
                                    isDisabled={visualizacao === true
                                        ? true
                                        : modalData
                                            ? true
                                            : false
                                    }
                                    value={pacienteEscolhido}
                                    setPaciente={(paciente) => {
                                        setPacienteEscolhido(paciente);
                                    }}
                                    setTerapias={(terapias) => {
                                        setTerapiaPacienteSelectBox(terapias);
                                        zeraDados(false);
                                    }}
                                    externalPaciente={pacienteOptions}
                                    required
                                ></SelectPaciente>
                            </div>
                            <div className="col-12">
                                <Select
                                    name="terapia_paciente_id"
                                    label="Terapia do Paciente:"
                                    options={terapiaPacienteSelectBox}
                                    isDisabled={visualizacao === true
                                        ? true
                                        : modalData && tresHorasDiff
                                            ? true
                                            : false
                                    }
                                    value={terapiaPacienteEscolhido}
                                    onChange={handleChangeTerapiaPaciente}
                                    defaultValue={terapiaPacienteEscolhido}
                                    required
                                />
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <Input
                                        name="valor"
                                        title="Valor:"
                                        onChange={(e) => setValor(e.target.value)}
                                        disabled={visualizacao === true
                                            ? true
                                            : modalData && tresHorasDiff
                                                ? true
                                                : planoDeSaudeEscolhidoNome?.toLowerCase() === 'particular' ? !terapiaPacienteEscolhido?.value ? true : false : true
                                        }
                                        defaultValue={modalData === null ? '' : modalData.valor}
                                        required={inputSolicitacao ? false : true}
                                        hasClearButton={true}
                                    />
                                </div>
                                <div className="col-6">
                                    <Input
                                        id="quantidade"
                                        name="quantidade"
                                        title="Quantidade:"
                                        type="number"
                                        disabled={visualizacao === true ? true : !terapiaPacienteEscolhido?.value}
                                        defaultValue={modalData === null ? '' : modalData.quantidade_liberada}
                                        required={inputSolicitacao ? false : true}
                                        hasClearButton={true}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <Input
                                        id="identificador_geral"
                                        name="identificador_geral"
                                        title="Código 1:"
                                        disabled={visualizacao === true
                                            ? true
                                            : modalData && tresHorasDiff
                                                ? true
                                                : !terapiaPacienteEscolhido?.value || planoDeSaudeEscolhidoNome?.toLowerCase() === 'particular' ? true : false
                                        }
                                        value={identificadorGeral}
                                        onChange={(e) => { setIdentificadorGeral(e.target.value); }}
                                        onBlur={() => handleTestaIgualdadeGuia()}
                                        hasClearButton={true}
                                        required={inputSolicitacao ? false : true}
                                    />
                                </div>
                                <div className="col-6">
                                    <Input
                                        id="numero_guia"
                                        name="numero_guia"
                                        title="Código 2:"
                                        disabled={visualizacao === true
                                            ? true
                                            : modalData && tresHorasDiff
                                                ? true
                                                : planoDeSaudeEscolhidoNome?.toLowerCase() === 'particular' ? true : !terapiaPacienteEscolhido?.value ? true : false
                                        }
                                        value={numeroGuia}
                                        onChange={(e) => { setNumeroGuia(e.target.value); }}
                                        onBlur={() => handleTestaIgualdadeGuia()}
                                        required={inputSolicitacao ? false : true}
                                        hasClearButton={true}
                                    />
                                </div>
                            </div>
                            <div className={`row${vigenciaValida ? ' border-danger' : ''}`}>
                                <div className='col-6'>
                                    <Input
                                        id="solicitacao"
                                        name="solicitacao"
                                        title="Data de Solicitação:"
                                        type="date"
                                        onBlur={(e) => setSolicitacao(e.target.value)}
                                        onChange={(e) => setInputSolicitacao(e.target.value)}
                                        value={inputSolicitacao}
                                        disabled={visualizacao === true ? true : !terapiaPacienteEscolhido?.value}
                                        hasClearButton={true}
                                        required
                                    />
                                </div>
                                <div className='col-6'>
                                    <Input
                                        id="aceitacao"
                                        name="aceitacao"
                                        title="Data de Aceitacao:"
                                        type="date"
                                        onBlur={(e) => setAceitacao(e.target.value)}
                                        onChange={(e) => setInputAceitacao(e.target.value)}
                                        value={inputAceitacao}
                                        disabled={visualizacao === true ? true : !terapiaPacienteEscolhido?.value}
                                        hasClearButton={true}
                                    />
                                </div>
                                <div className="col-6 ">
                                    <Input
                                        id="inicio"
                                        name="inicio"
                                        title="Data de início:"
                                        type="date"
                                        max={validade}
                                        sideContent={true}
                                        onFocus={(e) => { setInicio(''); setValidade(''); setInputInicio(''); setInputValidade(''); }}
                                        onBlur={(e) => setInicio(e.target.value)}
                                        onChange={(e) => setInputInicio(e.target.value)}
                                        value={inputInicio}
                                        disabled={visualizacao === true
                                            ? true
                                            : modalData && tresHorasDiff
                                                ? true
                                                : !terapiaPacienteEscolhido?.value
                                        }
                                        hasClearButton={true}
                                        required={inputSolicitacao ? false : true}
                                    />
                                </div>
                                <div className="col-6">
                                    <Input
                                        id="validade"
                                        name="validade"
                                        title="Validade da Guia:"
                                        type="date"
                                        min={inicio}
                                        sideContent={true}
                                        onFocus={(e) => { setValidade(''); setInputValidade(''); }}
                                        onBlur={(e) => setValidade(e.target.value)}
                                        onChange={(e) => setInputValidade(e.target.value)}
                                        disabled={
                                            inicio === null
                                                ? true
                                                : visualizacao === true
                                                    ? true
                                                    : modalData && tresHorasDiff
                                                        ? true
                                                        : !terapiaPacienteEscolhido?.value
                                        }
                                        value={inputValidade}
                                        hasClearButton={true}
                                        required={inputSolicitacao ? false : true}
                                    />
                                </div>
                            </div>
                            <div className='col-12'>
                                <TextArea
                                    id="comentario"
                                    name="comentario"
                                    title="Comentário:"
                                    onChange={(e) => setComentario(e.target.value)}
                                    disabled={visualizacao === true ? true : !terapiaPacienteEscolhido?.value}
                                    defaultValue={modalData === null ? '' : modalData.comentario}
                                />
                            </div>
                        </div>
                        {historicosDaGuia
                            ? <div className={'col-12'} style={{ marginTop: '35px' }}>
                                <Fieldset legend="Histórico de Guias" style={{ border: '#f0f0f0 solid 2px', borderRadius: '10px' }} collapsed={true} toggleable>
                                    <table className="table table-striped rounded-0">
                                        <thead>
                                            <tr>
                                                <th className="border-end border-top" scope="col">Paciente</th>
                                                <th className="border-end border-top" scope="col">Terapia</th>
                                                <th className="border-end border-top" scope="col">Especialidade</th>
                                                <th className="border-end border-top" scope="col">Plano</th>
                                                <th className="border-end border-top" scope="col">Liberado/Saldo</th>
                                                <th className="border-end border-top" scope="col">Valor (R$)</th>
                                                <th className="border-end border-top" scope="col">Dias</th>
                                                <th className="border-end border-top" scope="col">Vigência</th>
                                                <th className="border-end border-top" scope="col">Status</th>
                                                <th className="border-top" scope="col">Código 1</th>
                                                <th className="border-end border-top" scope="col">Código 2</th>
                                                <th className="border-top" scope="col">Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {historicosDaGuia.map((historicoGuia, index) => (
                                                <tr className="hl-roxo-claro" key={index}>
                                                    <th className="border-end font-12px" scope="row">{historicoGuia?.nome}</th>
                                                    <td className="text-center border-end font-12px">{historicoGuia?.terapia}</td>
                                                    <td className="text-center border-end font-12px">{historicoGuia?.especialidade}</td>
                                                    <td className="text-center border-end font-12px">{historicoGuia?.nome_reduzido}</td>
                                                    <td className="text-center border-end font-12px">{historicoGuia?.quantidade_liberada}/{Number(historicoGuia.quantidade_liberada) - Number(historicoGuia.quantidade_feita)}</td>
                                                    <td className="text-center border-end font-12px">{historicoGuia.valor ? historicoGuia.valor : ' - '}</td>
                                                    <td className="text-center border-end font-12px">{historicoGuia?.dias_a_vencer}</td>
                                                    <td className="text-center border-end font-12px">{moment.utc(historicoGuia?.inicio).format('DD/MM/YYYY')}-{moment.utc(historicoGuia?.validade).format('DD/MM/YYYY')}</td>
                                                    <td className="text-center border-end font-12px">{historicoGuia?.status}</td>
                                                    <td className="text-center border-end font-12px">{historicoGuia.identificador_geral ? historicoGuia.identificador_geral : ' - '}</td>
                                                    <td className="text-center border-end font-12px">{historicoGuia.numero_guia ? historicoGuia.numero_guia : ' - '}</td>
                                                    <td className="text-center border-end font-12px">
                                                        <button type="button" onClick={(e) => handleClickCopiaGuia(historicoGuia)}>
                                                            <CgCopy size={24}></CgCopy>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </Fieldset>
                            </div>
                            : <></>
                        }
                        {atendimentosGuia && <div className={'col-12'} style={{ marginTop: '35px' }}>
                            <Fieldset legend="Atendimentos" style={{ border: '#f0f0f0 solid 2px', borderRadius: '10px' }} collapsed={true} toggleable>
                                <table className="table table-striped rounded-0">
                                    <thead>
                                        <tr>
                                            <th className="border-end border-top" scope="col">Terapeuta</th>
                                            <th className="border-end border-top" scope="col">Status</th>
                                            <th className="border-top" scope="col">Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {atendimentosGuia.map((atendimento, index) => (
                                            <tr className="hl-roxo-claro" key={index}>
                                                <th className="border-end font-12px" scope="row">{atendimento?.terapeuta}</th>
                                                <td className="text-center border-end font-12px">{atendimento.sugestao_recepcao_name}</td>
                                                <td className="text-center border-end font-12px">
                                                    <button type="button" onClick={(e) => handleClickCopiaGuia(atendimento)}>
                                                        <CgCopy size={24}></CgCopy>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </Fieldset>
                        </div>}
                    </div>
                    {!visualizacao &&
                        <button className="btn-padrao mt-30px" type="button" onClick={functionThatSubmitsForm} disabled={salvando} >
                            {salvando
                                ? (<ClipLoader color="#ffffff" size={30}></ClipLoader>)
                                : modalData === null
                                    ? ('Adicionar')
                                    : ('Alterar')
                            }
                        </button>
                    }
                </Form>
            </div>
        </Modal>
    );
    //#endregion
});

export default ModalGuias;