import styled from 'styled-components';

const smallScreen = window.innerWidth < 800;

export const ModalBase = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;

  width: 100%;
  height: 100vh;
  
  background-color: rgba(0, 0, 0, 0.6);

  display: ${smallScreen ? 'block' : 'flex'};
  justify-content: center;
  align-items: center;
  align-content: center;

  overflow: none;
`;


export const ContainerBase = styled.div`
  width: ${smallScreen ? '100vw' : '60vw'};
  height: ${smallScreen ? '80vh' : '70vh'};

  background-color: #FFFFFF;
  color: #000;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  overflow-x: auto;
  padding: ${smallScreen ? '15px' : '30px'};
`;