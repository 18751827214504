import React, { useEffect, useState, useCallback, useRef, forwardRef } from 'react';
import { Form } from '@unform/web';
import { ClipLoader } from 'react-spinners';
import api from 'services/api';
import Input from 'components/Input';
import Button from 'components/Button';

const customStylesLabel = {
    fontWeight: 'bold',
    marginTop: '10px',
    fontSize: '13px'
};

const TipoDemandaModal = ({ modalData, callback, closeModal }, ref) => {
    const [tipoDemandaNome, setTipoDemandaNome] = useState('');
    const [prioridade, setPrioridade] = useState(0);
    const [loading, setLoading] = useState(false);
    const formRef = useRef(null);

    const limpaModal = () => {
        setPrioridade(0);
        setTipoDemandaNome(null);
    };

    const store = async (data) => {
        try {
            setLoading(true);
            await api.post('api/tipo-demanda', data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
            closeModal();
            callback();
        }
    };

    const update = async (data, tipoDemandaId) => {
        try {
            setLoading(true);
            await api.put(`api/tipo-demanda/${tipoDemandaId}&id=${tipoDemandaId}`, data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
            closeModal();
            callback();
        }
    };

    async function handleSubmit() {
        const data = {
            tipo_demanda: tipoDemandaNome,
            prioridade: prioridade,
        };
        limpaModal();
        if (modalData && modalData.id)
            return update(data, modalData.id);
        else
            return store(data);
    }

    useEffect(() => {
        if (modalData && modalData.id) {
            setTipoDemandaNome(modalData.tipo_demanda);
            setPrioridade(modalData.prioridade);
        } else {
            setTipoDemandaNome('');
            setPrioridade(0);
        }
    }, [modalData]);

    return (
        <div>
            <div className='flex-col'>
                <h2 style={{ color: '#442953', fontWeight: 'bold' }}>{modalData === null ? 'Cadastrar ' : 'Alterar '}Tipo de Demanda</h2>
                <Form ref={formRef} onSubmit={handleSubmit} className='flex-col'>
                    <div className='row d-flex'>
                        <div className='col-lg-6 col-sm-12'>
                            <Input name={'tipo_demanda'} title={'Tipo de Demanda'} value={tipoDemandaNome} onChange={(e) => setTipoDemandaNome(e.target.value.toUpperCase())} required={'required'} />
                        </div>
                        <div className='col-lg-6 col-sm-12 flex-col d-flex'>
                            <label style={customStylesLabel} htmlFor='prioridade'>Prioridade</label>
                            <Input name={'prioridade'} id={'prioridade'} type={'number'} min={0} max={5} value={prioridade} onFocus={(e) => e.target.select()} onChange={(e) => setPrioridade(e.target.value)} required={'required'} />
                        </div>
                    </div>
                    <Button className='btn-padrao' type="submit" disabled={loading}>
                        {loading
                            ? <ClipLoader size={22} />
                            : (modalData && modalData.id)
                                ? 'Alterar'
                                : 'Cadastrar'
                        }
                    </Button>
                </Form>
            </div>
        </div>
    );
};

export default forwardRef(TipoDemandaModal);